import { useContext, useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import BackToDashboardButton from '../../components/BackToDashboardButton';
import GridItem, { GridItemContent } from '../../components/GridItem';
import { PageWrapper, PageTitle } from '../../components/PageComponents';
import { getDriverProfile, getUserProfile } from '../../dbconnector/users';
import {
  getDriverCommunicationPreferenceFromId,
  getVehicleTypeFromId,
  NeighborRideDriver,
} from '../../models/driver_profile';
import { NeighborRideUser } from '../../models/user';
import { UserContext } from '../../models/user_context';
import { getGroupCode } from './helpers';

export default function DriverProfilePage() {
  const navigate = useNavigate();
  const { userId, userToken, isMobile, verifySession } =
    useContext(UserContext);

  const [driverProfile, setDriverProfile] = useState<NeighborRideDriver>();
  const [userProfile, setUserProfile] = useState<NeighborRideUser>();
  const [groupCode, setGroupCode] = useState('');

  useEffect(() => {
    getGroupCode(userId, userToken)
      .then((code) => setGroupCode(code))
      .catch((err) => {
        if (err.message === 'Forbidden') {
          verifySession(403);
        }
      });
    const getData = async () => {
      const inputDriverProfile = await getDriverProfile(userId, userToken);
      const inputUserProfile = await getUserProfile(userId, userToken);
      setDriverProfile(inputDriverProfile);
      setUserProfile(inputUserProfile);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userToken]);

  if (!driverProfile || !userProfile) {
    return null;
  }

  // Split up collected profile data into their corresponding tables
  const general: GridItemContent = {
    Name: userProfile?.first_name
      ? `${userProfile.first_name} ${userProfile.last_name}`
      : '',
    Username: userProfile.email,
    Birthdate: driverProfile.birth_date,
    Gender: driverProfile.gender,
    Role: 'Driver',
    'Group Code(s)': groupCode,
  };
  const contact: GridItemContent = {
    'Primary Phone': driverProfile.home_phone,
    'Alternate Phone': driverProfile.cell_phone,
    Email: userProfile.email,
    'Communication Preferences': getDriverCommunicationPreferenceFromId(
      driverProfile.profile_comm_preference_id
    ),
  };
  const address: GridItemContent = {
    Address: driverProfile.address1,
    'Address 2': driverProfile.address2,
    'Development Name': driverProfile.development_name,
    City: driverProfile.city,
    State: driverProfile.state,
    Zip: driverProfile.zip,
  };
  const rideAlerts: string[] = [];
  if (driverProfile.ride_alert_all_call) {
    rideAlerts.push('Daily All Call');
  }
  if (driverProfile.ride_alert_last_resort) {
    rideAlerts.push('Emergency Driver List');
  }
  if (rideAlerts.length == 0) {
    rideAlerts.push('None');
  }
  const checkedRideAlerts = [];
  if (driverProfile.ride_alert_all_call === '0') {
    checkedRideAlerts.push('Daily All Call/Last Resort');
  }
  if (driverProfile.ride_alert_last_resort === '0') {
    checkedRideAlerts.push('Emergency Driver List');
  }
  const checkedRideConfirmations = [];
  if (driverProfile.profile_ride_confirmation_accept === '0') {
    checkedRideConfirmations.push(
      'Send me ride confirmations via email for rides I accept.'
    );
  }
  if (driverProfile.profile_ride_reminder_day_before === '0') {
    checkedRideConfirmations.push(
      'Send me reminders for my rides the previous day.'
    );
  }

  const driverPref: GridItemContent = {
    'Ride Alerts': checkedRideAlerts.join(', ')
      ? checkedRideAlerts.join(', ')
      : 'None',
    'Ride Request Preferences': getDriverCommunicationPreferenceFromId(
      driverProfile.profile_comm_preference_id
    ),
    'Ride Confirmations': checkedRideConfirmations.join('\n')
      ? checkedRideConfirmations.join('\n')
      : 'None',
    'Drive Only in Howard County':
      driverProfile.howard_county === '0' ? 'Yes' : 'No',
  };
  const emergencyContact: GridItemContent = {
    Name: driverProfile?.emergency_contact_name
      ? driverProfile.emergency_contact_name
      : 'No Data',
    Phone: driverProfile?.emergency_contact_phone
      ? driverProfile.emergency_contact_phone
      : 'No Data',
  };
  const driverInfo: GridItemContent = {
    Status: 'Active',
    'Insurance Company': driverProfile.insurance_company,
    'Insurance Expiration': driverProfile.insurance_expiration,
    'Driver License Expiration': driverProfile.drivers_license_expiration,
    'Vehicle Type': getVehicleTypeFromId(driverProfile.vehicle_type_id),
    'Vehicle High Step Restriction':
      driverProfile.vehicle_high_step_restriction,
    'Vehicle Passenger Limit': driverProfile.vehicle_passenger_limit,
  };
  const notes: GridItemContent = {
    'Driver Notes': driverProfile.driver_notes,
    'Vehicle Specific Notes': driverProfile.vehicle_specific_notes,
  };

  return (
    <PageWrapper>
      <PageTitle title="Profile" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? undefined : 'center',
          marginBottom: '30px',
        }}
      >
        <BackToDashboardButton />
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: isMobile ? undefined : 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate('/edit-profile')}
            sx={{
              marginY: '10px',
              height: '40px',
              marginRight: isMobile ? 0 : '15px',
            }}
          >
            Edit Profile
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate('/update-schedule')}
            sx={{ height: '40px' }}
          >
            Edit Schedule
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <GridItem content={general} header="General Information" />
        <GridItem content={contact} header="Contact" />
        <GridItem content={address} header="Address" />
        <GridItem content={driverPref} header="Driver Preferences" />
        <GridItem content={emergencyContact} header="Emergency Contact" />
        <GridItem content={driverInfo} header="Driver" />
        <GridItem content={notes} header="Notes" />
        <GridItem header="Audit History" />
      </Box>
    </PageWrapper>
  );
}
