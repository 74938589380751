function SupportPage() {
  return (
    <div>
      <h1>Support Page</h1>
      <p>
        Support Neighbor Ride by following the links or making a donation below
      </p>
    </div>
  );
}

export default SupportPage;
