/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
import { useContext, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { Colors } from '../constants';
import { BASE_API_URL } from '../models/utils';
import { UserContext } from '../models/user_context';

interface IMessage {
  body: string;
  created_at?: string;
  id?: string;
  message_type_id: string;
  role?: string;
  status_id?: string;
  title?: string;
  updated_at?: string;
  viewed?: string;
}

interface IMessagesProps {
  role: 'Client' | 'Driver' | 'Login page';
}

const setMessageStyle = (messageType: string) => {
  let backgroundColor;
  let color;

  switch (messageType) {
    case '0':
      backgroundColor = Colors.lightGreyTextBackground;
      break;
    case '1':
      backgroundColor = Colors.lightBlue;
      color = Colors.darkBlue;
      break;
    case '2':
      backgroundColor = Colors.lightYellow;
      color = Colors.darkYellow;
      break;
    case '3':
      backgroundColor = Colors.redTextBackground;
      color = Colors.redText;
      break;
  }

  return {
    padding: '20px 10px 20px 10px',
    backgroundColor,
    color,
  };
};

const defaultMessages = {
  Client: [
    {
      body: 'The CONFIRM button disappears if the office has called you with a drivers name.',
      message_type_id: '0',
    },
    {
      body: 'Please click Confirm to acknowledge that a driver has accepted your ride(s).',
      message_type_id: '3',
    },
  ],
  Driver: [
    {
      body: 'If you can no longer complete a ride, please contact the Ride Coordinator.',
      message_type_id: '3',
    },
  ],
  'Login page': [],
};

export default function Messages({ role }: IMessagesProps) {
  const [messages, setMessages] = useState<IMessage[]>(defaultMessages[role]);

  const { userId, userToken, verifySession } = useContext(UserContext);

  const setMessagesAux = async (res: Response) => {
    if (res.status === 200) {
      const json = await res.json();
      setMessages([...messages, ...json.messages]);
    } else {
      verifySession(res.status);
    }
  };

  useEffect(() => {
    if (role !== 'Login page') {
      fetch(`${BASE_API_URL}/messages/${userId}/${role}`, {
        headers: { Authorization: userToken },
      }).then(setMessagesAux);
    } else fetch(`${BASE_API_URL}/messages/login`).then(setMessagesAux);
  }, [userToken]);

  return (
    <>
      {messages
        .sort((a, b) => Number(b.message_type_id) - Number(a.message_type_id))
        .map((message, index) => (
          <Paper
            key={index}
            style={setMessageStyle(message.message_type_id)}
            sx={{ marginBottom: '10px' }}
          >
            {message.body}
          </Paper>
        ))}
    </>
  );
}
