import moment from 'moment';

import { FirebaseLocation } from '../models/location';
import {
  ClientHistoryTableRide,
  DriverHistoryTableRide,
  TableRide,
} from '../models/rides';

export const capFirstLetter = (value: string): string => {
  if (value) return value.charAt(0).toUpperCase() + value.slice(1);
  return value;
};

export const getPrettyDate = (date_str: string): string => {
  /*
   * Input: 2022-09-23
   * Output: September 9, 2022
   */
  const prettyDateString = moment(date_str, 'Y-MM-DD').format('MMMM D, Y');
  return prettyDateString;
};

export const getNeighborRideDate = (date: Date): string => {
  const newDate = new Date(date);
  const neighborRideDate = moment(newDate).format('Y-MM-DD');
  return neighborRideDate;
};

export const getDateFromNeighborRideDate = (date_str: string): Date => {
  return moment(date_str, 'YYYY-MM-DD').toDate();
};

export const convertMilitaryToAMPMTime = (military_time: string): string => {
  /*
   * Input: 13:30:00
   * Output: 1:30 PM
   */
  const aMpMTime = moment(military_time, 'HH:mm:ss').format('h:mm A');
  return aMpMTime;
};

export const convertAMPMtoMilitary = (ampm_time: string): string => {
  /*
   * Input: 1:30 PM
   * Output: 13:30:00
   */
  const militaryTime = moment(ampm_time, 'h:mm A').format('HH:mm:ss');
  return militaryTime;
};

export const generateAddressString = (loc: FirebaseLocation): string => {
  return `${loc.title?.toString()} | ${loc.address?.toString()}, ${loc.city?.toString()}, ${loc.state?.toString()}, ${loc.zipcode?.toString()} (${loc.id?.toString()})`;
};

export const isZipValid = (zip: string) => {
  const invalidDigit = zip.split('').some((digit) => isNaN(Number(digit)));
  if (zip.length !== 5 || invalidDigit) return false;
  return true;
};

export const sortRides = (
  rides: TableRide[] | DriverHistoryTableRide[] | ClientHistoryTableRide[]
) => {
  return [...rides].sort((a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    if (a.date === b.date && 'pickup' in a && 'pickup' in b) {
      if (a.pickup <= b.pickup) return -1;
      if (a.pickup > b.pickup) return 1;
    }
    return -1;
  });
};
