import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import Dropdown from '../../../components/Dropdown';
import { NotesModel } from '../../../models/client_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  notesInfo: NotesModel;
  setNotesInfo: React.Dispatch<React.SetStateAction<NotesModel>>;
}

export default function Notes(props: InfoProps) {
  const getFirstLetterCapitalString = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const { notesInfo, setNotesInfo } = props;
  const [restriction, setRestriction] = useState(
    notesInfo.highStepRestriction
      ? getFirstLetterCapitalString(notesInfo.highStepRestriction)
      : ''
  );

  const possibleRestrictions = ['Yes', 'No'];

  useEffect(() => {
    if (restriction) {
      setNotesInfo((obj: NotesModel) => {
        const objCopy = { ...obj };
        objCopy.highStepRestriction = getFirstLetterCapitalString(restriction);
        return objCopy;
      });
    }
  }, [restriction, setNotesInfo]);

  useEffect(() => {
    setRestriction(
      notesInfo.highStepRestriction
        ? getFirstLetterCapitalString(notesInfo.highStepRestriction)
        : ''
    );
  }, [notesInfo]);

  return (
    <BoxContainer header="Notes">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12}>
          <h4>High Step Restriction</h4>
          <Dropdown
            options={possibleRestrictions}
            displayOption={restriction}
            setDisplayOption={setRestriction}
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
