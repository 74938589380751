import React, { useContext, useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { PageWrapper, PageTitle } from '../../components/PageComponents';
import { getSuggestedRides, SuggestedRideGroup } from '../../dbconnector/rides';
import { TableRide } from '../../models/rides';
import { UserContext } from '../../models/user_context';
import {
  convertFirebaseRidesToTableRides,
  driverDashboardColumns,
} from '../DashboardPage/utils';
import { sortRides } from '../utils';

export default function SuggestedRidesPage() {
  const { userId, userToken } = useContext(UserContext);

  const [rideCoordinatorRides, setRideCoordinatorRides] = useState<TableRide[]>(
    []
  );
  const [scheduleFittingRides, setScheduleFittingRides] = useState<TableRide[]>(
    []
  );
  const [zipcodeRides, setZipcodeRides] = useState<TableRide[]>([]);
  const [clientsLastSixRides, setClientsLastSixRides] = useState<TableRide[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState('');

  async function getData() {
    const token = userToken;
    const suggestedRides: SuggestedRideGroup = await getSuggestedRides(
      userId,
      token
    );

    const rawRideCoordinatorRides = suggestedRides.rideCoordinatorRides;
    const rawScheduleFittingRides = suggestedRides.scheduleFittingRides;
    const rawZipcodeRides = suggestedRides.zipcodeRides;
    const rawClientsLastSixRides = suggestedRides.clientsLastSixRides;

    setRideCoordinatorRides(
      convertFirebaseRidesToTableRides(rawRideCoordinatorRides)
    );
    setScheduleFittingRides(
      convertFirebaseRidesToTableRides(rawScheduleFittingRides)
    );
    setZipcodeRides(convertFirebaseRidesToTableRides(rawZipcodeRides));
    setClientsLastSixRides(
      convertFirebaseRidesToTableRides(rawClientsLastSixRides)
    );
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <PageTitle title="Suggested Rides" />
      <h2>Ride Coordinator Suggestions</h2>
      <DataGrid
        rows={sortRides(rideCoordinatorRides)}
        columns={driverDashboardColumns(
          userId,
          userToken,
          false,
          setSuccess,
          getData,
          disabled,
          setDisabled
        )}
        pageSize={7}
        autoHeight
        loading={isLoading}
      />
      <h2>Rides Fitting Driver's Schedule</h2>
      <DataGrid
        rows={sortRides(scheduleFittingRides)}
        columns={driverDashboardColumns(
          userId,
          userToken,
          false,
          setSuccess,
          getData,
          disabled,
          setDisabled
        )}
        pageSize={7}
        autoHeight
        loading={isLoading}
      />
      <h2>Rides For Clients Who Live in Your Zipcode</h2>
      <DataGrid
        rows={sortRides(zipcodeRides)}
        columns={driverDashboardColumns(
          userId,
          userToken,
          false,
          setSuccess,
          getData,
          disabled,
          setDisabled
        )}
        pageSize={7}
        autoHeight
        loading={isLoading}
      />
      <h2>Rides Containing Clients from Your Last Six Rides</h2>
      <DataGrid
        rows={sortRides(clientsLastSixRides)}
        columns={driverDashboardColumns(
          userId,
          userToken,
          false,
          setSuccess,
          getData,
          disabled,
          setDisabled
        )}
        pageSize={7}
        autoHeight
        loading={isLoading}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        sx={{ marginTop: 10 }}
      >
        <Alert
          sx={{ width: '100%', padding: '20px 30px', fontWeight: 'bold' }}
          onClose={() => setSuccess(false)}
        >
          Ride has been accepted.
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
}
