import styled from 'styled-components';

import { Colors } from '../../constants';

export const Box = styled.footer`
  background: ${Colors.mainColor};
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 20px 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (max-width: 700px) {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 5fr auto auto 1fr;
  grid-gap: 30px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const Heading = styled.p`
  font-size: 24px;
  color: white;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
`;

export const Link = styled.a`
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
`;

export const License = styled.p`
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  text-align: center;
`;
