import './App.css';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth, signOut } from 'firebase/auth';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import {
  RequireAnyAuth,
  RequireClientAuth,
  RequireDriverAuth,
} from './components/AuthRules';
import Footer from './components/Footer/Footer';
import TopAccountBar from './components/NavigationBar/TopAccountBar';
import { AuthState } from './models/auth_state';
import { UserContext } from './models/user_context';
import AccountHistory from './pages/AccountHistoryPage/AccountHistory';
import { DashboardPage } from './pages/DashboardPage/DashboardPage';
import DriverRidesPage from './pages/DriverRidesPage/DriverRidesPage';
import LoginPage from './pages/LoginPage/LoginPage';
import MakePaymentPage from './pages/MakePaymentPage/MakePaymentPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import RequestRidePage from './pages/RequestRidePage/RequestRidePage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import { RideHistoryPage } from './pages/RideHistoryPage/RideHistoryPage';
import SuggestedRidesPage from './pages/SuggestedRidesPage/SuggestedRidesPage';
import SupportPage from './pages/SupportPage/SupportPage';
import UpdateProfilePage from './pages/UpdateProfilePage/UpdateProfilePage';
import { UpdateSchedulePage } from './pages/UpdateSchedulePage/UpdateSchedulePage';
import { ViewRidePage } from './pages/ViewRidePage/ViewRidePage';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

export function StripeContextWrapper(props: {
  children?: JSX.Element | JSX.Element[];
}) {
  return <Elements stripe={stripePromise}>{props.children}</Elements>;
}

function App() {
  const auth = getAuth();

  const [authState, setAuthState] = useState<AuthState>(() => {
    const authStateString = localStorage.getItem('authState');
    if (authStateString) {
      return parseInt(authStateString);
    }
    return AuthState.NotAuthenticated;
  });

  const [userId, setUserId] = useState<string>(() => {
    const userIdString = localStorage.getItem('userId');
    if (userIdString) {
      return userIdString;
    }
    return '';
  });

  const [userToken, setUserToken] = useState<string>(() => {
    const userTokenString = localStorage.getItem('userToken');
    if (userTokenString) {
      return userTokenString;
    }
    return '';
  });

  const [userFullName, setUserFullName] = useState<string>(() => {
    const userFullNameString = localStorage.getItem('userFullName');
    if (userFullNameString) {
      return userFullNameString;
    }
    return '';
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const listener = () => setIsMobile(window.innerWidth <= 900);
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  const navigate = useNavigate();

  const logOut = () => {
    signOut(auth).then(() => {
      caches.delete('pendingRides');
      setAuthState(AuthState.NotAuthenticated);
      localStorage.removeItem('userId');
      localStorage.removeItem('userToken');
      localStorage.removeItem('userFullName');
      localStorage.removeItem('authState');
      navigate('/login');
    });
  };

  const verifySession = async (httpStatus: number) => {
    if (httpStatus === 403) {
      if (auth.currentUser) {
        const newToken = await auth.currentUser.getIdToken(true);
        console.log(newToken);
        setUserToken(newToken);
        localStorage.setItem('userToken', newToken);
      } else {
        logOut();
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UserContext.Provider
        value={{
          authState,
          userId,
          userToken,
          userFullName,
          isMobile,
          setAuthState,
          setUserId,
          setUserToken,
          setUserFullName,
          logOut,
          verifySession,
        }}
      >
        <div className="App">
          <TopAccountBar />
          <Routes>
            <Route
              path="/"
              element={
                authState !== AuthState.NotAuthenticated ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/password-reset" element={<ResetPasswordPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route
              path="/dashboard"
              element={
                <RequireAnyAuth>
                  <DashboardPage />
                </RequireAnyAuth>
              }
            />
            <Route
              path="/ride-history"
              element={
                <RequireAnyAuth>
                  <RideHistoryPage />
                </RequireAnyAuth>
              }
            />
            <Route
              path="/update-schedule"
              element={
                <RequireDriverAuth>
                  <UpdateSchedulePage />
                </RequireDriverAuth>
              }
            />
            <Route
              path="/driver-rides"
              element={
                <RequireDriverAuth>
                  <DriverRidesPage />
                </RequireDriverAuth>
              }
            />
            <Route
              path="/suggested-rides"
              element={
                <RequireDriverAuth>
                  <SuggestedRidesPage />
                </RequireDriverAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAnyAuth>
                  <ProfilePage />
                </RequireAnyAuth>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <RequireAnyAuth>
                  <UpdateProfilePage />
                </RequireAnyAuth>
              }
            />
            <Route
              path="/ride-request/:clientId?"
              element={
                <RequireClientAuth>
                  <RequestRidePage />
                </RequireClientAuth>
              }
            />
            <Route
              path="/make-payment"
              element={
                <StripeContextWrapper>
                  <MakePaymentPage />
                </StripeContextWrapper>
              }
            />
            <Route
              path="/account-history"
              element={
                <RequireClientAuth>
                  <AccountHistory />
                </RequireClientAuth>
              }
            />
            <Route
              path="/trips/:rideId"
              element={
                <RequireAnyAuth>
                  <ViewRidePage />
                </RequireAnyAuth>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </div>
      </UserContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
