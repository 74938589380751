import { Grid, TextField } from '@mui/material';

import { NotesModel } from '../../../models/driver_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  notesInfo: NotesModel;
  setNotesInfo: React.Dispatch<React.SetStateAction<NotesModel>>;
}

export default function Notes(props: InfoProps) {
  const { notesInfo, setNotesInfo } = props;

  const updateNotesKey = (key: keyof NotesModel, val: string) => {
    setNotesInfo((obj: NotesModel) => {
      const objCopy: NotesModel = { ...obj };
      objCopy[key] = val;
      return objCopy;
    });
  };

  return (
    <BoxContainer header="Notes">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12}>
          <h4>Driver Notes *</h4>
          <TextField
            value={notesInfo.driverNotes}
            multiline={true}
            minRows={10}
            maxRows={10}
            onChange={(e) => updateNotesKey('driverNotes', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Extra Vehicle Notes *</h4>
          <TextField
            value={notesInfo.vehicleNotes}
            multiline={true}
            minRows={10}
            maxRows={10}
            onChange={(e) => updateNotesKey('vehicleNotes', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
