import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoginRequiredPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const navToLoginAux = () => {
    if (location.pathname.includes('/trips')) {
      const rideId = location.pathname.split('/').pop() as string;
      localStorage.setItem('rideId', rideId);
    }
    navigate('/login');
  };

  return (
    <div>
      <h1>This page can't be accessed without logging in.</h1>
      <Button onClick={navToLoginAux}>Login</Button>
    </div>
  );
}
