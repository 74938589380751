import { useState, useContext, useEffect } from 'react';

import {
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Paper,
  SxProps,
  Theme,
} from '@mui/material';

import { Colors } from '../constants';
import { getDriverRideSummary } from '../dbconnector/users';
import { UserContext } from '../models/user_context';

export default function YearRides(props: { sx?: SxProps<Theme> | undefined }) {
  const [year, setYear] = useState<string>('');
  const [numRidesCompleted, setNumRidesCompleted] = useState<string>('');
  const [numRidesAccepted, setNumRidesAccepted] = useState<string>('');
  const [mileage, setMileage] = useState<string>('');

  const { userId, userToken } = useContext(UserContext);

  useEffect(() => {
    async function downloadPaymentInfo() {
      const token = userToken;
      const driverRideSummary = await getDriverRideSummary(userId, token);
      setYear(driverRideSummary?.year ?? '');
      setNumRidesCompleted(
        driverRideSummary?.numRidesCompleted?.toString() ?? ''
      );
      setNumRidesAccepted(
        driverRideSummary?.numRidesAccepted?.toString() ?? ''
      );
      setMileage(driverRideSummary?.mileage?.toString() ?? '');
    }

    downloadPaymentInfo();
  }, [userId, userToken]);

  return (
    <TableContainer component={Paper} sx={props.sx}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={2}
              style={{ backgroundColor: Colors.greyTextBackground }}
            >
              Ride Summary
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>{year}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Rides Completed</TableCell>
            <TableCell>{numRidesCompleted}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Rides Accepted</TableCell>
            <TableCell>{numRidesAccepted}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Mileage</TableCell>
            <TableCell>{Number(mileage).toFixed(1)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
