import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

import {
  Box,
  Column,
  Heading,
  Text,
  Row,
  License,
  Container,
  Link,
} from './FooterStyledComponents';

function Footer() {
  return (
    <Box>
      <Container>
        <Row>
          <Column>
            <Heading>Mission</Heading>
            <Text>
              Neighbor Ride enhances the health and quality of life for Howard
              County's seniors by providing affordable, volunteer-based
              supplemental transportation.
            </Text>
          </Column>
          <Column>
            <Heading>Office</Heading>
            <Text>
              5570 Sterrett Place, Suite 102
              <br />
              Columbia, MD 21044
              <br />
              <Link
                href="https://www.google.com/maps/dir//5570 Sterrett Place, Suite 102, Columbia, MD 21044"
                target="_blank"
              >
                Get Directions
              </Link>
            </Text>
          </Column>
          <Column>
            <Heading>Contacts</Heading>
            <Text>
              Phone: 410-884-RIDE (7433)
              <br />
              Email: information@neighborride.org
              <br />
              Fax: 410-884-4166
            </Text>
          </Column>
          <Column>
            <Heading>Connect</Heading>
            <Link href="https://www.facebook.com/NeighborRide/" target="_blank">
              <FacebookOutlinedIcon fontSize="large" />
            </Link>
          </Column>
        </Row>
      </Container>
      <License>
        ©{new Date().getFullYear()} Neighbor Ride. All rights reserved.
      </License>
    </Box>
  );
}

export default Footer;
