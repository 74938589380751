import { Grid, TextField } from '@mui/material';

import { ContactModel } from '../../../models/client_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  contactInfo: ContactModel;
  setContactInfo: React.Dispatch<React.SetStateAction<ContactModel>>;
}

export default function Contact(props: InfoProps) {
  const { contactInfo, setContactInfo } = props;

  return (
    <BoxContainer header="Contact">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12} md={6}>
          <h3>Primary Phone</h3>
          <TextField
            fullWidth
            value={contactInfo.homePhone}
            onChange={({ target }) =>
              setContactInfo({ ...contactInfo, homePhone: target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>Alternate Phone</h3>
          <TextField
            fullWidth
            value={contactInfo.cellPhone}
            onChange={({ target }) =>
              setContactInfo({ ...contactInfo, cellPhone: target.value })
            }
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
