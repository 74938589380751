import React, { useContext, useEffect, useState, useRef } from 'react';

import { Box, Button } from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { PageWrapper, PageTitle } from '../../components/PageComponents';
import {
  AdditionalPassenger,
  getRideAndClientAndDriver,
} from '../../dbconnector/rides';
import { AuthState } from '../../models/auth_state';
import { BasicClient, IDriverAddress } from '../../models/client_profile';
import { FirebaseRide } from '../../models/rides';
import { UserContext } from '../../models/user_context';
import { RideInfoPane } from './components/RideInfoPane';
import RideSidebarActions from './components/RideSidebarActions';

export function ViewRidePage() {
  const { rideId } = useParams();
  const { userId, userToken, authState, isMobile } = useContext(UserContext);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `ride_${rideId}`,
  });

  const [ride, setRide] = useState<FirebaseRide | null>();
  const [client, setClient] = useState<BasicClient>();
  const [driver, setDriver] = useState<IDriverAddress>();
  const [additionalPassengers, setAdditionalPassengers] = useState<
    AdditionalPassenger[]
  >([]);
  const [isPending, setIsPending] = useState(true);

  const loadRide = async () => {
    const data = await getRideAndClientAndDriver(
      userId,
      userToken,
      rideId as string
    );

    if (
      authState === AuthState.Driver &&
      data.ride.trip_status.toLowerCase() !== 'pending' &&
      data.ride.driver_id !== userId
    ) {
      setIsPending(false);
    }

    setRide(data.ride);
    setClient(data.client);
    setDriver(data.driverInfo);
    setAdditionalPassengers(data.additionalPassengers);
  };

  useEffect(() => {
    loadRide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataNotReady = !ride || !client;

  if (dataNotReady) {
    return null;
  }

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <Box ref={ref} sx={{ margin: isMobile ? 0 : 1 }}>
        <RideInfoPane
          ride={ride}
          client={client}
          driver={driver}
          additionalPassengersInfo={additionalPassengers}
        />
      </Box>
    );
  });

  return (
    <PageWrapper>
      {isPending ? (
        <>
          <PageTitle title="Ride" />
          <Box>
            <RideSidebarActions
              isPending={
                ride?.trip_status.toString().toLowerCase() === 'pending'
              }
              rideId={ride.id}
              driverId={userId}
              userToken={userToken}
              isDriver={authState === AuthState.Driver}
              isMobile={isMobile}
              handlePrint={() => handlePrint()}
              loadRide={loadRide}
            />
            <h1 style={{ margin: '30px 0' }}>
              Thank you for viewing this ride!
            </h1>
            <ComponentToPrint ref={componentRef} />
          </Box>
        </>
      ) : (
        <>
          <h3>
            Thank you for considering this ride, however it is no longer
            available. Please consider taking another ride.
          </h3>
          <NavLink to="/driver-rides">
            <Button variant="contained">View Pending Rides</Button>
          </NavLink>
        </>
      )}
    </PageWrapper>
  );
}
