import {
  FirebaseDriverAvailability,
  WeekAvailability,
} from './driver_schedule';

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const getDateString = (date1: number | string | Date): string => {
  const date = new Date(date1);
  return `${days[date.getDay()]}, ${
    months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};

export interface PaymentInfo {
  paymentMethod: string;
  latestPaymentDate: string;
  latestPaymentAmount: string;
  balance: string;
}

export interface RidesInfo {
  currMonthName: string;
  nextMonthName: string;
  next2MonthName: string;
  currMonthNum: number;
  nextMonthNum: number;
  next2MonthNum: number;
}

export interface DriverRideSummary {
  year: string;
  numRidesCompleted: string;
  numRidesAccepted: string;
  mileage: string;
}

export const getTimeString = (date1: number | string | Date | null): string => {
  if (!date1) {
    return '';
  }
  const date = new Date(date1);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const newMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  const strTime = `${hours}:${newMinutes} ${ampm}`;
  return strTime;
};

export const getMillisecondsFromTimeString = (timeStr: string): number => {
  // Format: H:MM{AM/PM}
  try {
    const colonIndex = timeStr.indexOf(':');
    const hoursStr = timeStr.substring(0, colonIndex);
    const minutesStr = timeStr.substring(colonIndex + 1, colonIndex + 3);
    const bucketStr = timeStr.substring(colonIndex + 3, timeStr.length);
    const hoursSeconds = (parseInt(hoursStr) % 12) * 3600;
    const minutesSeconds = parseInt(minutesStr) * 60;
    const bucketSeconds = bucketStr.toLowerCase() === 'pm' ? 3600 : 0;
    return (hoursSeconds + minutesSeconds + bucketSeconds) * 1000;
  } catch {
    return 0;
  }
};

export const getRandomWeekAvailability = (userId: string): WeekAvailability => {
  const weekAvailability: WeekAvailability = {
    '0': getRandomDriverAvailability('0', userId),
    '1': getRandomDriverAvailability('1', userId),
    '2': getRandomDriverAvailability('2', userId),
    '3': getRandomDriverAvailability('3', userId),
    '4': getRandomDriverAvailability('4', userId),
    '5': getRandomDriverAvailability('5', userId),
    '6': getRandomDriverAvailability('6', userId),
  };
  return weekAvailability;
};

export const getRandomDriverAvailability = (
  dowId: string,
  userId: string
): FirebaseDriverAvailability => {
  const availability: FirebaseDriverAvailability = {
    available: '0',
    body: '',
    created_at: '',
    dow_id: dowId,
    end: '',
    id: getRandomAlphanumbericString(),
    start: '',
    status_id: '0',
    updated_at: '',
    user_id: userId,
  };
  return availability;
};

export const getRandomAlphanumbericString = (): string => {
  return (Math.random() + 1).toString(36).substring(7);
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
