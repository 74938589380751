export interface GeneralInfoModel {
  username?: string;
  firstName: string;
  lastName: string;
  role: string;
  birthDate?: Date;
  gender: string;
}

export interface ContactModel {
  cellPhone: string;
  homePhone: string;
  workPhone: string;
}

export interface RidePreferencesModel {
  dailyCall: boolean;
  emergencyList: boolean;
  howardOnly: boolean;
  notificationPreferences: string;
  notifyAcceptedRide: boolean;
  notifyBeforeRide: boolean;
}

export interface LocationModel {
  developmentName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface NotesModel {
  driverNotes: string;
  vehicleNotes: string;
}

export interface DriverInfoModel {
  insuranceCompany: string;
  insuranceExpiration: Date;
  licenseExpiration: Date;
  vehicleType: string;
  vehicleHighStepRestriction: string;
  vehiclePassengerLimit: string;
}

export interface EmergencyModel {
  emergencyName: string;
  emergencyPhone: string;
}

export interface DriverProfileModel {
  primaryPhone: string;
  email: string;
  userId: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleType: string;
  vehicleHighStepRestriction: boolean;
  vehiclePassengerLimit: number;
  alternativePhone1: string;
  groupCode: string;
  alternativePhone2: string;
  gender: string;
  communicationPref: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  dailyCall: boolean;
  emergencyList: boolean;
  howardOnly: string;
  notifyAcceptedRide: boolean;
  notifyBeforeRide: boolean;
  developmentName: string;
  driverNotes: string;
  vehicleNotes: string;
  auditHistory: string;
}
export interface DriverPrivateProfileModel {
  insuranceCompany: string;
  insuranceExpiration: Date;
  insuranceImgUrl: string;
  license: string;
  licenseExpiration: Date;
  onVacation: boolean;
  volunteerGroup: string;
  emergencyContactName: string;
  emergencyContactPhone: string;
  birthdate: string;
}

export interface NeighborRideDriver {
  user_id: string;
  vehicle_type_id: string;
  birth_date: string;
  gender: string;
  registration_date: string;
  driver_status: string;
  driver_inactive_reason: string;
  status_date: string;
  status_last_changed: string;
  start_date: string;
  volunteer_type: string;
  account_number: string;
  referral_source: string;
  primary_language: string;
  payment_method: string;
  health_issues: string;
  development_name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  home_phone: string;
  work_phone: string;
  cell_phone: string;
  profile_comm_preference_id: string;
  profile_ride_confirmation_accept: string;
  profile_ride_reminder_day_before: string;
  emergency_contact_name: string;
  emergency_contact_address: string;
  emergency_contact_city: string;
  emergency_contact_state: string;
  emergency_contact_zip: string;
  emergency_contact_phone: string;
  emergency_contact_alternate_phone: string;
  emergency_contact_relationship: string;
  emergency_contact_2_name: string;
  emergency_contact_2_address: string;
  emergency_contact_2_city: string;
  emergency_contact_2_state: string;
  emergency_contact_2_zip: string;
  emergency_contact_2_phone: string;
  emergency_contact_2_alternate_phone: string;
  emergency_contact_2_relationship: string;
  ride_alert_all_available: string;
  ride_alert_all_call: string;
  ride_alert_last_resort: string;
  howard_county: string;
  vehicle_specific_notes: string;
  insurance_company: string;
  insurance_number: string;
  insurance_expiration: string;
  insurance_card_image: string;
  drivers_license_number: string;
  drivers_license_expiration: string;
  drivers_license_image: string;
  vehicle_passenger_limit: string;
  vehicle_high_step_restriction: string;
  driver_notes: string;
  ride_coord_notes: string;
  profile_new: string;
  created_at: string;
  updated_at: string;
}

export const getDriverCommunicationPreferenceFromId = (id: string): string => {
  if (id === '1') {
    return 'Call';
  } else if (id === '2') {
    return 'Email and Online';
  } else if (id === '3') {
    return 'Online Only';
  } else {
    return 'Unknown';
  }
};

export const getVehicleTypeFromId = (id: string): string => {
  if (id === '1') {
    return 'Sedan';
  } else if (id === '2') {
    return 'Small SUV';
  } else if (id === '3') {
    return 'SUV with Running Board';
  } else if (id === '4') {
    return 'Compact';
  } else {
    return 'Unknown';
  }
};

export const getDriverCommunicationPreferenceIdFromName = (
  name: string
): string => {
  const lowerName = name.toLowerCase();
  if (lowerName === 'call') {
    return '1';
  } else if (lowerName === 'email and online') {
    return '2';
  } else if (lowerName === 'online only') {
    return '3';
  } else {
    return '-1';
  }
};

export const getVehicleTypeIdFromName = (name: string): string => {
  const lowerName = name.toLowerCase();
  if (lowerName === 'sedan') {
    return '1';
  } else if (lowerName === 'small suv') {
    return '2';
  } else if (lowerName === 'suv with running board') {
    return '3';
  } else if (lowerName === 'compact') {
    return '4';
  } else {
    return '-1';
  }
};
