import { useContext, useEffect, useState } from 'react';

import {
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Paper,
  SxProps,
  Theme,
} from '@mui/material';

import { Colors } from '../constants';
import { getPaymentInfo } from '../dbconnector/users';
import { UserContext } from '../models/user_context';
import { getPrettyDate } from '../pages/utils';

export default function AccountInformation(props: {
  sx?: SxProps<Theme> | undefined;
}) {
  const [paymentMethod, setPaymentMethod] = useState<string>('Loading...');
  const [balance, setBalance] = useState<string>('Loading...');
  const [latestPaymentAmount, setLatestPaymentAmount] =
    useState<string>('Loading...');
  const [latestPaymentDate, setLatestPaymentDate] =
    useState<string>('Loading...');

  const { userId, userToken } = useContext(UserContext);

  useEffect(() => {
    async function downloadPaymentInfo() {
      const token = userToken;
      const paymentInfo = await getPaymentInfo(userId, token);
      setPaymentMethod(paymentInfo.paymentMethod);
      setBalance(paymentInfo.balance);
      setLatestPaymentAmount(paymentInfo.latestPaymentAmount);
      setLatestPaymentDate(
        paymentInfo.latestPaymentDate
          ? getPrettyDate(paymentInfo.latestPaymentDate)
          : 'None'
      );
    }

    downloadPaymentInfo();
  }, [userId, userToken]);

  return (
    <TableContainer component={Paper} sx={props.sx}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={2}
              style={{ backgroundColor: Colors.greyTextBackground }}
            >
              Account
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Payment Method:</TableCell>
            <TableCell>{paymentMethod}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Current Balance:</TableCell>
            <TableCell>{balance}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Transaction Amount:</TableCell>
            <TableCell>{latestPaymentAmount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Transaction Date:</TableCell>
            <TableCell>{latestPaymentDate}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
