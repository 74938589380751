import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { BottomNavigationAction } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const WhiteTextButton = styled(Button)({
  color: 'white',
  borderColor: 'white',
  ':hover': {
    borderColor: 'white',
  },
});

export const WhiteMenuIcon = styled(MenuIcon)({
  color: 'white',
});

export const WhitePersonIcon = styled(PersonIcon)({
  color: 'white',
});

export const WhiteLogoutIcon = styled(LogoutIcon)({
  color: 'white',
});

export const WhiteBottomNavigationAction = styled(BottomNavigationAction)({
  color: 'white',
});
