/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Paper } from '@mui/material';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

export default function RidePaper({
  ride,
  handleAcceptRide,
}: {
  ride: any;
  handleAcceptRide?: () => void;
}) {
  const auxA = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  };

  const auxB: React.CSSProperties = {
    maxWidth: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
  };

  return (
    <Paper style={{ marginBottom: '50px' }}>
      <Box sx={auxA}>
        <span>Date</span>
        <span style={{ fontWeight: 'bold' }}>
          {moment(ride.date).format('DD-MMM-YYYY (ddd)')}
        </span>
      </Box>
      <Box sx={auxA}>
        <span>Client</span>
        <span style={auxB}>{ride.client}</span>
      </Box>
      <Box sx={auxA}>
        <span>Type</span>
        <span style={{ fontWeight: 'bold' }}>{ride.rideType}</span>
      </Box>
      <Box sx={auxA}>
        <span>Direction</span>
        <span style={{ fontWeight: 'bold' }}>{ride.tripDirection}</span>
      </Box>
      <Box sx={auxA}>
        <span>Destination</span>
        <span style={auxB}>{ride.destination}</span>
      </Box>
      <Box sx={auxA}>
        <span>Pick-Up</span>
        <span style={{ fontWeight: 'bold' }}>
          {moment(ride.pickup, 'HH:mm:ss').format('h:mm A')}
        </span>
      </Box>
      <Box sx={auxA}>
        <span>Return</span>
        <span style={{ fontWeight: 'bold' }}>
          {ride.return ? moment(ride.return, 'HH:mm:ss').format('h:mm A') : ''}
        </span>
      </Box>
      <Box sx={auxA}>
        <span>Status</span>
        <span style={{ fontWeight: 'bold' }}>{ride.status}</span>
      </Box>
      <Box>
        <NavLink to={`/trips/${ride.id}`} key={ride.id}>
          <Button>View</Button>
        </NavLink>
        {handleAcceptRide && <Button onClick={handleAcceptRide}>Accept</Button>}
      </Box>
    </Paper>
  );
}
