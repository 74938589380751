import { Grid, TextField } from '@mui/material';

import { LocationModel } from '../../../models/driver_profile';
import BoxContainer from './BoxContainer';

interface InfoProps {
  addressInfo: LocationModel;
  setAddressInfo: React.Dispatch<React.SetStateAction<LocationModel>>;
}

export default function Address(props: InfoProps) {
  const { addressInfo, setAddressInfo } = props;

  return (
    <BoxContainer header="Address">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12} md={4}>
          <h3>Address *</h3>
          <TextField
            fullWidth
            value={addressInfo.address}
            onChange={({ target }) =>
              setAddressInfo({ ...addressInfo, address: target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>Address 2</h3>
          <TextField
            fullWidth
            value={addressInfo.address2}
            onChange={({ target }) =>
              setAddressInfo({ ...addressInfo, address2: target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>Development Name</h3>
          <TextField
            fullWidth
            value={addressInfo.developmentName}
            onChange={({ target }) =>
              setAddressInfo({ ...addressInfo, developmentName: target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>City *</h3>
          <TextField
            fullWidth
            value={addressInfo.city}
            onChange={({ target }) =>
              setAddressInfo({ ...addressInfo, city: target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>State *</h3>
          <TextField
            fullWidth
            value={addressInfo.state}
            onChange={({ target }) =>
              setAddressInfo({ ...addressInfo, state: target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>Zip Code *</h3>
          <TextField
            fullWidth
            value={addressInfo.zipCode}
            onChange={({ target }) =>
              setAddressInfo({ ...addressInfo, zipCode: target.value })
            }
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
