import { Box } from '@mui/material';

export function Header({ header }: { header: string }) {
  return (
    <Box
      sx={{
        padding: '0.5em',
        fontSize: '24px',
        backgroundColor: 'rgb(221, 221, 221)',
      }}
    >
      <b>{header}</b>
    </Box>
  );
}

export function Subheader({ subheader }: { subheader: string }) {
  return (
    <Box
      sx={{
        fontSize: '26px',
        overflowWrap: 'break-word',
        textAlign: 'start',
        paddingX: '8px',
        marginY: '10px',
      }}
    >
      <b>{subheader}</b>
    </Box>
  );
}

export function KeyValue({
  inputKey,
  value,
}: {
  inputKey: string;
  value: string;
}) {
  return (
    <Box
      sx={{
        lineHeight: 1.5,
        overflowWrap: 'break-word',
        textAlign: 'start',
        paddingX: '8px',
      }}
    >
      <b>{inputKey}</b>: {value}
    </Box>
  );
}
