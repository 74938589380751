import { SetStateAction, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  Snackbar,
} from '@mui/material';
import * as EmailValidator from 'email-validator';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function ResetPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);

  const openSnackBar = () => {
    setShowSnackBar(true);
  };
  const hideSnackBar = () => {
    setShowSnackBar(false);
  };

  const changeEmail = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setEmail(event.target.value);
  };

  const handlePasswordResetRequest = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        openSnackBar();
      })
      .catch(() => {
        setError('There was an error sending you a password reset email.');
      });
  };

  return (
    <Box
      sx={{
        display: { xs: 'flex' },
        alignSelf: 'center',
        margin: '50px 10px 50px 10px',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px 20px 20px 20px',
        }}
      >
        <h2>Reset Password</h2>
        <p style={{ marginBottom: '30px' }}>
          Enter your email to get a password reset link.
        </p>
        <FormControl sx={{ marginBottom: '20px' }}>
          <InputLabel htmlFor="email" error={error !== ''}>
            Email
          </InputLabel>
          <OutlinedInput
            id="email"
            label="Email"
            value={email}
            error={error !== ''}
            onChange={changeEmail}
            aria-describedby="email-error-text"
          />
          <FormHelperText error id="email-error-text">
            {error}
          </FormHelperText>
        </FormControl>
        <Button
          variant="contained"
          sx={{ marginBottom: '10px' }}
          onClick={() => {
            if (EmailValidator.validate(email)) {
              setError('');
              handlePasswordResetRequest();
            } else {
              setError('Invalid email.');
            }
          }}
        >
          Request Password Reset Link
        </Button>
        <Button
          variant="text"
          sx={{ marginBottom: '20px' }}
          onClick={() => {
            navigate('/login');
          }}
        >
          Return to sign in page
        </Button>
        <p>
          Call 410-884-RIDE (7433) to request a ride if you experience problems
          signing in.
        </p>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackBar}
        onClose={hideSnackBar}
        message={`We've sent a password reset link to ${email}`}
        key="PasswordResetSnackbar"
      />
    </Box>
  );
}

export default ResetPasswordPage;
