import { useContext, useEffect, useState } from 'react';

import { Box, Grid, Stack, TextField } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

import { PageWrapper, PageTitle } from '../../../components/PageComponents';
import YearRides from '../../../components/YearRides';
import { getPastDriverRides } from '../../../dbconnector/rides';
import { DriverHistoryTableRide, FirebaseRide } from '../../../models/rides';
import { UserContext } from '../../../models/user_context';
import {
  driverRideHistoryColumns,
  getDatestringFromDate,
} from '../../DashboardPage/utils';
import { sortRides } from '../../utils';
import { convertFirebaseRidesToDriverHistoryTableRides } from '../utils';

function ExportToolbar(fileName: string) {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{ fileName }} />
    </GridToolbarContainer>
  );
}

export function DriverRideHistoryPage() {
  const { userId, userToken, isMobile, userFullName } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);
  const [pastRides, setPastRides] = useState<DriverHistoryTableRide[]>([]);
  const [filteredRides, setFilteredRides] = useState<DriverHistoryTableRide[]>(
    []
  );

  const oneMonthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState<string>(oneMonthAgo);
  const [toDate, setToDate] = useState<string>(
    getDatestringFromDate(new Date())
  );

  const changeFromDate = (newValue: Date | null) => {
    if (!newValue) {
      setFromDate('2019-06-00');
    } else {
      setFromDate(getDatestringFromDate(newValue));
    }
  };
  const changeToDate = (newValue: Date | null) => {
    if (!newValue) {
      setFromDate(getDatestringFromDate(new Date()));
    } else {
      setToDate(getDatestringFromDate(newValue));
    }
  };

  useEffect(() => {
    async function downloadPastRides() {
      const token = userToken;
      const newPastFirebaseRides: FirebaseRide[] = await getPastDriverRides(
        userId,
        token
      );
      const newPastRides =
        convertFirebaseRidesToDriverHistoryTableRides(newPastFirebaseRides);
      setPastRides(newPastRides);
      setFilteredRides(newPastRides);
      setIsLoading(false);
    }
    downloadPastRides();
  }, [userId, userToken]);

  useEffect(() => {
    const newFilteredRides = pastRides.filter((ride) => {
      return ride.date >= fromDate && ride.date <= toDate;
    });
    setFilteredRides(newFilteredRides);
  }, [fromDate, toDate, pastRides]);

  return (
    <PageWrapper>
      <PageTitle title="Ride History" />
      <Grid
        container
        justifyContent="space-between"
        sx={{ marginBottom: '20px' }}
      >
        <Grid
          item
          xs={12}
          md={5.5}
          sx={{ marginBottom: isMobile ? '20px' : 0 }}
        >
          <Stack>
            <DesktopDatePicker
              label="From Date"
              inputFormat="MM/DD/YYYY"
              value={fromDate}
              onChange={changeFromDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Stack>
            <DesktopDatePicker
              label="To Date"
              inputFormat="MM/DD/YYYY"
              value={toDate}
              onChange={changeToDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </Grid>
      </Grid>
      <DataGrid
        rows={[
          {
            id: 'total',
            mileage: `Total: ${filteredRides
              .reduce((acc, curr) => acc + Number(curr.mileage), 0)
              .toFixed(1)}`,
          },
          ...sortRides(filteredRides),
        ]}
        columns={driverRideHistoryColumns}
        components={{
          Toolbar: () =>
            ExportToolbar(
              `${userFullName
                .toLowerCase()
                .replace(' ', '_')}_ride_history_from_${fromDate}_to_${toDate}`
            ),
        }}
        loading={isLoading}
        autoHeight
        pageSize={10}
      />
      <Box sx={{ marginBottom: '20px' }} />
      <YearRides />
    </PageWrapper>
  );
}
