import dayjs from 'dayjs';

import {
  ClientHistoryTableRide,
  DriverHistoryTableRide,
  FirebaseRide,
} from '../../models/rides';

export const convertFirebaseRidesToDriverHistoryTableRides = (
  firebaseRides: FirebaseRide[]
): DriverHistoryTableRide[] => {
  const tableRides: DriverHistoryTableRide[] = [];
  for (let i = 0; i < firebaseRides.length; i++) {
    const tableRide = convertFirebaseRideToDriverHistoryTableRide(
      firebaseRides[i]
    );
    tableRides.push(tableRide);
  }
  return tableRides;
};

export const convertFirebaseRideToDriverHistoryTableRide = (
  firebaseRide: FirebaseRide
): DriverHistoryTableRide => {
  const historyRide: DriverHistoryTableRide = {
    id: firebaseRide.id,
    date: firebaseRide.pickup_date,
    destination: firebaseRide.destination_name,
    client: firebaseRide.client_name,
    mileage: firebaseRide.vol_miles,
    clientAddress: firebaseRide.pickup_address,
    tripStatus: firebaseRide.trip_status,
  };
  return historyRide;
};

export const convertFirebaseRidesToClientHistoryTableRides = (
  firebaseRides: FirebaseRide[]
): ClientHistoryTableRide[] => {
  const rides: ClientHistoryTableRide[] = [];
  for (let i = 0; i < firebaseRides.length; i++) {
    const tableRide = convertFirebaseRideToClientHistoryTableRide(
      firebaseRides[i]
    );
    rides.push(tableRide);
  }
  return rides;
};

export const convertFirebaseRideToClientHistoryTableRide = (
  firebaseRide: FirebaseRide
): ClientHistoryTableRide => {
  const historyRide: ClientHistoryTableRide = {
    id: firebaseRide.id,
    date: firebaseRide.pickup_date,
    driver: firebaseRide.driverName,
    destination: firebaseRide.destination_name,
    cost: firebaseRide.cost,
  };
  return historyRide;
};

export const getDateFromDatestring = (datestring: string) => {
  return dayjs(datestring).toDate();
};
