import { useState, useEffect, useContext } from 'react';

import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import BackToDashboardButton from '../../components/BackToDashboardButton';
import GridItem, { GridItemContent } from '../../components/GridItem';
import { PageWrapper, PageTitle } from '../../components/PageComponents';
import { getClientProfile, getUserProfile } from '../../dbconnector/users';
import { NeighborRideClient } from '../../models/client_profile';
import { NeighborRideUser } from '../../models/user';
import { UserContext } from '../../models/user_context';
import { getGroupCode } from './helpers';

export default function ClientProfilePage() {
  const navigate = useNavigate();
  const { userId, userToken, verifySession } = useContext(UserContext);

  const [clientProfile, setClientProfile] =
    useState<NeighborRideClient | null>();
  const [userProfile, setUserProfile] = useState<NeighborRideUser | null>();
  const [groupCode, setGroupCode] = useState('');

  useEffect(() => {
    getGroupCode(userId, userToken)
      .then((code) => setGroupCode(code))
      .catch((err) => {
        if (err.message === 'Forbidden') {
          verifySession(403);
        }
      });
    const getData = async () => {
      const token = userToken;
      const inputClientProfile: NeighborRideClient = await getClientProfile(
        userId,
        token
      );
      const inputUserProifle: NeighborRideUser = await getUserProfile(
        userId,
        token
      );
      setClientProfile(inputClientProfile);
      setUserProfile(inputUserProifle);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userToken]);

  if (!clientProfile || !userProfile) {
    return null;
  }

  // Split up collected profile data into their corresponding tables
  const general: GridItemContent = {
    Name: userProfile?.first_name
      ? `${userProfile.first_name} ${userProfile.last_name}`
      : '',
    Username: userProfile.email,
    Birthdate: clientProfile.birth_date,
    Gender: clientProfile.gender,
    Role: 'Client',
    'Group Code(s)': groupCode,
  };
  const contact: GridItemContent = {
    'Primary Phone': clientProfile.home_phone,
    'Alternate Phone': clientProfile.cell_phone,
    Email: userProfile.email,
  };
  const address: GridItemContent = {
    Address: clientProfile.address1,
    'Address 2': clientProfile.address2,
    'Development Name': clientProfile.development_name,
    City: clientProfile.city,
    State: clientProfile.state,
    Zip: clientProfile.zip,
  };
  const emergencyContact: GridItemContent = {
    Name: clientProfile.emergency_contact_name,
    Phone: clientProfile.emergency_contact_phone,
    'Alternate Phone': clientProfile.emergency_contact_alternate_phone,
    'Address/Apt': clientProfile.emergency_contact_address,
    City: clientProfile.emergency_contact_city,
    State: clientProfile.emergency_contact_state,
    Zip: clientProfile.emergency_contact_zip,
    Relationship: clientProfile.emergency_contact_relationship,
  };
  const emergencyContact2: GridItemContent = {
    Name: clientProfile.emergency_contact_2_name,
    Phone: clientProfile.emergency_contact_2_phone,
    'Alternate Phone': clientProfile.emergency_contact_2_alternate_phone,
    'Address/Apt': clientProfile.emergency_contact_2_address,
    City: clientProfile.emergency_contact_2_city,
    State: clientProfile.emergency_contact_2_state,
    Zip: clientProfile.emergency_contact_2_zip,
    Relationship: clientProfile.emergency_contact_2_relationship,
  };

  return (
    <PageWrapper>
      <PageTitle title="Profile" />
      <Box
        sx={{
          display: 'flex',
          marginBottom: '30px',
          justifyContent: 'space-between',
        }}
      >
        <BackToDashboardButton />
        <Button variant="contained" onClick={() => navigate('/edit-profile')}>
          Edit Profile
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <GridItem content={general} header="General Information" />
        <GridItem content={contact} header="Contact" />
        <GridItem content={address} header="Address" />
        <GridItem content={emergencyContact} header="Emergency Contact" />
        <GridItem content={emergencyContact2} header="Emergency Contact 2" />
      </Box>
    </PageWrapper>
  );
}
