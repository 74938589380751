import { useContext, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Alert,
  ClickAwayListener,
  Modal,
  Typography,
  Divider,
} from '@mui/material';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

import { PageTitle, PageWrapper } from '../../components/PageComponents';
import { Colors } from '../../constants';
import { getClientProfile, getUserProfile } from '../../dbconnector/users';
import { AuthState } from '../../models/auth_state';
import { UserContext } from '../../models/user_context';
import { BASE_API_URL } from '../../models/utils';
import {
  BirthdayInput,
  amountHelper,
  birthdayHelper,
  messages,
  options,
} from './helpers';

function MakePaymentPage() {
  const { userId, userToken, authState, isMobile } = useContext(UserContext);

  const [amount, setAmount] = useState(25);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentType, setPaymentType] = useState('1');
  const [amountDisabled, setAmountDisabled] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [clientId, setClientId] = useState('');
  const [blankFields, setBlankFields] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [checkingClient, setCheckingClient] = useState(false);
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const getData = async () => {
      const token = userToken;
      const clientProfile = await getClientProfile(userId, token);
      const userProfile = await getUserProfile(userId, token);
      setFirstName(userProfile.first_name);
      setLastName(userProfile.last_name);
      setEmail(userProfile.email);
      setBirthday(birthdayHelper(clientProfile.birth_date));
    };
    if (authState === AuthState.Client) {
      getData();
    }
  }, [userId, userToken, authState]);

  useEffect(() => {
    if (paymentType === '1') {
      setAmountDisabled(false);
    } else if (paymentType === '5') {
      setAmount(45);
      setAmountDisabled(true);
    }
  }, [paymentType]);

  const validateEmail = (emailCandidate: string) => {
    if (!emailCandidate.match(/[a-z|0-9]*@[a-z]*\.(com|org|edu|net)/)) {
      setInvalidEmail(true);
      setEmail(emailCandidate.toLowerCase());
    } else {
      setInvalidEmail(false);
      setEmail(emailCandidate.toLowerCase());
    }
  };

  const validateClient = async () => {
    if (authState !== AuthState.Client) {
      if (!firstName || !lastName || !birthday || !email) {
        setBlankFields(true);
        return;
      }

      if (invalidEmail || invalidDate) {
        return;
      }

      try {
        setCheckingClient(true);
        const res = await fetch(
          `${BASE_API_URL}/user/${email.trim().toLowerCase()}`
        );
        const { user } = await res.json();
        if (user.last_name.toLowerCase() !== lastName.trim().toLowerCase()) {
          throw new Error('Invalid name.');
        }

        setClientId(user.user_id);

        const res2 = await fetch(`${BASE_API_URL}/birthdate/${user.user_id}`);
        const json = await res2.json();
        if (birthdayHelper(json.birthDate) !== birthday) {
          throw new Error('Invalid birthday.');
        }
        setCheckingClient(false);
        setModalOpened(true);
      } catch (err) {
        console.log(err);
        setCheckingClient(false);
        setErrorMessage(messages.CLIENT_NOT_FOUND);
      }
    } else {
      setModalOpened(true);
    }
  };

  const makePayment = async () => {
    setCheckingPayment(true);
    if (!stripe || !elements) {
      setCheckingPayment(false);
      return;
    }

    const payment = await fetch(`${BASE_API_URL}/payment`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodType: 'card',
        amount: Math.ceil((amount / 0.978 + 0.3) * 100) / 100,
        currency: 'usd',
        email,
        name: `${firstName} ${lastName}`,
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    const { clientSecret } = payment;

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setCheckingPayment(false);
      return;
    }

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

    if (stripeError) {
      setCheckingPayment(false);
      setErrorMessage(stripeError.message || '');
      console.log(stripeError);
      return;
    }

    console.log('Payment Intent: ', paymentIntent);

    if (paymentIntent?.status === 'succeeded') {
      await fetch(`${BASE_API_URL}/paymentDoc`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId: authState === AuthState.Client ? userId : clientId,
          guestPay: authState !== AuthState.Client,
          paymentType,
          amount,
        }),
      });

      if (authState === AuthState.Client) {
        navigate('/dashboard');
      } else {
        cardElement.clear();
        setErrorMessage('');
        setFirstName('');
        setLastName('');
        setBirthday('');
        setEmail('');
        setPaymentType('1');
        setAmount(25);
        setModalOpened(false);
        setBlankFields(false);
        setCheckingPayment(false);
        setSuccess(true);
      }
    }
  };

  return (
    <PageWrapper>
      <Grid container justifyContent="space-between">
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          item
          xs={12}
          md={5.5}
        >
          <PageTitle title="Make a Payment" />
          <p style={{ textAlign: 'justify' }}>{messages.CONVENIENCE}</p>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '15px',
            }}
          >
            <h2 style={{ textAlign: 'left' }}>Passenger Info</h2>
            <TextField
              error={blankFields && !firstName}
              label="First Name"
              variant="standard"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={authState === AuthState.Client}
              sx={{ mb: '3%' }}
            />
            <TextField
              error={blankFields && !lastName}
              label="Last Name"
              variant="standard"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={authState === AuthState.Client}
              sx={{ mb: '3%' }}
            />
            <BirthdayInput
              birthday={birthday}
              setBirthday={setBirthday}
              disabled={authState === AuthState.Client}
              blankFields={blankFields}
              invalidDate={invalidDate}
              setInvalidDate={setInvalidDate}
            />
            <TextField
              error={invalidEmail || (blankFields && !email)}
              helperText={invalidEmail ? 'Invalid email.' : ''}
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => validateEmail(e.target.value)}
              disabled={authState === AuthState.Client}
              sx={{ mb: '3%' }}
            />
          </Paper>
        </Grid>
        <Box sx={{ display: { md: 'none' }, margin: '10px' }} />
        <Grid item xs={12} md={5.5}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '15px',
            }}
          >
            <h2 style={{ textAlign: 'left' }}>Payment Type</h2>
            <RadioGroup
              value={paymentType}
              onChange={(_e, value) => setPaymentType(value)}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Add Funds (Existing Passenger)"
              />
              {authState !== AuthState.Client && (
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="Registration (New Passenger)"
                />
              )}
            </RadioGroup>
            <p style={{ textAlign: 'left', fontWeight: 'bold' }}>
              {authState === AuthState.Client
                ? messages.FUNDS_MESSAGE
                : paymentType === '1'
                ? messages.FUNDS_MESSAGE
                : paymentType === '5'
                ? messages.FEE_MESSAGE
                : ''}
            </p>
            <p style={{ textAlign: 'left', color: Colors.redText }}>
              {authState === AuthState.Client
                ? messages.FUNDS_MIN_AMOUNT
                : paymentType === '1'
                ? messages.FUNDS_MIN_AMOUNT
                : paymentType === '5'
                ? messages.FEE_MIN_AMOUNT
                : ''}
            </p>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '20px',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                sx={{ fontWeight: 'bold', flex: 1, mr: '2%' }}
                onClick={() => setAmount(25)}
                disabled={amountDisabled}
              >
                $25
              </Button>
              <Button
                variant="contained"
                sx={{ fontWeight: 'bold', flex: 1, mr: '2%' }}
                onClick={() => setAmount(50)}
                disabled={amountDisabled}
              >
                $50
              </Button>
              <Button
                variant="contained"
                sx={{ fontWeight: 'bold', flex: 1 }}
                onClick={() => setAmount(100)}
                disabled={amountDisabled}
              >
                $100
              </Button>
            </Box>
            <ClickAwayListener
              onClickAway={() => {
                if (amount < 25) setAmount(25);
              }}
            >
              <TextField
                label="Payment Amount"
                value={amount}
                onChange={(e) => amountHelper(e, setAmount)}
                disabled={amountDisabled}
                sx={{ my: '12px', py: '12px' }}
              />
            </ClickAwayListener>
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                style={{
                  backgroundColor: Colors.lightGreyTextBackground,
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>Payment:</TableCell>
                    <TableCell align="right">${amount.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Processing Fee:</TableCell>
                    <TableCell align="right">
                      $
                      {(
                        Math.ceil((amount / 0.978 + 0.3 - amount) * 100) / 100
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total: </TableCell>
                    <TableCell align="right">
                      $
                      {(Math.ceil((amount / 0.978 + 0.3) * 100) / 100).toFixed(
                        2
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <LoadingButton
        loading={checkingClient}
        disabled={checkingClient}
        variant="contained"
        sx={{ mt: '20px', width: '100%', fontWeight: 'bold' }}
        onClick={validateClient}
      >
        Make a Payment
      </LoadingButton>
      <Modal
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        open={modalOpened}
      >
        <ClickAwayListener onClickAway={() => setModalOpened(false)}>
          <Paper
            elevation={24}
            sx={{
              width: isMobile ? '90%' : '30%',
              backgroundColor: 'white',
            }}
          >
            <Paper
              sx={{
                backgroundColor: Colors.mainColor,
                padding: '10px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <img
                src="/nr_logo_modern_white.svg"
                alt="Neighbor Ride"
                height="50px"
              />
            </Paper>
            <Box
              sx={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '0px 15px 15px 10px',
              }}
            >
              <h1>Payment confirmation</h1>
              <Box>
                <Typography
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  First Name:<b>{firstName}</b>
                </Typography>
                <Typography
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Last Name:
                  <b>{lastName}</b>
                </Typography>
                <Typography
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Birthday:
                  <b>{birthday}</b>
                </Typography>
                <Typography
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Email:
                  <b>{email}</b>
                </Typography>
                <Divider sx={{ my: '10px' }} />
                <Typography
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Payment value:
                  <b>$ {amount.toFixed(2)}</b>
                </Typography>
                <Typography
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Processing Fee:
                  <b>
                    ${' '}
                    {(
                      Math.ceil((amount / 0.978 + 0.3 - amount) * 100) / 100
                    ).toFixed(2)}
                  </b>
                </Typography>
                <Typography
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  Total:
                  <b>
                    ${' '}
                    {(Math.ceil((amount / 0.978 + 0.3) * 100) / 100).toFixed(2)}
                  </b>
                </Typography>
              </Box>
              <Box sx={{ my: '20px' }}>
                <CardElement options={options} />
              </Box>
              <LoadingButton
                loading={checkingPayment}
                disabled={checkingPayment}
                variant="contained"
                sx={{ fontWeight: 'bold' }}
                onClick={makePayment}
              >
                Confirm payment
              </LoadingButton>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorMessage !== ''}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        sx={{ marginTop: 10 }}
      >
        <Alert
          severity="error"
          sx={{ width: '100%', padding: '20px 30px', fontWeight: 'bold' }}
          onClose={() => setErrorMessage('')}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        sx={{ marginTop: 10 }}
      >
        <Alert
          sx={{ width: '100%', padding: '20px 30px', fontWeight: 'bold' }}
          onClose={() => setSuccess(false)}
        >
          {messages.PAYMENT_SUCCESS}
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
}

export default MakePaymentPage;
