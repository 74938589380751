import { useContext } from 'react';

import { AuthState } from '../../models/auth_state';
import { UserContext } from '../../models/user_context';
import ClientUpdateProfile from './ClientUpdateProfile/ClientUpdateProfile';
import DriverUpdateProfile from './DriverUpdateProfile/DriverUpdateProfile';

export default function UpdateProfilePage() {
  const { authState } = useContext(UserContext);

  if (authState === AuthState.Client) {
    return <ClientUpdateProfile />;
  } else if (authState === AuthState.Driver) {
    return <DriverUpdateProfile />;
  }

  return null;
}
