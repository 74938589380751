import { createContext } from 'react';

import { AuthState } from './auth_state';

interface UserContextInterface {
  authState: AuthState;
  userId: string;
  userToken: string;
  userFullName: string;
  isMobile: boolean;
  setAuthState: (authState: AuthState) => void;
  setUserId: (userId: string) => void;
  setUserToken: (token: string) => void;
  setUserFullName: (email: string) => void;
  logOut: () => void;
  verifySession: (httpStatus: number) => void;
}

export const UserContext = createContext<UserContextInterface>({
  authState: AuthState.NotAuthenticated,
  userId: '',
  userToken: '',
  userFullName: '',
  isMobile: false,
  setAuthState: () => {
    throw new Error('Not implemented');
  },
  setUserId: () => {
    throw new Error('Not implemented');
  },
  setUserToken: () => {
    throw new Error('Not implemented');
  },
  setUserFullName: () => {
    throw new Error('Not implemented');
  },
  logOut: () => {
    throw new Error('Not implemented');
  },
  verifySession: () => {
    throw new Error('Not implemented');
  },
});
