import { useEffect, useState } from 'react';

import {
  Autocomplete,
  LinearProgress,
  MenuItem,
  TextField,
} from '@mui/material';

import { FirebaseLocation } from '../../models/location';
import { generateAddressString } from '../utils';
import { IOption } from './helpers';

interface LocationProps {
  locationsAvailable: boolean;
  locations: FirebaseLocation[];
  setLocationId: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  rideMileage: number;
  distanceLoading: boolean;
}

export default function Location({
  locations,
  setLocationId,
  locationsAvailable,
  label,
  rideMileage,
  distanceLoading,
}: LocationProps) {
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    if (rideMileage <= 35) {
      setHelperText('');
    } else {
      setHelperText(
        `The distance of ${rideMileage.toFixed(
          2
        )} miles exceeds the maximum ride distance of 35 miles.`
      );
    }
  }, [rideMileage]);

  return (
    <div>
      <h3>{label}</h3>
      {locationsAvailable ? (
        <Autocomplete
          isOptionEqualToValue={(option: IOption, value) =>
            option.id === value.id
          }
          options={
            locations.length === 0
              ? [{ label: 'Loading...', id: '-1' }]
              : locations.map((loc) => ({
                  label: generateAddressString(loc),
                  id: loc.id,
                }))
          }
          onChange={(_, v) => setLocationId(v?.id ?? '')}
          renderInput={(params) => (
            <>
              <TextField
                error={rideMileage > 35}
                helperText={helperText}
                {...params}
                label={label}
                fullWidth
              />
              {distanceLoading && <LinearProgress />}
            </>
          )}
        />
      ) : (
        <TextField label={label} select fullWidth>
          <MenuItem>No options</MenuItem>
        </TextField>
      )}
    </div>
  );
}
