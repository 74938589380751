import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  if (value == index) {
    return <Box sx={{ p: 3, flex: 1, margin: '0 5% 0 5%' }}>{children}</Box>;
  } else {
    return null;
  }
}
