import { Grid, TextField } from '@mui/material';

import { ContactModel } from '../../../models/driver_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  contactInfo: ContactModel;
  setContactInfo: React.Dispatch<React.SetStateAction<ContactModel>>;
}

export default function Contact(props: InfoProps) {
  const { contactInfo, setContactInfo } = props;

  const updateContactKey = (key: keyof ContactModel, val: string) => {
    setContactInfo((obj: ContactModel) => {
      const objCopy: ContactModel = { ...obj };
      objCopy[key] = val;
      return objCopy;
    });
  };

  return (
    <BoxContainer header="Contact">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12} md={6}>
          <h3>Primary Phone</h3>
          <TextField
            fullWidth
            value={contactInfo.homePhone}
            onChange={(e) => updateContactKey('homePhone', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>Alternate Phone</h3>
          <TextField
            fullWidth
            value={contactInfo.cellPhone}
            onChange={(e) => updateContactKey('cellPhone', e.target.value)}
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
