import { FirebaseLocation } from '../../models/location';

export interface NewLocationProps {
  setNewLocation: React.Dispatch<
    React.SetStateAction<FirebaseLocation | undefined>
  >;
  setTab: React.Dispatch<React.SetStateAction<number>>;
}

export interface IRideType {
  id: string;
  title: string;
  notes: string;
  shadow_cost: string;
  created_at: string;
  updated_at: string;
}

export const firebaseLocation = {
  address: '',
  address2: '',
  city: '',
  created_at: '',
  duplicate_date: '',
  id: '',
  keywords: '',
  location_duplicate_id: '',
  location_status_id: '0',
  location_type_id: '1',
  notes: '',
  phone: '',
  state: '',
  title: '',
  updated_at: '',
  zipcode: '',
};

export const simpleInputs = [
  {
    name: 'title',
    label: 'Name',
  },
  {
    name: 'address',
    label: 'Address',
  },
  {
    name: 'phone',
    label: 'Phone',
  },
  {
    name: 'city',
    label: 'City',
  },
];

export const emptyLocation = {
  title: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  phone: '',
};

export const tripDirections = [
  {
    id: '1',
    title: 'Roundtrip',
  },
  {
    id: '11',
    title: 'One Way From Client',
  },
  {
    id: '21',
    title: 'One Way from Destination',
  },
];

export interface IOption {
  label: string;
  id: string;
}
