import { useEffect, useState } from 'react';

import { Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import Dropdown from '../../../components/Dropdown';
import { RidePreferencesModel } from '../../../models/driver_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  preferencesInfo: RidePreferencesModel;
  setPreferencesInfo: React.Dispatch<
    React.SetStateAction<RidePreferencesModel>
  >;
}

export default function RidePreferences(props: InfoProps) {
  const { preferencesInfo, setPreferencesInfo } = props;
  const [notificationPref, setNotificationPref] = useState(
    preferencesInfo.notificationPreferences
  );

  const possibleNotificationPreferences = [
    'Call',
    'Email and Online',
    'Online Only',
  ];

  type BooleanKeys = keyof Omit<
    RidePreferencesModel,
    'notificationPreferences'
  >;

  const updatePreferencesBooleanKey = (key: BooleanKeys, val: boolean) => {
    setPreferencesInfo((obj: RidePreferencesModel) => {
      const objCopy: RidePreferencesModel = { ...obj };
      objCopy[key] = val;
      return objCopy;
    });
  };

  useEffect(() => {
    if (notificationPref) {
      setPreferencesInfo((obj: RidePreferencesModel) => {
        const objCopy = { ...obj };
        objCopy.notificationPreferences = notificationPref;
        return objCopy;
      });
    }
  }, [notificationPref, setPreferencesInfo]);

  return (
    <BoxContainer header="Ride Preferences">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12} md={4}>
          <h4>Ride Alerts</h4>
          <FormGroup sx={{ marginLeft: '1em' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    preferencesInfo.dailyCall
                      ? preferencesInfo.dailyCall
                      : false
                  }
                  onChange={(e) =>
                    updatePreferencesBooleanKey('dailyCall', e.target.checked)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              sx={{ marginBottom: '1em' }}
              label="Daily All Call/Last Resort Emails"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    preferencesInfo.emergencyList
                      ? preferencesInfo.emergencyList
                      : false
                  }
                  onChange={(e) =>
                    updatePreferencesBooleanKey(
                      'emergencyList',
                      e.target.checked
                    )
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="I would like to be on the Emergency / 'Last Chance' Driver list"
            />
          </FormGroup>
          <h4>Howard County</h4>
          <FormGroup sx={{ marginLeft: '1em' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    preferencesInfo.howardOnly
                      ? preferencesInfo.howardOnly
                      : false
                  }
                  onChange={(e) =>
                    updatePreferencesBooleanKey('howardOnly', e.target.checked)
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Drive Only in Howard County?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <h4>Ride Request Notification Preferences</h4>
          <Dropdown
            options={possibleNotificationPreferences}
            displayOption={notificationPref}
            setDisplayOption={setNotificationPref}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <h4>Ride Confirmation</h4>
          <FormGroup sx={{ marginLeft: '1em' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    preferencesInfo.notifyAcceptedRide
                      ? preferencesInfo.notifyAcceptedRide
                      : false
                  }
                  onChange={(e) =>
                    updatePreferencesBooleanKey(
                      'notifyAcceptedRide',
                      e.target.checked
                    )
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              sx={{ marginBottom: '1em' }}
              label="Send me ride confirmations via email for rides I accept"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    preferencesInfo.notifyBeforeRide
                      ? preferencesInfo.notifyBeforeRide
                      : false
                  }
                  onChange={(e) =>
                    updatePreferencesBooleanKey(
                      'notifyBeforeRide',
                      e.target.checked
                    )
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Send me ride reminders via email (day before ride)"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
