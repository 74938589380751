import { initializeApp } from 'firebase/app';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
/* eslint-disable import/no-unresolved */
import reportWebVitals from './reportWebVitals';

const firebaseOptionsString = process.env.REACT_APP_FIREBASE_OPTIONS || '';
const firebaseOptionsArray = firebaseOptionsString.split(',');

initializeApp({
  apiKey: firebaseOptionsArray[0],
  authDomain: firebaseOptionsArray[1],
  projectId: firebaseOptionsArray[2],
  storageBucket: firebaseOptionsArray[3],
  messagingSenderId: firebaseOptionsArray[4],
  appId: firebaseOptionsArray[5],
  measurementId: firebaseOptionsArray[6],
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
