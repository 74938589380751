import { Colors } from '../../constants';

export const styles = {
  highlight: {
    backgroundColor: Colors.lightGreen,
  },
  tableCell: {
    borderRight: `1px solid ${Colors.greyTextBackground}`,
  },
};
