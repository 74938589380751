import { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Tab,
  Tabs,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { PageWrapper, PageTitle } from '../../../components/PageComponents';
import {
  getClientProfile,
  getUserProfile,
  updateClientProfile,
} from '../../../dbconnector/users';
import {
  GeneralInfoModel,
  ContactModel,
  LocationModel,
  PersonalInfoModel,
  NotesModel,
  EmergencyModel,
  NeighborRideClient,
} from '../../../models/client_profile';
import { NeighborRideUser } from '../../../models/user';
import { UserContext } from '../../../models/user_context';
import { getNeighborRideDate } from '../../utils';
import Address from '../components/Address';
import GeneralInfo from '../components/GeneralInfo';
import { TabPanel } from '../components/TabPanel';
import Contact from './Contact';
import Emergency from './Emergency';
import Emergency2 from './Emergency2';
import Notes from './Notes';
import PersonalInfo from './PersonalInfo';

export default function ClientUpdateProfile() {
  const { userId, userToken, isMobile } = useContext(UserContext);
  const navigate = useNavigate();

  const [client, setClient] = useState<NeighborRideClient>();
  const [user, setUser] = useState<NeighborRideUser>();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const [tabIndex, setTabIndex] = useState(0);
  const [generalInfo, setGeneralInfo] = useState<GeneralInfoModel>({
    firstName: '',
    lastName: '',
    role: 'client',
    username: '',
    gender: '',
  });
  const [contactInfo, setContactInfo] = useState<ContactModel>({
    cellPhone: '',
    homePhone: '',
    workPhone: '',
  });
  const [addressInfo, setAddressInfo] = useState<LocationModel>({
    developmentName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [notesInfo, setNotesInfo] = useState<NotesModel>({
    highStepRestriction: '',
  });
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoModel>({
    primaryLanguage: '',
    healthIssues: '',
    paymentMethod: '',
  });
  const [emergencyInfo, setEmergencyInfo] = useState<EmergencyModel>({
    name: '',
    phone: '',
    alternatePhone: '',
    relationship: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [emergencyInfo2, setEmergencyInfo2] = useState<EmergencyModel>({
    name: '',
    phone: '',
    alternatePhone: '',
    relationship: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
  });

  const [btnSaving, setBtnSaving] = useState(false);

  useEffect(() => {
    async function downloadClientData() {
      const token = userToken;
      const inputClient = await getClientProfile(userId, token);
      const inputUser = await getUserProfile(userId, token);
      const birthdate =
        inputClient.birth_date !== ''
          ? moment(inputClient.birth_date, 'YYYY-MM-DD').toDate()
          : undefined;
      const newGeneralInfo: GeneralInfoModel = {
        firstName: inputUser.first_name,
        lastName: inputUser.last_name,
        username: inputUser.email,
        role: 'client',
        gender: inputClient.gender,
        birthDate: birthdate,
      };
      const newContactInfo: ContactModel = {
        cellPhone: inputClient.cell_phone,
        homePhone: inputClient.home_phone,
        workPhone: inputClient.work_phone,
      };
      const newAddressInfo: LocationModel = {
        developmentName: inputClient.development_name,
        address: inputClient.address1,
        address2: inputClient.address2,
        city: inputClient.city,
        state: inputClient.state,
        zipCode: inputClient.zip,
      };
      const newNotesInfo: NotesModel = {
        highStepRestriction: inputClient.vehicle_high_step_restriction,
      };
      const newPersonalInfo: PersonalInfoModel = {
        primaryLanguage: inputClient.primary_language,
        healthIssues: inputClient.health_issues,
        paymentMethod: inputClient.payment_method,
      };
      const newEmergencyInfo: EmergencyModel = {
        name: inputClient.emergency_contact_name,
        phone: inputClient.emergency_contact_phone,
        alternatePhone: inputClient.emergency_contact_alternate_phone,
        relationship: inputClient.emergency_contact_relationship,
        address: inputClient.emergency_contact_address,
        city: inputClient.emergency_contact_city,
        state: inputClient.emergency_contact_state,
        zipCode: inputClient.emergency_contact_zip,
      };
      const newEmergencyInfo2: EmergencyModel = {
        name: inputClient.emergency_contact_2_name,
        phone: inputClient.emergency_contact_2_phone,
        alternatePhone: inputClient.emergency_contact_2_alternate_phone,
        relationship: inputClient.emergency_contact_2_relationship,
        address: inputClient.emergency_contact_2_address,
        city: inputClient.emergency_contact_2_city,
        state: inputClient.emergency_contact_2_state,
        zipCode: inputClient.emergency_contact_2_zip,
      };

      setClient(inputClient);
      setUser(inputUser);

      setGeneralInfo(newGeneralInfo);
      setContactInfo(newContactInfo);
      setAddressInfo(newAddressInfo);
      setNotesInfo(newNotesInfo);
      setPersonalInfo(newPersonalInfo);
      setEmergencyInfo(newEmergencyInfo);
      setEmergencyInfo2(newEmergencyInfo2);
      return;
    }
    downloadClientData();
  }, [userId, userToken]);

  const tabComponents = [
    {
      header: 'General Info',
      component: (
        <GeneralInfo
          generalInfo={generalInfo}
          setGeneralInfo={setGeneralInfo}
        />
      ),
    },
    {
      header: 'Contact',
      component: (
        <Contact contactInfo={contactInfo} setContactInfo={setContactInfo} />
      ),
    },
    {
      header: 'Address',
      component: (
        <Address addressInfo={addressInfo} setAddressInfo={setAddressInfo} />
      ),
    },
    {
      header: 'Personal Info',
      component: (
        <PersonalInfo
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
        />
      ),
    },
    {
      header: 'Notes',
      component: <Notes notesInfo={notesInfo} setNotesInfo={setNotesInfo} />,
    },
    {
      header: 'Emergency',
      component: (
        <Emergency
          emergencyInfo={emergencyInfo}
          setEmergencyInfo={setEmergencyInfo}
        />
      ),
    },
    {
      header: 'Emergency2',
      component: (
        <Emergency2
          emergencyInfo={emergencyInfo2}
          setEmergencyInfo={setEmergencyInfo2}
        />
      ),
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setTabIndex(newValue);
  };

  const moveTabForward = (currIndex: number) => {
    if (currIndex < tabComponents.length - 1) {
      setTabIndex(currIndex + 1);
    }
  };

  const moveTabBackward = (currIndex: number) => {
    if (currIndex > 0) {
      setTabIndex(currIndex - 1);
    }
  };

  async function handleUpdateClientProfile() {
    setBtnSaving(true);
    const newClient: NeighborRideClient = JSON.parse(JSON.stringify(client));
    const newUser: NeighborRideUser = JSON.parse(JSON.stringify(user));
    newUser.first_name = generalInfo.firstName;
    newUser.last_name = generalInfo.lastName;
    newClient.gender = generalInfo.gender;
    console.log('here:');
    console.log(generalInfo.birthDate?.toString());
    newClient.birth_date = generalInfo.birthDate
      ? getNeighborRideDate(generalInfo.birthDate)
      : '';
    console.log(newClient.birth_date);
    newClient.cell_phone = contactInfo.cellPhone;
    newClient.home_phone = contactInfo.homePhone;
    newClient.work_phone = contactInfo.workPhone;

    newClient.development_name = addressInfo.developmentName;
    newClient.address1 = addressInfo.address;
    newClient.address2 = addressInfo.address2;
    newClient.city = addressInfo.city;
    newClient.state = addressInfo.state;
    newClient.zip = addressInfo.zipCode;

    newClient.vehicle_high_step_restriction = notesInfo.highStepRestriction;

    newClient.primary_language = personalInfo.primaryLanguage;
    newClient.health_issues = personalInfo.healthIssues;

    newClient.emergency_contact_name = emergencyInfo.name;
    newClient.emergency_contact_phone = emergencyInfo.phone;
    newClient.emergency_contact_alternate_phone = emergencyInfo.alternatePhone;
    newClient.emergency_contact_relationship = emergencyInfo.relationship;
    newClient.emergency_contact_address = emergencyInfo.address;
    newClient.emergency_contact_city = emergencyInfo.city;
    newClient.emergency_contact_state = emergencyInfo.state;
    newClient.emergency_contact_zip = emergencyInfo.zipCode;

    newClient.emergency_contact_2_name = emergencyInfo2.name;
    newClient.emergency_contact_2_phone = emergencyInfo2.phone;
    newClient.emergency_contact_2_alternate_phone =
      emergencyInfo2.alternatePhone;
    newClient.emergency_contact_2_relationship = emergencyInfo2.relationship;
    newClient.emergency_contact_2_address = emergencyInfo2.address;
    newClient.emergency_contact_2_city = emergencyInfo2.city;
    newClient.emergency_contact_2_state = emergencyInfo2.state;
    newClient.emergency_contact_2_zip = emergencyInfo2.zipCode;

    try {
      const token = userToken;
      const successfulUpdate = await updateClientProfile(
        userId,
        token,
        newClient,
        newUser
      );
      if (successfulUpdate) {
        navigate('/profile');
      } else {
        setSnackbarOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
    setBtnSaving(false);
  }

  return (
    <PageWrapper>
      <PageTitle title="Update Profile" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabIndex}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {tabComponents.map((sectionObj, index) => (
            <Tab key={`tab-${index}`} label={sectionObj.header} />
          ))}
        </Tabs>
        {tabComponents.map((sectionObj, index) => (
          <TabPanel key={`tab-content-${index}`} value={tabIndex} index={index}>
            {sectionObj.component}
            <Box sx={{ display: 'flex', marginTop: '20px' }}>
              <Button
                variant="contained"
                onClick={handleUpdateClientProfile}
                endIcon={
                  btnSaving ? (
                    <CircularProgress sx={{ color: 'white' }} size="0.9em" />
                  ) : null
                }
              >
                Save
              </Button>
              <Box sx={{ flex: 1 }}></Box>
              {tabIndex > 0 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    moveTabBackward(tabIndex);
                  }}
                  sx={{ marginRight: '10px' }}
                >
                  Prev
                </Button>
              )}
              {tabIndex < tabComponents.length - 1 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    moveTabForward(tabIndex);
                  }}
                >
                  Next
                </Button>
              )}
            </Box>
          </TabPanel>
        ))}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
          onClose={() => setSnackbarOpen(false)}
        >
          Could not update profile, please contact a Ride Coordinator.
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
}
