/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useContext } from 'react';

import { Grid, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import RidePaper from '../../../components/RidePaper';
import { UserContext } from '../../../models/user_context';
import { BASE_API_URL } from '../../../models/utils';
import { sortRides } from '../../utils';
import { membersColumns, ridesColumns } from '../utils';

export function LiaisonDashboardPage() {
  const { userId, userToken, verifySession, isMobile } =
    useContext(UserContext);

  const navigate = useNavigate();

  const [group, setGroup] = useState('');
  const [members, setMembers] = useState([]);
  const [rides, setRides] = useState([]);
  const [membersLoading, setMembersLoading] = useState(true);
  const [ridesLoading, setRidesLoading] = useState(true);

  const fetchAux = (aux: string) =>
    fetch(`${BASE_API_URL}/liaison/${aux}/${userId}`, {
      headers: { Authorization: userToken },
    }).then(async (res) => {
      if (res.status === 200) {
        const json = await res.json();

        switch (aux) {
          case 'group':
            setGroup(json[aux]);
            break;
          case 'members':
            setMembers(json[aux]);
            setMembersLoading(false);
            break;
          case 'rides':
            setRides(json[aux]);
            setRidesLoading(false);
            break;
        }
      } else {
        verifySession(res.status);
      }
    });

  useEffect(() => {
    fetchAux('group');
    fetchAux('members');
    fetchAux('rides');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <Box sx={{ margin: '4%' }}>
      <h1
        style={{ marginTop: 0, textAlign: 'left' }}
      >{`Liaison Dashboard - ${group}`}</h1>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={2.8}>
          <h2>Members</h2>
          <DataGrid
            disableSelectionOnClick
            autoHeight
            pageSize={7}
            loading={membersLoading}
            rows={members}
            columns={membersColumns}
            sx={{
              '.MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <h2>Rides</h2>
          {isMobile ? (
            <>
              {sortRides(rides).map((ride: any, index) => (
                <RidePaper ride={ride} key={index} />
              ))}
            </>
          ) : (
            <DataGrid
              disableSelectionOnClick
              autoHeight
              pageSize={7}
              loading={ridesLoading}
              rows={sortRides(rides)}
              columns={ridesColumns}
              onRowClick={(params) => navigate(`/trips/${params.id}`)}
              sx={{
                '.MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
