import { useEffect, useState } from 'react';

import { Grid, Box, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import Dropdown from '../../../components/Dropdown';
import { GeneralInfoModel } from '../../../models/driver_profile';
import BoxContainer from './BoxContainer';

interface GeneralInfoProps {
  generalInfo: GeneralInfoModel;
  setGeneralInfo: React.Dispatch<React.SetStateAction<GeneralInfoModel>>;
}

export default function GeneralInfo(props: GeneralInfoProps) {
  const { generalInfo, setGeneralInfo } = props;

  const possibleGenders: string[] = ['Male', 'Female'];
  const [birthDate, setBirthDate] = useState<Date | null>();
  console.log(generalInfo.gender);
  const getFirstLetterCapitalString = (str: string): string => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
    return '';
  };
  const [gender, setGender] = useState<string>(
    getFirstLetterCapitalString(generalInfo.gender)
  );

  useEffect(() => {
    if (birthDate) {
      setGeneralInfo((obj: GeneralInfoModel) => {
        const objCopy = { ...obj };
        objCopy.birthDate = birthDate;
        return objCopy;
      });
    }
    if (gender) {
      setGeneralInfo((obj: GeneralInfoModel) => {
        const objCopy = { ...obj };
        objCopy.gender = getFirstLetterCapitalString(gender);
        return objCopy;
      });
    }
  }, [gender, birthDate, setGeneralInfo]);
  const name = `${generalInfo.firstName} ${generalInfo.lastName}`;

  useEffect(() => {
    setGender(
      generalInfo?.gender ? getFirstLetterCapitalString(generalInfo.gender) : ''
    );
  }, [generalInfo]);

  return (
    <BoxContainer header="General Info">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12} md={4}>
          <b>Name: </b> {name}
          <br />
          <br />
          <b>Role: </b> {generalInfo.role}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <b>Birth Date</b> *
            <Box>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={generalInfo.birthDate}
                onChange={setBirthDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <b>Gender</b> *
            <Box>
              <Dropdown
                options={possibleGenders}
                displayOption={
                  gender ?? getFirstLetterCapitalString(generalInfo.gender)
                }
                setDisplayOption={setGender}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
