/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';

import { TextField } from '@mui/material';
import moment from 'moment';

export const messages = {
  FUNDS_MESSAGE: 'Add funds to your existing Transportation Account.',
  FEE_MESSAGE: 'Pay your one time Account Registration Fee.',
  FUNDS_MIN_AMOUNT:
    '$25 minimum. Custom amount greater than $25 may be typed directly in field below.',
  FEE_MIN_AMOUNT:
    '$45.00 total includes $20.00 registration and $25.00 in funds added to your transportation account.',
  CLIENT_NOT_FOUND:
    'Client not found, please call NR office with any questions.',
  PAYMENT_SUCCESS: 'Payment completed successfully.',
  CONVENIENCE:
    'As a convenience for our passengers and their families, Neighbor Ride offers credit card payments for transportation. Credit card processing fees are included in the total price.',
};

export const options = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export const amountHelper = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setAmount: (amount: number) => void
) => {
  if (event.target.value === '') {
    setAmount(0);
  }
  const newAmount = Number(event.target.value);
  if (!Number.isNaN(newAmount)) {
    setAmount(newAmount);
  }
};

export const birthdayHelper = (birthdate: string) =>
  moment(birthdate, 'YYYY-MM-DD').format('MM/DD');

interface IBirthdayInput {
  birthday: string;
  setBirthday: (birthday: string) => void;
  disabled: boolean;
  blankFields: boolean;
  invalidDate: boolean;
  setInvalidDate: (invalid: boolean) => void;
}

export function BirthdayInput({
  birthday,
  setBirthday,
  disabled,
  blankFields,
  invalidDate,
  setInvalidDate,
}: IBirthdayInput) {
  const previousBirthday = useRef('');

  useEffect(() => {
    if (previousBirthday.current.length === 1 && birthday.length === 2) {
      setBirthday(`${birthday}/`);
    }
    if (previousBirthday.current.length === 3 && birthday.length === 2) {
      setBirthday(birthday.slice(0, birthday.length - 1));
    }
  }, [birthday]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    previousBirthday.current = birthday;
    const newBirthday = event.target.value;

    setBirthday(newBirthday);
    if (!moment(newBirthday, 'MM/DD').isValid() || newBirthday.length < 5) {
      setInvalidDate(true);
    } else {
      setInvalidDate(false);
    }
  };

  const allowOnlyNumbers = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      !event.key.match(/[0-9]/) &&
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault();
    }
  };

  return (
    <TextField
      error={invalidDate || (blankFields && !birthday)}
      helperText={invalidDate ? 'Invalid date.' : ''}
      label="Birthday"
      variant="standard"
      placeholder="MM/DD"
      value={birthday}
      onChange={handleChange}
      onKeyDown={allowOnlyNumbers}
      disabled={disabled}
      sx={{ mb: '3%' }}
      inputProps={{ maxLength: 5 }}
    />
  );
}
