import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

interface DropdownProps {
  options: string[];
  title?: string;
  fullWidth?: boolean;
  displayOption: string;
  setDisplayOption: React.Dispatch<React.SetStateAction<string>>;
}

export default function Dropdown(props: DropdownProps) {
  const { options, title, displayOption, setDisplayOption, ...other } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayOption(event.target.value);
  };

  return (
    <TextField
      id="outlined-select"
      select
      label={title}
      value={displayOption}
      onChange={handleChange}
      sx={{ minWidth: '200px' }}
      {...other}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}
