import { useContext, useEffect, useState } from 'react';

import {
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Paper,
  SxProps,
  Theme,
} from '@mui/material';

import { getRidesInfo } from '../../dbconnector/users';
import { UserContext } from '../../models/user_context';
import { styles } from './MonthRides.styles';

export default function MonthRides(props: {
  sx?: SxProps<Theme> | undefined;
  userId: string;
}) {
  const [currMonthName, setCurrMonthName] = useState<string>('');
  const [nextMonthName, setNextMonthName] = useState<string>('');
  const [next2MonthName, setNext2MonthName] = useState<string>('');

  const [currMonthNum, setCurrMonthNum] = useState(0);
  const [nextMonthNum, setNextMonthNum] = useState(0);
  const [next2MonthNum, setNext2MonthNum] = useState(0);

  const { userToken } = useContext(UserContext);

  const { userId, sx } = props;

  useEffect(() => {
    async function downloadRidesInfo() {
      const token = userToken;
      const ridesInfo = await getRidesInfo(userId, token);
      setCurrMonthName(ridesInfo.currMonthName);
      setNextMonthName(ridesInfo.nextMonthName);
      setNext2MonthName(ridesInfo.next2MonthName);
      setCurrMonthNum(ridesInfo.currMonthNum);
      setNextMonthNum(ridesInfo.nextMonthNum);
      setNext2MonthNum(ridesInfo.next2MonthNum);
    }

    downloadRidesInfo();
  }, [userId, userToken]);

  return (
    <TableContainer component={Paper} sx={sx}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={2}
              style={{ backgroundColor: '#DDDDDD' }}
            >
              Month Rides
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={styles.tableCell}>Max Rides per Month</TableCell>
            <TableCell>12</TableCell>
          </TableRow>
          <TableRow sx={styles.highlight}>
            <TableCell sx={styles.tableCell}>
              Rides for {currMonthName}
            </TableCell>
            <TableCell>{currMonthNum}</TableCell>
          </TableRow>
          <TableRow sx={styles.highlight}>
            <TableCell sx={styles.tableCell}>
              Rides for {nextMonthName}
            </TableCell>
            <TableCell>{nextMonthNum}</TableCell>
          </TableRow>
          <TableRow sx={styles.highlight}>
            <TableCell sx={styles.tableCell}>
              Rides for {next2MonthName}
            </TableCell>
            <TableCell>{next2MonthNum}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
