import { useContext } from 'react';

import { AuthState } from '../../models/auth_state';
import { UserContext } from '../../models/user_context';
import ClientNavBar from './ClientNavBar';
import DriverNavBar from './DriverNavBar';

function NavigationBar() {
  const { authState } = useContext(UserContext);

  if (authState === AuthState.Client) {
    return <ClientNavBar />;
  }

  if (authState === AuthState.Driver) {
    return <DriverNavBar />;
  }

  return null;
}

export default NavigationBar;
