export const Colors = {
  mainColor: '#0b74ff',
  redTextBackground: '#ffd2cc',
  redText: '#b00',
  greyTextBackground: '#DDDDDD',
  lightGreyTextBackground: '#F3F3F3', // good for tables
  lightGreen: '#b3ffb3',
  lightYellow: '#ffffe0',
  darkYellow: '#8B8000',
  lightBlue: '#ADD8E6',
  darkBlue: '#00008B',
};

export const API_ROOT =
  'https://us-central1-neighbor-ride.cloudfunctions.net/app';
