import { NeighborRideClient } from '../models/client_profile';
import { NeighborRideDriver } from '../models/driver_profile';
import {
  FirebaseDriverAvailability,
  FirebaseDriverUnavailability,
  WeekAvailability,
} from '../models/driver_schedule';
import { NeighborRideUser } from '../models/user';
import {
  BASE_API_URL,
  DriverRideSummary,
  PaymentInfo,
  RidesInfo,
} from '../models/utils';

export const getUserProfile = async (
  uid: string,
  token: string
): Promise<NeighborRideUser> => {
  const res = await fetch(`${BASE_API_URL}/users`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const user: NeighborRideUser = data.user;
  return user;
};

export const getPaymentInfo = async (
  uid: string,
  token: string
): Promise<PaymentInfo> => {
  const res = await fetch(`${BASE_API_URL}/payments`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const paymentInfo = data.paymentInfo;
  return paymentInfo;
};

export const getRidesInfo = async (
  uid: string,
  token: string
): Promise<RidesInfo> => {
  const res = await fetch(`${BASE_API_URL}/ridesInfo`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const ridesInfo = data.ridesInfo;
  return ridesInfo;
};

export const getDriverRideSummary = async (
  uid: string,
  token: string
): Promise<DriverRideSummary> => {
  const res = await fetch(`${BASE_API_URL}/driverRideSummary`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const driverRideSummary = data.driverRideSummary;
  return driverRideSummary;
};

export const getClientProfile = async (
  uid: string,
  token: string
): Promise<NeighborRideClient> => {
  const res = await fetch(`${BASE_API_URL}/clients`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const client: NeighborRideClient = data.client;
  return client;
};

export const updateClientProfile = async (
  uid: string,
  token: string,
  client: NeighborRideClient,
  user: NeighborRideUser
): Promise<boolean> => {
  const clientRes = await fetch(`${BASE_API_URL}/clients`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      newClientData: client,
    }),
  });

  const userRes = await fetch(`${BASE_API_URL}/users`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      newUserData: user,
    }),
  });

  if (clientRes.status !== 200 || userRes.status !== 200) {
    return false;
  }
  return true;
};

export const getDriverProfile = async (
  uid: string,
  token: string
): Promise<NeighborRideDriver> => {
  const res = await fetch(`${BASE_API_URL}/drivers`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const driver: NeighborRideDriver = data.driver;
  return driver;
};

export const updateDriverProfile = async (
  uid: string,
  token: string,
  driver: NeighborRideDriver,
  user: NeighborRideUser
): Promise<boolean> => {
  const driverRes = await fetch(`${BASE_API_URL}/drivers`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      newDriverData: driver,
    }),
  });

  const userRes = await fetch(`${BASE_API_URL}/users`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      newUserData: user,
    }),
  });

  if (driverRes.status !== 200 || userRes.status !== 200) {
    return false;
  }
  return true;
};

export const getDriverAvailabilities = async (
  uid: string,
  token: string
): Promise<FirebaseDriverAvailability[]> => {
  const res = await fetch(`${BASE_API_URL}/availabilities`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const availabilities: FirebaseDriverAvailability[] = data.availabilities;
  return availabilities;
};

export const getDriverUnavailabilities = async (
  uid: string,
  token: string
): Promise<FirebaseDriverUnavailability[]> => {
  const res = await fetch(`${BASE_API_URL}/unavailabilities`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  console.log({ data });
  const unavailabilities: FirebaseDriverUnavailability[] =
    data.unavailabilities;
  console.log({ unavailabilities });
  return unavailabilities;
};

export const submitAvailabilities = async (
  uid: string,
  token: string,
  availabilities: WeekAvailability
): Promise<boolean> => {
  const res = await fetch(`${BASE_API_URL}/availabilities`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      availabilities: Object.values(availabilities),
    }),
  });
  return res.status === 200;
};

export const submitUnavailability = async (
  uid: string,
  token: string,
  unavailability: FirebaseDriverUnavailability
): Promise<boolean> => {
  const res = await fetch(`${BASE_API_URL}/unavailabilities`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      unavailability: unavailability,
    }),
  });
  return res.status === 200;
};

export const deleteUnavailability = async (
  uid: string,
  token: string,
  unavailabilityId: string
): Promise<boolean> => {
  console.log({ unavailabilityId });
  const res = await fetch(`${BASE_API_URL}/unavailabilities`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      unavailabilityId: unavailabilityId,
    }),
  });
  const bruh = await res.text();
  console.log({ bruh });
  console.log(res.status);
  return res.status === 200;
};
