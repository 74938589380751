import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { acceptRide } from '../../../dbconnector/rides';

type RideInfoPaneProps = {
  isPending: boolean;
  rideId: string;
  driverId: string;
  userToken: string;
  isDriver: boolean;
  isMobile: boolean;
  handlePrint: () => void;
  loadRide: () => void;
};

export default function RideSidebarActions(props: RideInfoPaneProps) {
  const navigate = useNavigate();
  const {
    isPending,
    rideId,
    driverId,
    userToken,
    isDriver,
    isMobile,
    handlePrint,
    loadRide,
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Button
        variant="contained"
        sx={{ width: isMobile ? '100%' : '300px' }}
        onClick={() => {
          navigate('/dashboard');
        }}
      >
        Back to Dashboard
      </Button>
      <Button
        variant="contained"
        sx={{ width: isMobile ? '100%' : '300px', marginY: '10px' }}
        onClick={handlePrint}
      >
        PRINT RIDE
      </Button>
      {isPending && isDriver ? (
        <Button
          variant="contained"
          sx={{ width: isMobile ? '100%' : '300px' }}
          onClick={async () => {
            const success = await acceptRide(driverId, rideId, userToken);
            if (success) {
              navigate('/dashboard');
            } else {
              loadRide();
            }
          }}
        >
          ACCEPT RIDE
        </Button>
      ) : null}
    </Box>
  );
}
