import { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import BackToDashboardButton from '../../components/BackToDashboardButton';
import { PageWrapper, PageTitle } from '../../components/PageComponents';
import {
  deleteUnavailability,
  getDriverAvailabilities,
  getDriverUnavailabilities,
  submitAvailabilities,
  submitUnavailability,
} from '../../dbconnector/users';
import {
  FirebaseDriverAvailability,
  FirebaseDriverUnavailability,
  WeekAvailability,
} from '../../models/driver_schedule';
import { UserContext } from '../../models/user_context';
import {
  getRandomAlphanumbericString,
  getRandomWeekAvailability,
} from '../../models/utils';
import { getDatestringFromDate } from '../DashboardPage/utils';

const weekDays: Week = {
  '0': 'Monday',
  '1': 'Tuesday',
  '2': 'Wednesday',
  '3': 'Thursday',
  '4': 'Friday',
  '5': 'Saturday',
  '6': 'Sunday',
};

export interface Week {
  [key: string]: string;
}

export function UpdateSchedulePage() {
  const navigate = useNavigate();
  const { userId, userToken } = useContext(UserContext);

  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [note, setNote] = useState<string>('');

  const changeFromDate = (newValue: Date | null) => {
    setFromDate(newValue);
  };
  const changeToDate = (newValue: Date | null) => {
    setToDate(newValue);
  };
  const changeNote = (newValue: string) => {
    setNote(newValue);
  };

  const [days, setDays] = useState<WeekAvailability>(
    getRandomWeekAvailability(userId)
  );

  const [timeoffs, setTimeoffs] = useState<FirebaseDriverUnavailability[]>([]);

  useEffect(() => {
    async function downloadDriverAvailabilityAndUnavailability() {
      const token = userToken;
      const availabilities: FirebaseDriverAvailability[] =
        await getDriverAvailabilities(userId, token);
      const unavailabilities: FirebaseDriverUnavailability[] =
        (await getDriverUnavailabilities(userId, token)) ?? [];

      const newDays = getRandomWeekAvailability(userId);
      availabilities.forEach((availability: FirebaseDriverAvailability) => {
        const dowId = availability.dow_id;
        newDays[dowId] = availability;
      });

      setDays(newDays);
      setTimeoffs(unavailabilities);
    }
    downloadDriverAvailabilityAndUnavailability();
  }, [userId, userToken]);

  const onSubmitData = async () => {
    const token = userToken;
    const availabilitiesSuccess = await submitAvailabilities(
      userId,
      token,
      days
    );
    if (availabilitiesSuccess) {
      navigate('/dashboard');
    }
  };

  if (!days || !timeoffs) {
    return null;
  }

  const onChangeIsAvailable = (dowId: string, newIsAvailable: boolean) => {
    const newDays: WeekAvailability = JSON.parse(JSON.stringify(days));

    newDays[dowId].available = newIsAvailable ? '1' : '0';
    setDays(newDays);
  };

  const onChangeStartOrEndTime = (
    dowId: string,
    newTime: Date | null,
    isStartTime: boolean
  ) => {
    const newDays: WeekAvailability = JSON.parse(JSON.stringify(days));
    if (newTime) {
      const newDate = new Date(newTime);
      const newTimeStr = moment(newDate)
        .tz('America/New_York')
        .format('HH:mm:ss');

      if (isStartTime) {
        newDays[dowId].start = newTimeStr;
        console.log({ newTimeStr });
      } else {
        newDays[dowId].end = newTimeStr;
      }
    }

    setDays(newDays);
  };

  const compareAvailabilities = (
    one: FirebaseDriverAvailability,
    two: FirebaseDriverAvailability
  ): number => {
    return parseInt(one.dow_id) - parseInt(two.dow_id);
  };

  return (
    <PageWrapper>
      <PageTitle title="Update Schedule" />
      <Box sx={{ display: 'flex', marginBottom: '30px' }}>
        <BackToDashboardButton />
      </Box>
      <h2>Driver Schedule</h2>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Day of Week</TableCell>
              <TableCell align="center">Availability</TableCell>
              <TableCell align="center">Start Time</TableCell>
              <TableCell align="center">End Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(days)
              .sort(compareAvailabilities)
              .map((availability: FirebaseDriverAvailability) => (
                <TableRow key={availability.dow_id}>
                  <TableCell align="center">
                    {weekDays[availability.dow_id]}
                  </TableCell>
                  <TableCell align="center">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={availability.available === '1'}
                        onChange={() =>
                          onChangeIsAvailable(
                            availability.dow_id,
                            !(availability.available === '1')
                          )
                        }
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    <TimePicker
                      disabled={availability.available !== '1'}
                      value={moment(availability.start, 'HH:mm:ss')
                        .tz('America/New_York')
                        .toDate()}
                      onChange={(e) =>
                        onChangeStartOrEndTime(availability.dow_id, e, true)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TimePicker
                      disabled={availability.available !== '1'}
                      value={moment(availability.end, 'HH:mm:ss')
                        .tz('America/New_York')
                        .toDate()}
                      onChange={(e) =>
                        onChangeStartOrEndTime(availability.dow_id, e, false)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        sx={{
          minWidth: '150px',
          marginTop: '20px',
          marginBottom: '20px',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
        onClick={onSubmitData}
      >
        Submit
      </Button>
      <h2>Driver Time Off</h2>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>
              <TableCell align="center">Note</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeoffs.map(
              (timeoff: FirebaseDriverUnavailability, index: number) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    {dayjs(timeoff.day).format('YYYY-MM-DD')}
                  </TableCell>
                  <TableCell align="center">
                    {dayjs(timeoff.end_day).format('YYYY-MM-DD')}
                  </TableCell>
                  <TableCell align="center">{timeoff.body}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const success = await deleteUnavailability(
                          userId,
                          userToken,
                          timeoff.id
                        );
                        if (success) {
                          setTimeoffs(timeoffs.filter((_, i) => i !== index));
                        }
                      }}
                      sx={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
            <TableRow key="add">
              <TableCell align="center">
                <DesktopDatePicker
                  label="From Date"
                  inputFormat="YYYY-MM-DD"
                  value={fromDate}
                  onChange={changeFromDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </TableCell>
              <TableCell align="center">
                <DesktopDatePicker
                  label="To Date"
                  inputFormat="YYYY-MM-DD"
                  value={toDate}
                  onChange={changeToDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  value={note}
                  onChange={(e) => changeNote(e.target.value)}
                />
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  onClick={async () => {
                    const date = new Date();
                    const datestringWithTime = date.toISOString();
                    const datestring = getDatestringFromDate(date);
                    const token = userToken;
                    const newUnavailability: FirebaseDriverUnavailability = {
                      body: note,
                      created_at: datestringWithTime,
                      day: fromDate
                        ? getDatestringFromDate(fromDate)
                        : datestring,
                      end: '',
                      end_day: toDate
                        ? getDatestringFromDate(toDate)
                        : datestring,
                      id: getRandomAlphanumbericString(),
                      start: '',
                      status_id: '0',
                      updated_at: datestringWithTime,
                      user_id: userId,
                    };
                    setTimeoffs([...timeoffs, newUnavailability]);
                    const unavailabilitySuccess = await submitUnavailability(
                      userId,
                      token,
                      newUnavailability
                    );

                    if (unavailabilitySuccess) {
                      setFromDate(null);
                      setToDate(null);
                      setNote('');
                    }
                  }}
                  sx={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  Add Row
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PageWrapper>
  );
}
