import { useContext } from 'react';

import { AuthState } from '../models/auth_state';
import { UserContext } from '../models/user_context';
import LoginRequiredPage from '../pages/LoginRequiredPage/LoginRequiredPage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';

export function RequireAnyAuth({ children }: { children: JSX.Element }) {
  const { authState } = useContext(UserContext);

  if (authState === AuthState.NotAuthenticated) {
    return <LoginRequiredPage />;
  }

  return children;
}

export function RequireClientAuth({ children }: { children: JSX.Element }) {
  const { authState } = useContext(UserContext);

  if (authState === AuthState.NotAuthenticated) {
    return <LoginRequiredPage />;
  }

  if (authState !== AuthState.Client && authState !== AuthState.Liaison) {
    return <NotFoundPage />;
  }

  return children;
}

export function RequireDriverAuth({ children }: { children: JSX.Element }) {
  const { authState } = useContext(UserContext);

  if (authState === AuthState.NotAuthenticated) {
    return <LoginRequiredPage />;
  }

  if (authState !== AuthState.Driver) {
    return <NotFoundPage />;
  }

  return children;
}
