/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useContext, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import { PageWrapper } from '../../components/PageComponents';
import RidePaper from '../../components/RidePaper';
import { acceptRide, getPendingRides } from '../../dbconnector/rides';
import { FirebaseRide, TableRide } from '../../models/rides';
import { UserContext } from '../../models/user_context';
import {
  convertFirebaseRidesToTableRides,
  driverDashboardColumns,
  getDatestringFromDate,
  getRideTypeDisplayString,
} from '../DashboardPage/utils';
import { sortRides } from '../utils';
import {
  directions,
  hoCoZipCodes,
  initialRideFinder,
  purposes,
} from './helpers';

export default function DriverRidesPage() {
  const { userId, userToken, isMobile } = useContext(UserContext);

  const [allTableRides, setAllTableRides] = useState<TableRide[]>([]);
  const [filteredTableRides, setFilteredTableRides] = useState<TableRide[]>([]);
  const [currentFilter, setCurrentFilter] = useState<string>('pending');
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState('');

  const [rideFinder, setRideFinder] = useState(initialRideFinder);

  const handleChange = (
    event:
      | SelectChangeEvent
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRideFinder({
      ...rideFinder,
      [event.target.name]: event.target.value,
    });
  };

  const findRides = () => {
    const aux = filteredTableRides.filter(
      (ride) =>
        (!rideFinder.zip || ride.zipCodes.includes(rideFinder.zip)) &&
        (!rideFinder.purpose || rideFinder.purpose === ride.purpose) &&
        (!rideFinder.direction ||
          rideFinder.direction === ride.tripDirection) &&
        (!rideFinder.hsv || rideFinder.hsv === ride.hsv) &&
        (rideFinder.hoCoOnly === 'No' ||
          ride.zipCodes.filter((z) => z).every((z) => hoCoZipCodes.includes(z)))
    );
    return sortRides(aux);
  };

  async function getAllRides() {
    const token = userToken;
    const allPendingRides: FirebaseRide[] = await getPendingRides(
      userId,
      token
    );

    const newAllTableRides = convertFirebaseRidesToTableRides(allPendingRides);
    setAllTableRides(newAllTableRides);
    setIsLoading(false);
    setFilteredTableRides(newAllTableRides);
  }

  useEffect(() => {
    getAllRides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newFilteredTableRides = allTableRides.filter((tableRide) => {
      const todayDate = new Date();
      const tomorrowDate = new Date(todayDate.getTime() + 24 * 60 * 60 * 1000);
      const lastResortDate = new Date(
        todayDate.getTime() + 72 * 60 * 60 * 1000
      );
      const todayDatestring = getDatestringFromDate(todayDate);
      const tomorrowDatestring = getDatestringFromDate(tomorrowDate);
      const lastResortDatestring = getDatestringFromDate(lastResortDate);
      if (currentFilter === 'lastResort') {
        return (
          tableRide.date >= todayDatestring &&
          tableRide.date <= tomorrowDatestring
        );
      } else if (currentFilter === 'allCall') {
        return (
          tableRide.date >= todayDatestring &&
          tableRide.date <= lastResortDatestring
        );
      } else if (currentFilter === 'pending') {
        return true;
      } else if (currentFilter === 'standard') {
        return tableRide.rideType === 'Standard';
      } else if (currentFilter === 'foodBank') {
        return (
          tableRide.rideType.toString().toLowerCase() === 'food bank delivery'
        );
      } else if (currentFilter === 'loanClosetDelivery') {
        return (
          tableRide.rideType.toString().toLowerCase() === 'loan closet delivery'
        );
      } else if (currentFilter === 'loanClosetReturn') {
        return (
          tableRide.rideType.toString().toLowerCase() ===
          'loan closet equipment return'
        );
      } else {
        return false;
      }
    });
    setFilteredTableRides(newFilteredTableRides);
  }, [currentFilter, allTableRides]);

  const dataNotReady = !allTableRides;
  if (dataNotReady) {
    return null;
  }

  const handleAcceptRide = async (rideId: string) => {
    const aux = await acceptRide(userId, rideId, userToken);
    if (aux) {
      setSuccess(true);
    }
    getAllRides();
  };

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="contained"
          sx={{ marginTop: isMobile ? '10px' : 0 }}
          onClick={() => setCurrentFilter('pending')}
        >
          All Pending ({allTableRides.length})
        </Button>
        <Button
          variant="contained"
          sx={{ marginTop: isMobile ? '10px' : 0 }}
          onClick={() => setCurrentFilter('lastResort')}
        >
          Last Resort (
          {
            allTableRides.filter(
              (ride) =>
                ride.date <= moment().add(1, 'days').format('YYYY-MM-DD')
            ).length
          }
          )
        </Button>
        <Button
          variant="contained"
          sx={{ marginTop: isMobile ? '10px' : 0 }}
          onClick={() => setCurrentFilter('allCall')}
        >
          All Call (
          {
            allTableRides.filter(
              (ride) =>
                ride.date <= moment().add(3, 'days').format('YYYY-MM-DD')
            ).length
          }
          )
        </Button>
        <Button
          variant="contained"
          sx={{ marginTop: isMobile ? '10px' : 0 }}
          onClick={() => setCurrentFilter('standard')}
        >
          Standard (
          {allTableRides.filter((ride) => ride.rideType === 'Standard').length})
        </Button>
        <Button
          variant="contained"
          sx={{ marginTop: isMobile ? '10px' : 0 }}
          onClick={() => setCurrentFilter('foodBank')}
        >
          Food Bank Delivery (
          {
            allTableRides.filter(
              (ride) => ride.rideType === 'Food Bank Delivery'
            ).length
          }
          )
        </Button>
        <Button
          variant="contained"
          sx={{ marginTop: isMobile ? '10px' : 0 }}
          onClick={() => setCurrentFilter('loanClosetDelivery')}
        >
          Loan Closet Delivery (
          {
            allTableRides.filter(
              (ride) => ride.rideType === 'Loan Closet Delivery'
            ).length
          }
          )
        </Button>
        <Button
          variant="contained"
          sx={{ marginTop: isMobile ? '10px' : 0 }}
          onClick={() => setCurrentFilter('loanClosetReturn')}
        >
          Loan Closet Equipment Return (
          {
            allTableRides.filter(
              (ride) => ride.rideType === 'Loan Closet Equipment Return'
            ).length
          }
          )
        </Button>
      </Box>
      <h2>{getRideTypeDisplayString(currentFilter)} Rides</h2>
      <Grid justifyContent="space-between" container>
        <Grid xs={12} md={2} item marginBottom={2}>
          <FormControl fullWidth>
            <TextField
              value={rideFinder.zip}
              label="ZIP"
              name="zip"
              onChange={handleChange}
              inputProps={{ maxLength: 5 }}
            ></TextField>
          </FormControl>
        </Grid>
        <Grid xs={12} md={2} item marginBottom={2}>
          <FormControl fullWidth>
            <InputLabel>Ride Purpose</InputLabel>
            <Select
              value={rideFinder.purpose}
              label="Ride Purpose"
              name="purpose"
              onChange={handleChange}
            >
              <MenuItem value="">All</MenuItem>
              {purposes.map((pur, index) => (
                <MenuItem value={pur} key={index}>
                  {pur}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={2} item marginBottom={2}>
          <FormControl fullWidth>
            <InputLabel>Trip Direction</InputLabel>
            <Select
              value={rideFinder.direction}
              label="Trip Direction"
              name="direction"
              onChange={handleChange}
            >
              <MenuItem value="">All</MenuItem>
              {directions.map((dir, index) => (
                <MenuItem value={dir} key={index}>
                  {dir}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={2} item marginBottom={2}>
          <FormControl fullWidth>
            <InputLabel>HSV</InputLabel>
            <Select
              value={rideFinder.hsv}
              label="HSV"
              name="hsv"
              onChange={handleChange}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={2} item marginBottom={2}>
          <FormControl fullWidth>
            <InputLabel>Howard County Only</InputLabel>
            <Select
              value={rideFinder.hoCoOnly}
              label="Howard County Only"
              name="hoCoOnly"
              onChange={handleChange}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} item marginBottom={2}>
          <Button
            onClick={() => setRideFinder(initialRideFinder)}
            fullWidth
            variant="contained"
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      {isMobile ? (
        <>
          {findRides().map((ride: any, index) => (
            <RidePaper
              key={index}
              ride={ride}
              handleAcceptRide={() => handleAcceptRide(ride.id)}
            />
          ))}
        </>
      ) : (
        <DataGrid
          rows={findRides()}
          columns={driverDashboardColumns(
            userId,
            userToken,
            false,
            setSuccess,
            getAllRides,
            disabled,
            setDisabled
          )}
          loading={isLoading}
          autoHeight
          rowsPerPageOptions={[]}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        sx={{ marginTop: 10 }}
      >
        <Alert
          sx={{ width: '100%', padding: '20px 30px', fontWeight: 'bold' }}
          onClose={() => setSuccess(false)}
        >
          Ride has been accepted.
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
}
