import { SetStateAction, useContext, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
} from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import Messages from '../../components/Messages';
import { getUserProfile } from '../../dbconnector/users';
import { AuthState } from '../../models/auth_state';
import { UserContext } from '../../models/user_context';

function LoginPage() {
  const navigate = useNavigate();
  const { setAuthState, setUserId, setUserToken, setUserFullName } =
    useContext(UserContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const changeEmail = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setEmail(event.target.value);
  };
  const changePassword = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    const auth = getAuth();
    let userInactive = false;
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredentials) => {
        const uid = userCredentials.user.uid;
        const token = await userCredentials.user.getIdToken();
        const user = await getUserProfile(uid, token);

        if (user.user_status_id === '1') {
          userInactive = true;
          throw new Error();
        }

        if (user.role === 'client') {
          setAuthState(AuthState.Client);
          localStorage.setItem('authState', AuthState.Client.toString());
        } else if (user.role === 'driver') {
          setAuthState(AuthState.Driver);
          localStorage.setItem('authState', AuthState.Driver.toString());
        } else if (user.role === 'liaison') {
          setAuthState(AuthState.Liaison);
          localStorage.setItem('authState', AuthState.Liaison.toString());
        } else {
          throw new Error('Invalid role.');
        }

        const fullName = `${user.first_name} ${user.last_name}`;
        setUserId(uid);
        setUserToken(token);
        setUserFullName(fullName);
        localStorage.setItem('userId', uid);
        localStorage.setItem('userToken', token);
        localStorage.setItem('userFullName', fullName);
        setLoading(false);
        navigate('/dashboard');
      })
      .catch((error1) => {
        console.log({ error1 });
        setLoading(false);
        if (userInactive) {
          setError('This user is inactive.');
        } else {
          setError('Error logging in.');
        }
      });
  };

  return (
    <Box
      sx={{
        display: { xs: 'flex' },
        flexDirection: 'column',
        alignSelf: 'center',
        margin: '50px 10px 50px 10px',
      }}
    >
      <Messages role="Login page" />
      <Box sx={{ marginTop: 5 }} />
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          padding: '20px 20px 20px 20px',
        }}
      >
        <h2>Welcome to Ride Match!</h2>
        <p style={{ marginBottom: '30px' }}>Sign in below.</p>
        <FormControl sx={{ marginBottom: '20px' }}>
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            id="email"
            label="Email"
            value={email}
            onChange={changeEmail}
          />
        </FormControl>
        <FormControl sx={{ marginBottom: '20px' }}>
          <InputLabel htmlFor="password" error={error !== ''}>
            Password
          </InputLabel>
          <OutlinedInput
            id="password"
            label="Password"
            type="password"
            value={password}
            error={error !== ''}
            onChange={changePassword}
            aria-describedby="password-error-text"
          />
          <FormHelperText id="password-error-text" error>
            {error}
          </FormHelperText>
        </FormControl>
        <LoadingButton
          variant="contained"
          sx={{ marginBottom: '10px' }}
          loading={loading}
          onClick={() => {
            setLoading(true);
            handleLogin();
          }}
        >
          Sign in
        </LoadingButton>
        <Button
          variant="text"
          sx={{ marginBottom: '30px' }}
          onClick={() => {
            navigate('/password-reset');
          }}
        >
          Reset Password
        </Button>
        <p>
          Call 410-884-RIDE (7433) to request a ride if you experience problems
          signing in.
        </p>
      </Paper>
    </Box>
  );
}

export default LoginPage;
