import { useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { AuthState } from '../../models/auth_state';
import { UserContext } from '../../models/user_context';
import { ClientDashboardPage } from './components/ClientDashboardPage';
import { DriverDashboardPage } from './components/DriverDashboardPage';
import { LiaisonDashboardPage } from './components/LiaisonDashboardPage';

export function DashboardPage() {
  const { authState } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const rideId = localStorage.getItem('rideId');
    if (rideId) {
      localStorage.removeItem('rideId');
      navigate(`/trips/${rideId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authState === AuthState.Client) {
    return <ClientDashboardPage />;
  }

  if (authState === AuthState.Driver) {
    return <DriverDashboardPage />;
  }

  if (authState === AuthState.Liaison) {
    return <LiaisonDashboardPage />;
  }

  return null;
}
