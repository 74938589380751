import { useContext } from 'react';

import { Box } from '@mui/material';

import { UserContext } from '../models/user_context';

interface GridItemProps {
  content?: GridItemContent;
  header: string;
}

export interface GridItemContent {
  [key: string]: string | undefined;
}

export default function GridItem(props: GridItemProps) {
  const { content, header } = props;
  const { isMobile } = useContext(UserContext);

  return (
    <Box
      sx={{
        border: 1,
        width: '350px',
        marginBottom: '15px',
        marginRight: isMobile ? 0 : '15px',
      }}
    >
      <Box
        sx={{
          padding: '0.5em',
          fontSize: '18px',
          backgroundColor: 'rgb(221, 221, 221)',
        }}
      >
        <b>{header}</b>
      </Box>
      <Box sx={{ textAlign: 'left', paddingLeft: '1%' }}>
        {content &&
          Object.keys(content).map((key, index) => (
            <Box key={index} sx={{ lineHeight: 1.5 }}>
              <b>{key}</b>: {content[key]}
            </Box>
          ))}
      </Box>
    </Box>
  );
}
