/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useContext } from 'react';

import { Box, Grid, Button, Snackbar, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import AccountInformation from '../../../components/AccountInformation';
import Messages from '../../../components/Messages';
import MonthRides from '../../../components/MonthRides/MonthRides';
import { PageWrapper, PageTitle } from '../../../components/PageComponents';
import RidePaper from '../../../components/RidePaper';
import { getClientRides } from '../../../dbconnector/rides';
import { FirebaseRide, TableRide } from '../../../models/rides';
import { UserContext } from '../../../models/user_context';
import { sortRides } from '../../utils';
import {
  clientDashboardColumns,
  convertFirebaseRidesToTableRides,
} from '../utils';

export function ClientDashboardPage() {
  const navigate = useNavigate();
  const { userId, userToken, isMobile } = useContext(UserContext);

  const [assignedRides, setAssignedRides] = useState<TableRide[]>([]);
  const [pendingRides, setPendingRides] = useState<TableRide[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  async function getAllRides() {
    const token = userToken;
    const clientRides: FirebaseRide[] = await getClientRides(userId, token);
    console.log({ clientRides });
    const assignedFirebaseRides = clientRides.filter(
      (ride) => ride.trip_status_id === '3' || ride.trip_status_id === '4' // assigned
    );
    const pendingFirebaseRides = clientRides.filter(
      (ride) => ride.trip_status_id === '1' || ride.trip_status_id === '2' // pending
    );
    const newAssignedRides = convertFirebaseRidesToTableRides(
      assignedFirebaseRides
    );
    const newPendingRides =
      convertFirebaseRidesToTableRides(pendingFirebaseRides);
    console.log({ newAssignedRides });
    console.log({ newPendingRides });
    setAssignedRides(newAssignedRides);
    setPendingRides(newPendingRides);
    setIsLoading(false);
  }

  useEffect(() => {
    getAllRides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <PageTitle title="Dashboard" />
      <Grid container justifyContent="space-between">
        <Grid
          item
          md={8.5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <Messages role="Client" />
          <Button
            variant="contained"
            sx={{
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
            onClick={() => navigate('/ride-request')}
          >
            Request a Ride
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
            onClick={() => navigate('/ride-history')}
          >
            Ride History
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
            onClick={() => navigate('/make-payment')}
          >
            Make Payment
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
            onClick={() => navigate('/account-history')}
          >
            Account History
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <AccountInformation />
          <Box sx={{ marginTop: '20px' }} />
          <MonthRides userId={userId} />
        </Grid>
      </Grid>
      <h2>
        {assignedRides.length === 0 && isMobile
          ? ''
          : 'Rides with Drivers Assigned'}
      </h2>
      {isMobile ? (
        <>
          {sortRides(assignedRides).map((ride: any, index) => (
            <RidePaper ride={ride} key={index} />
          ))}
        </>
      ) : (
        <DataGrid
          rows={sortRides(assignedRides)}
          columns={clientDashboardColumns(
            userId,
            userToken,
            setSuccess,
            setFailure,
            getAllRides
          )}
          pageSize={7}
          autoHeight
          loading={isLoading}
        />
      )}
      <h2>
        {pendingRides.length === 0 && isMobile
          ? ''
          : 'Rides with Drivers Pending'}
      </h2>
      {isMobile ? (
        <>
          {sortRides(pendingRides).map((ride: any, index) => (
            <RidePaper ride={ride} key={index} />
          ))}
        </>
      ) : (
        <DataGrid
          rows={sortRides(pendingRides)}
          columns={clientDashboardColumns(userId, userToken)}
          pageSize={7}
          autoHeight
          loading={isLoading}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        sx={{ marginTop: 10 }}
      >
        <Alert
          sx={{ width: '100%', padding: '20px 30px', fontWeight: 'bold' }}
          onClose={() => setSuccess(false)}
        >
          Ride has been confirmed.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={failure}
        autoHideDuration={6000}
        onClose={() => setFailure(false)}
        sx={{ marginTop: 10 }}
      >
        <Alert
          severity="error"
          sx={{ width: '100%', padding: '20px 30px', fontWeight: 'bold' }}
          onClose={() => setFailure(false)}
        >
          Ride could not be confirmed, please contact a Ride Coordinator for
          assistance.
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
}
