/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import AccountInformation from '../../components/AccountInformation';
import DateInput from '../../components/DateInput';
import { PageTitle, PageWrapper } from '../../components/PageComponents';
import { UserContext } from '../../models/user_context';
import { BASE_API_URL } from '../../models/utils';
import {
  CustomToolbar,
  IJson,
  ITransaction,
  columns,
  dateAux,
  emptyDates,
} from './helpers';

export default function AccountHistory() {
  const { userId, userToken, verifySession } = useContext(UserContext);

  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState(emptyDates);
  const [dateFilter, setDateFilter] = useState(false);

  useEffect(() => {
    if (dates.from.length === 10 && dates.to.length === 10) setDateFilter(true);
    else setDateFilter(false);
  }, [dates]);

  useEffect(() => {
    fetch(`${BASE_API_URL}/payments/${userId}`, {
      headers: { Authorization: userToken },
    }).then(async (res) => {
      if (res.status === 200) {
        const json: IJson = await res.json();
        const aux = json.transactions
          .sort((a, b) => {
            if (
              new Date(dateAux(a.created_at)) < new Date(dateAux(b.created_at))
            )
              return 1;
            return -1;
          })
          .map((transaction, index: number) => ({
            id: index,
            date: transaction.created_at
              ? new Date(dateAux(transaction.created_at)).toLocaleDateString(
                  'en-US'
                )
              : '-',
            details: `${transaction.type} / ${transaction.purpose}`,
            guest:
              transaction.guest_pay === '0'
                ? 'No'
                : transaction.guest_pay === '1'
                ? 'Yes'
                : '',
            credit: `$${Number(transaction.credit).toFixed(2)}`,
            debit: `$${Number(transaction.debit).toFixed(2)}`,
          }));
        setTransactions(aux);
        setIsLoading(false);
      } else {
        verifySession(res.status);
      }
    });
  }, [userToken]);

  const getFilteredRows = () =>
    transactions.filter((transaction) => {
      if (!dateFilter) return transaction;

      return new Date(transaction.date) >= new Date(dates.from) &&
        new Date(transaction.date) <= new Date(dates.to)
        ? true
        : false;
    });

  return (
    <PageWrapper>
      <PageTitle title="Account History" />
      <Grid container justifyContent="space-between" sx={{ marginTop: '30px' }}>
        <Grid item xs={12} md={3} sx={{ marginBottom: '30px' }}>
          <Box sx={{ marginBottom: '30px' }}>
            <h2 style={{ marginTop: 0 }}>Filter by Date</h2>
            <DateInput
              name="from"
              label="From (MM/DD/AAAA)"
              date={dates.from}
              setDate={(from: string) => setDates({ ...dates, from })}
            />
            <DateInput
              name="to"
              label="To (MM/DD/AAAA)"
              date={dates.to}
              setDate={(to: string) => setDates({ ...dates, to })}
              sx={{ marginY: '10px' }}
            />
            <Button
              onClick={() => setDates(emptyDates)}
              fullWidth
              variant="contained"
            >
              Clear filter
            </Button>
          </Box>
          <AccountInformation />
        </Grid>
        <Grid item xs={12} md={7}>
          <DataGrid
            disableSelectionOnClick
            autoHeight
            pageSize={7}
            loading={isLoading}
            rows={getFilteredRows()}
            columns={columns}
            components={{ Toolbar: CustomToolbar }}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
