/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';

import { Grid, Button, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import Messages from '../../../components/Messages';
import { PageWrapper, PageTitle } from '../../../components/PageComponents';
import RidePaper from '../../../components/RidePaper';
import YearRides from '../../../components/YearRides';
import {
  cachePendingRides,
  getAssignedRides,
  getPendingCount,
} from '../../../dbconnector/rides';
import { FirebaseRide, TableRide } from '../../../models/rides';
import { UserContext } from '../../../models/user_context';
import { sortRides } from '../../utils';
import {
  convertFirebaseRidesToTableRides,
  driverDashboardColumns,
} from '../utils';

export function DriverDashboardPage() {
  const navigate = useNavigate();
  const { userId, userToken, isMobile } = useContext(UserContext);

  const [pendingRidesCount, setPendingRidesCount] = useState(0);
  const [assignedRides, setAssignedRides] = useState<TableRide[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const pendingRidesHelper = async () => {
    cachePendingRides();
    const count = await getPendingCount();
    setPendingRidesCount(count);
  };

  useEffect(() => {
    async function getRides() {
      const token = userToken;
      const rides: FirebaseRide[] = await getAssignedRides(userId, token);
      const tableRides = convertFirebaseRidesToTableRides(rides);
      setAssignedRides(tableRides);
      setIsLoading(false);
    }

    getRides();
    pendingRidesHelper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userToken]);

  return (
    <PageWrapper>
      <PageTitle title="Dashboard" />
      <Grid container justifyContent="space-between">
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          item
          xs={12}
          md={8.5}
        >
          <Messages role="Driver" />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              variant="contained"
              sx={{
                fontWeight: 'bold',
                marginBottom: '10px',
                fontSize: 'x-large',
              }}
              onClick={() => navigate('/driver-rides')}
            >
              Pending Rides ({pendingRidesCount})
            </Button>
            <Button
              variant="contained"
              sx={{
                fontWeight: 'bold',
                marginBottom: '10px',
                fontSize: 'x-large',
              }}
              onClick={() => navigate('/suggested-rides')}
            >
              Suggested Rides
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                sx={{ fontWeight: 'bold', width: '48%' }}
                onClick={() => navigate('/update-schedule')}
              >
                Update Schedule
              </Button>
              <Button
                variant="contained"
                sx={{ fontWeight: 'bold', width: '48%' }}
                onClick={() => navigate('/ride-history')}
              >
                Ride History
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid sx={{ marginTop: isMobile ? '10px' : 0 }} item xs={12} md={3}>
          <YearRides />
        </Grid>
      </Grid>
      <Box>
        <h2>Assigned Rides</h2>
        {isMobile ? (
          <>
            {sortRides(assignedRides).map((ride: any, index) => (
              <RidePaper ride={ride} key={index} />
            ))}
          </>
        ) : (
          <DataGrid
            rows={sortRides(assignedRides)}
            columns={driverDashboardColumns(userId, userToken, true)}
            pageSize={7}
            autoHeight
            loading={isLoading}
          />
        )}
      </Box>
    </PageWrapper>
  );
}
