import { Box } from '@mui/material';

import { Colors } from '../../../constants';

interface BoxContainerProps {
  children?: React.ReactNode;
  header: string;
}

export default function BoxContainer(props: BoxContainerProps) {
  const { children, header } = props;
  return (
    <Box sx={{ border: 1, flex: 1 }}>
      <Box
        sx={{
          padding: '0.5em',
          backgroundColor: Colors.greyTextBackground,
        }}
      >
        <h3>{header}</h3>
      </Box>
      {children}
    </Box>
  );
}
