import { useLocation } from 'react-router-dom';

function NotFoundPage() {
  const location = useLocation();

  return (
    <div>
      <h1>The page {location.pathname} could not be found.</h1>
    </div>
  );
}

export default NotFoundPage;
