export const directions = [
  'Roundtrip',
  'One Way From Client',
  'One Way from Destination',
];

export const purposes = [
  'Dental',
  'Exercise',
  'Medical',
  'Personal Business',
  'Personal Care',
  'Rehabilitation',
  'Religious Servs.',
  'Shopping',
  'Social',
  'Volunteer',
  'Work',
  'Medical/Shopping',
  'Education',
  'Personal',
  'Accompanied Grocery Shopping',
  'Vision',
];

export const hoCoZipCodes = [
  '20701',
  '20723',
  '20759',
  '20763',
  '20777',
  '20794',
  '20833',
  '21029',
  '21036',
  '21042',
  '21043',
  '21044',
  '21045',
  '21046',
  '21075',
  '21076',
  '21104',
  '21163',
  '21723',
  '21737',
  '21738',
  '21771',
  '21784',
  '21794',
  '21797',
  '21041',
  '21150',
  '21765',
];

export const initialRideFinder = {
  zip: '',
  purpose: '',
  direction: '',
  hsv: '',
  hoCoOnly: 'No',
};
