import { useState, useEffect } from 'react';

import { Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import Dropdown from '../../../components/Dropdown';
import { DriverInfoModel } from '../../../models/driver_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  driverInfo: DriverInfoModel;
  setDriverInfo: React.Dispatch<React.SetStateAction<DriverInfoModel>>;
}

export default function DriverInfo(props: InfoProps) {
  const getFirstLetterCapitalString = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const { driverInfo, setDriverInfo } = props;
  const [vehicleType, setVehicleType] = useState(
    getFirstLetterCapitalString(driverInfo.vehicleType)
  );
  const [vehiclePassengerLimit, setVehiclePassengerLimit] = useState(
    getFirstLetterCapitalString(driverInfo.vehiclePassengerLimit)
  );
  const [vehicleStepRestriction, setVehicleStepRestriction] = useState(
    getFirstLetterCapitalString(driverInfo.vehicleHighStepRestriction)
  );
  const [insuranceExpiration, setInsuranceExpiration] = useState<Date | null>(
    driverInfo.insuranceExpiration
  );
  const [driversLicenseExpiration, setDriversLicenseExpiration] =
    useState<Date | null>(driverInfo.licenseExpiration);

  const possibleVehiclesTypes = [
    'Sedan',
    'Small SUV',
    'SUV with Running Board',
    'Compact',
  ];
  const possibleVehiclePassengerLimit = ['1', '2', '3', '4', '5', '6'];
  const possibleVehicleStepRestriction = ['No', 'Yes'];

  type StringKeys = keyof Omit<
    DriverInfoModel,
    'insuranceExpiration' | 'licenseExpiration'
  >;

  const updateDriverStringKey = (key: StringKeys, val: string) => {
    setDriverInfo((obj: DriverInfoModel) => {
      const objCopy: DriverInfoModel = { ...obj };
      objCopy[key] = val;
      return objCopy;
    });
  };

  // const updateDriverDateKey = (
  //   key: 'insuranceExpiration' | 'licenseExpiration',
  //   val: Date | null | undefined
  // ) => {
  //   if (val) {
  //     console.log({ val });
  //     setDriverInfo((obj: DriverInfoModel) => {
  //       const objCopy: DriverInfoModel = { ...obj };
  //       objCopy[key] = val;
  //       return objCopy;
  //     });
  //   }
  // };

  useEffect(() => {
    if (vehicleType) {
      setDriverInfo((obj: DriverInfoModel) => {
        const objCopy = { ...obj };
        objCopy.vehicleType = getFirstLetterCapitalString(vehicleType);
        return objCopy;
      });
    }
    if (vehiclePassengerLimit) {
      setDriverInfo((obj: DriverInfoModel) => {
        const objCopy = { ...obj };
        objCopy.vehiclePassengerLimit = getFirstLetterCapitalString(
          vehiclePassengerLimit
        );
        return objCopy;
      });
    }
    if (vehicleStepRestriction) {
      setDriverInfo((obj: DriverInfoModel) => {
        const objCopy = { ...obj };
        objCopy.vehicleHighStepRestriction = getFirstLetterCapitalString(
          vehicleStepRestriction
        );
        return objCopy;
      });
    }
    if (insuranceExpiration) {
      setDriverInfo((obj: DriverInfoModel) => {
        const objCopy = { ...obj };
        objCopy.insuranceExpiration = insuranceExpiration;
        return objCopy;
      });
    }
    if (driversLicenseExpiration) {
      setDriverInfo((obj: DriverInfoModel) => {
        const objCopy = { ...obj };
        objCopy.licenseExpiration = driversLicenseExpiration;
        return objCopy;
      });
    }
  }, [
    vehicleType,
    vehiclePassengerLimit,
    vehicleStepRestriction,
    insuranceExpiration,
    driversLicenseExpiration,
    setDriverInfo,
  ]);

  return (
    <BoxContainer header="Driver Info">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12}>
          <h4>Insurance Company *</h4>
          <TextField
            value={driverInfo.insuranceCompany}
            onChange={(e) =>
              updateDriverStringKey('insuranceCompany', e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Insurance Expiration</h4>
          <DesktopDatePicker
            inputFormat="MM/DD/YYYY"
            value={insuranceExpiration}
            onChange={setInsuranceExpiration}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Driver's License Expiration</h4>
          <DesktopDatePicker
            inputFormat="MM/DD/YYYY"
            value={driversLicenseExpiration}
            onChange={setDriversLicenseExpiration}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Vehicle Type *</h4>
          <Dropdown
            options={possibleVehiclesTypes}
            displayOption={vehicleType}
            setDisplayOption={setVehicleType}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Vehicle High Step Restriction *</h4>
          <Dropdown
            options={possibleVehicleStepRestriction}
            displayOption={vehicleStepRestriction}
            setDisplayOption={setVehicleStepRestriction}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Vehicle Passenger Limit *</h4>
          <Dropdown
            options={possibleVehiclePassengerLimit}
            displayOption={vehiclePassengerLimit}
            setDisplayOption={setVehiclePassengerLimit}
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
