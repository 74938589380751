import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function BackToDashboardButton() {
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      onClick={() => {
        navigate('/dashboard');
      }}
      sx={{
        height: '40px',
        padding: '10px',
      }}
    >
      <ArrowBackIosNewIcon sx={{ fontSize: '15px', marginRight: '10px' }} />
      Dashboard
    </Button>
  );
}
