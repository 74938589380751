import { useContext, useEffect, useState } from 'react';

import { TextField, Button } from '@mui/material';

import { USstatesDropdown } from '../../components/USstatesDropDown';
import { addNewLocation } from '../../dbconnector/rides';
import { UserContext } from '../../models/user_context';
import { isZipValid } from '../utils';
import {
  NewLocationProps,
  emptyLocation,
  firebaseLocation,
  simpleInputs,
} from './helpers';

export default function NewLocation({
  setNewLocation,
  setTab,
}: NewLocationProps) {
  const { userId, userToken } = useContext(UserContext);

  const [location, setLocation] = useState(emptyLocation);
  const [zipError, setZipError] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    const aux = !isZipValid(location.zipcode) && location.zipcode !== '';
    setZipError(aux);
  }, [location.zipcode]);

  const areAllFieldsFilled = () =>
    Object.values(location).every((value) => value !== '');

  const handleClick = async () => {
    const newLocation = { ...firebaseLocation, ...location };

    if (areAllFieldsFilled() && isZipValid(location.zipcode)) {
      setSubmitError(false);
      const success = await addNewLocation(userId, userToken, newLocation);
      if (success) {
        setNewLocation(newLocation);
        setTab(0);
      }
    } else setSubmitError(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation({
      ...location,
      [event.target.name]: event.target.value,
    });
  };

  const renderInput = (name: string, label: string) => (
    <TextField
      key={name}
      name={name}
      label={label}
      onChange={handleInputChange}
      sx={{ marginY: '10px' }}
      fullWidth
    />
  );

  const setState = (state: string) => setLocation({ ...location, state });

  return (
    <div>
      <h3>New Location</h3>
      {simpleInputs.map(({ name, label }) => renderInput(name, label))}
      <USstatesDropdown sx={{ marginY: '10px' }} setState={setState} />
      <TextField
        label="Zip Code"
        name="zipcode"
        error={zipError}
        helperText={zipError && 'Invalid Zip code'}
        onChange={handleInputChange}
        sx={{ marginY: '10px' }}
        fullWidth
      />
      <Button
        variant="contained"
        sx={{ marginTop: '20px' }}
        onClick={handleClick}
      >
        Add Location
      </Button>
      {submitError && (
        <p style={{ color: '#d32f2f' }}>
          Please make sure all fields are properly filled.
        </p>
      )}
    </div>
  );
}
