import { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Tab,
  Tabs,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { PageWrapper, PageTitle } from '../../../components/PageComponents';
import {
  getDriverProfile,
  getUserProfile,
  updateDriverProfile,
} from '../../../dbconnector/users';
import {
  GeneralInfoModel,
  ContactModel,
  RidePreferencesModel,
  LocationModel,
  NotesModel,
  DriverInfoModel,
  EmergencyModel,
  getDriverCommunicationPreferenceFromId,
  getVehicleTypeFromId,
  NeighborRideDriver,
  getDriverCommunicationPreferenceIdFromName,
  getVehicleTypeIdFromName,
} from '../../../models/driver_profile';
import { NeighborRideUser } from '../../../models/user';
import { UserContext } from '../../../models/user_context';
import { getDatestringFromDate } from '../../DashboardPage/utils';
import { getDateFromNeighborRideDate, getNeighborRideDate } from '../../utils';
import Address from '../components/Address';
import GeneralInfo from '../components/GeneralInfo';
import { TabPanel } from '../components/TabPanel';
import Contact from './Contact';
import DriverInfo from './DriverInfo';
import Emergency from './Emergency';
import Notes from './Notes';
import RidePreferences from './RidePreferences';

export default function DriverUpdateProfile() {
  const { userId, userToken, isMobile } = useContext(UserContext);
  const navigate = useNavigate();

  const [driver, setDriver] = useState<NeighborRideDriver>();
  const [user, setUser] = useState<NeighborRideUser>();

  const [tabIndex, setTabIndex] = useState(0);
  const [generalInfo, setGeneralInfo] = useState<GeneralInfoModel>({
    firstName: '',
    lastName: '',
    role: 'Driver',
    username: '',
    gender: '',
  });
  const [contactInfo, setContactInfo] = useState<ContactModel>({
    cellPhone: '',
    homePhone: '',
    workPhone: '',
  });
  const [ridePreferences, setRidePreferences] = useState<RidePreferencesModel>({
    dailyCall: false,
    emergencyList: false,
    howardOnly: false,
    notificationPreferences: '',
    notifyAcceptedRide: false,
    notifyBeforeRide: false,
  });
  const [addressInfo, setAddressInfo] = useState<LocationModel>({
    developmentName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [notesInfo, setNotesInfo] = useState<NotesModel>({
    driverNotes: '',
    vehicleNotes: '',
  });
  const [emergencyInfo, setEmergencyInfo] = useState<EmergencyModel>({
    emergencyName: '',
    emergencyPhone: '',
  });
  const [driverInfo, setDriverInfo] = useState<DriverInfoModel>({
    insuranceCompany: '',
    insuranceExpiration: new Date(),
    licenseExpiration: new Date(),
    vehicleType: '',
    vehicleHighStepRestriction: '',
    vehiclePassengerLimit: '',
  });

  const [btnSaving, setBtnSaving] = useState(false);
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    async function downloadDriverData() {
      const token = userToken;
      const inputDriver = await getDriverProfile(userId, token);
      const inputUser = await getUserProfile(userId, token);
      console.log(inputDriver.birth_date);
      const birthdate =
        inputDriver.birth_date !== '' && inputDriver.birth_date
          ? moment(inputDriver.birth_date, 'YYYY-MM-DD').toDate()
          : undefined;
      console.log({ birthdate });
      const newGeneralInfo: GeneralInfoModel = {
        firstName: inputUser.first_name,
        lastName: inputUser.last_name,
        role: 'Driver',
        gender: inputDriver.gender,
        birthDate: birthdate,
      };
      const newContactInfo: ContactModel = {
        cellPhone: inputDriver.cell_phone,
        homePhone: inputDriver.home_phone,
        workPhone: inputDriver.work_phone,
      };
      const newRidePreferences: RidePreferencesModel = {
        dailyCall: inputDriver.ride_alert_all_call === '0',
        emergencyList: inputDriver.ride_alert_last_resort === '0',
        howardOnly: inputDriver.howard_county === '0',
        notificationPreferences: getDriverCommunicationPreferenceFromId(
          inputDriver.profile_comm_preference_id
        ),
        notifyAcceptedRide:
          inputDriver.profile_ride_confirmation_accept === '0',
        notifyBeforeRide: inputDriver.profile_ride_reminder_day_before === '0',
      };
      const newAddressInfo: LocationModel = {
        developmentName: inputDriver.development_name,
        address: inputDriver.address1,
        address2: inputDriver.address2,
        city: inputDriver.city,
        state: inputDriver.state,
        zipCode: inputDriver.zip,
      };
      const newNotesInfo: NotesModel = {
        driverNotes: inputDriver.driver_notes,
        vehicleNotes: inputDriver.vehicle_specific_notes,
      };
      const newEmergencyInfo: EmergencyModel = {
        emergencyName: inputDriver.emergency_contact_name,
        emergencyPhone: inputDriver.emergency_contact_phone,
      };
      console.log('drivers:');
      console.log(inputDriver.drivers_license_expiration);
      const newDriverInfo: DriverInfoModel = {
        insuranceCompany: inputDriver.insurance_company,
        insuranceExpiration: getDateFromNeighborRideDate(
          inputDriver.insurance_expiration
        ),
        licenseExpiration: getDateFromNeighborRideDate(
          inputDriver.drivers_license_expiration
        ),
        vehicleType: getVehicleTypeFromId(inputDriver.vehicle_type_id),
        vehicleHighStepRestriction: inputDriver.vehicle_high_step_restriction,
        vehiclePassengerLimit: inputDriver.vehicle_passenger_limit,
      };

      setDriver(inputDriver);
      setUser(inputUser);

      setGeneralInfo(newGeneralInfo);
      setContactInfo(newContactInfo);
      setRidePreferences(newRidePreferences);
      setAddressInfo(newAddressInfo);
      setNotesInfo(newNotesInfo);
      setEmergencyInfo(newEmergencyInfo);
      setDriverInfo(newDriverInfo);
      return;
    }
    downloadDriverData();
  }, [userId, userToken]);

  const tabComponents = [
    {
      header: 'General Info',
      component: (
        <GeneralInfo
          generalInfo={generalInfo}
          setGeneralInfo={setGeneralInfo}
        />
      ),
    },
    {
      header: 'Contact',
      component: (
        <Contact contactInfo={contactInfo} setContactInfo={setContactInfo} />
      ),
    },
    {
      header: 'Ride Preferences',
      component: (
        <RidePreferences
          preferencesInfo={ridePreferences}
          setPreferencesInfo={setRidePreferences}
        />
      ),
    },
    {
      header: 'Address',
      component: (
        <Address addressInfo={addressInfo} setAddressInfo={setAddressInfo} />
      ),
    },
    {
      header: 'Notes',
      component: <Notes notesInfo={notesInfo} setNotesInfo={setNotesInfo} />,
    },
    {
      header: 'Emergency',
      component: (
        <Emergency
          emergencyInfo={emergencyInfo}
          setEmergencyInfo={setEmergencyInfo}
        />
      ),
    },
    {
      header: 'Driver Info',
      component: (
        <DriverInfo driverInfo={driverInfo} setDriverInfo={setDriverInfo} />
      ),
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setTabIndex(newValue);
  };

  const moveTabForward = (currIndex: number) => {
    if (currIndex < tabComponents.length - 1) {
      setTabIndex(currIndex + 1);
    }
  };

  const moveTabBackward = (currIndex: number) => {
    if (currIndex > 0) {
      setTabIndex(currIndex - 1);
    }
  };

  async function handleUpdateDriverProfile() {
    setBtnSaving(true);
    const newDriver: NeighborRideDriver = JSON.parse(JSON.stringify(driver));
    const newUser: NeighborRideUser = JSON.parse(JSON.stringify(user));
    newUser.first_name = generalInfo.firstName;
    newUser.last_name = generalInfo.lastName;
    newDriver.gender = generalInfo.gender;
    newDriver.birth_date = generalInfo.birthDate
      ? getNeighborRideDate(generalInfo.birthDate)
      : '';
    newDriver.cell_phone = contactInfo.cellPhone;
    newDriver.home_phone = contactInfo.homePhone;
    newDriver.work_phone = contactInfo.workPhone;
    newDriver.ride_alert_all_call = ridePreferences.dailyCall ? '0' : '1';
    newDriver.ride_alert_last_resort = ridePreferences.emergencyList
      ? '0'
      : '1';
    newDriver.howard_county = ridePreferences.howardOnly ? '0' : '1';
    newDriver.profile_comm_preference_id =
      getDriverCommunicationPreferenceIdFromName(
        ridePreferences.notificationPreferences
      );
    console.log('here it is:');
    console.log(ridePreferences.notifyAcceptedRide);
    newDriver.profile_ride_confirmation_accept =
      ridePreferences.notifyAcceptedRide ? '0' : '1';
    console.log(newDriver.profile_ride_confirmation_accept);
    newDriver.profile_ride_reminder_day_before =
      ridePreferences.notifyBeforeRide ? '0' : '1';

    newDriver.development_name = addressInfo.developmentName;
    newDriver.address1 = addressInfo.address;
    newDriver.address2 = addressInfo.address2;
    newDriver.city = addressInfo.city;
    newDriver.state = addressInfo.state;
    newDriver.zip = addressInfo.zipCode;

    newDriver.driver_notes = notesInfo.driverNotes;
    newDriver.vehicle_specific_notes = notesInfo.vehicleNotes;

    newDriver.emergency_contact_name = emergencyInfo.emergencyName;
    newDriver.emergency_contact_phone = emergencyInfo.emergencyPhone;

    newDriver.insurance_company = driverInfo.insuranceCompany;

    console.log('insurance:');
    console.log(driverInfo.insuranceExpiration.toString());
    newDriver.insurance_expiration = getDatestringFromDate(
      driverInfo.insuranceExpiration
    );
    console.log(newDriver.insurance_expiration);

    newDriver.drivers_license_expiration = getDatestringFromDate(
      driverInfo.licenseExpiration
    );
    newDriver.vehicle_type_id = getVehicleTypeIdFromName(
      driverInfo.vehicleType
    );
    newDriver.vehicle_high_step_restriction =
      driverInfo.vehicleHighStepRestriction;
    newDriver.vehicle_passenger_limit = driverInfo.vehiclePassengerLimit;

    try {
      const token = userToken;
      const successfulUpdate = await updateDriverProfile(
        userId,
        token,
        newDriver,
        newUser
      );
      setBtnSaving(false);
      if (successfulUpdate) {
        navigate('/profile');
      } else {
        setFailure(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (!driver || !user) {
    return null;
  }

  return (
    <PageWrapper>
      <PageTitle title="Update Profile" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabIndex}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {tabComponents.map((sectionObj, index) => (
            <Tab key={`tab-${index}`} label={sectionObj.header} />
          ))}
        </Tabs>
        {tabComponents.map((sectionObj, index) => (
          <TabPanel key={`tab-content-${index}`} value={tabIndex} index={index}>
            {sectionObj.component}
            <Box sx={{ display: 'flex', marginTop: '20px' }}>
              <Button
                variant="contained"
                onClick={handleUpdateDriverProfile}
                endIcon={
                  btnSaving ? (
                    <CircularProgress size="0.9em" sx={{ color: 'white' }} />
                  ) : null
                }
              >
                Save
              </Button>
              <Box sx={{ flex: 1 }}></Box>
              {tabIndex > 0 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    moveTabBackward(tabIndex);
                  }}
                  sx={{ marginRight: '10px' }}
                >
                  Prev
                </Button>
              )}
              {tabIndex < tabComponents.length - 1 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    moveTabForward(tabIndex);
                  }}
                >
                  Next
                </Button>
              )}
            </Box>
          </TabPanel>
        ))}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={failure}
        autoHideDuration={6000}
        onClose={() => setFailure(false)}
        sx={{ marginTop: 10 }}
      >
        <Alert
          severity="error"
          sx={{ width: '100%', padding: '20px 30px', fontWeight: 'bold' }}
          onClose={() => setFailure(false)}
        >
          Could not update profile. Please contact Neighbor Ride for support.
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
}
