import { useContext, useState } from 'react';

import { Alert, Box, Button, Grid, Snackbar } from '@mui/material';

import { Header, Subheader, KeyValue } from '../../../components/BoxComponents';
import { AdditionalPassenger } from '../../../dbconnector/rides';
import { AuthState } from '../../../models/auth_state';
import { BasicClient, IDriverAddress } from '../../../models/client_profile';
import { FirebaseRide } from '../../../models/rides';
import { UserContext } from '../../../models/user_context';
import {
  capFirstLetter,
  convertMilitaryToAMPMTime,
  getPrettyDate,
} from '../../utils';

type RideInfoPaneProps = {
  ride: FirebaseRide;
  client: BasicClient;
  driver?: IDriverAddress;
  additionalPassengersInfo: AdditionalPassenger[];
};

export function RideInfoPane(props: RideInfoPaneProps) {
  const { authState } = useContext(UserContext);
  const { ride, client, driver, additionalPassengersInfo } = props;
  const driverName = `${ride.driver_first_name} ${ride.driver_last_name}`;
  const BASE_MAPS_URL = 'https://www.google.com/maps/dir/';
  const addressAux = (address: string) =>
    address.replace(/(Suite|Unit)\s+(\d+)/, '');
  const fullPickupAddress = `${addressAux(ride.pickup_address)}, ${
    ride.pickup_city
  }, ${ride.pickup_state} ${ride.pickup_zip}`;
  const fullDestinationAddress = `${addressAux(ride.destination_address)}, ${
    ride.destination_city
  }, ${ride.destination_state} ${ride.destination_zip}`;
  const clientToDestinationLink = `${
    BASE_MAPS_URL + fullPickupAddress
  }/${fullDestinationAddress}`;
  const destinationToClientLink = `${
    BASE_MAPS_URL + fullDestinationAddress
  }/${fullPickupAddress}`;

  const additionalPassengers = [];
  if (ride.additional_passenger_1) {
    additionalPassengers.push(ride.additional_passenger_1);
  }
  if (ride.additional_passenger_2) {
    additionalPassengers.push(ride.additional_passenger_2);
  }
  if (ride.additional_passenger_3) {
    additionalPassengers.push(ride.additional_passenger_3);
  }

  let driverToClient = '';
  let clientToDriver = '';
  let destToDriver = '';
  let driverToDest = '';
  if (driver) {
    const driverAddress = `${addressAux(driver.address1)}, ${driver.city}, ${
      driver.state
    }, ${driver.zip}`;
    driverToClient = `${BASE_MAPS_URL}${driverAddress}/${fullPickupAddress}`;
    clientToDriver = `${BASE_MAPS_URL}${fullPickupAddress}/${driverAddress}`;
    destToDriver = `${BASE_MAPS_URL}${fullDestinationAddress}/${driverAddress}`;
    driverToDest = `${BASE_MAPS_URL}${driverAddress}/${fullDestinationAddress}`;
  }

  const [failure, setFailure] = useState(false);

  const displayMapAlert = (refersToDriver: boolean) => {
    if (refersToDriver && !driver) {
      setFailure(true);
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid sx={sectionSX} item xs={12} sm={5.5}>
        <Header header={'Ride Information'} />
        <KeyValue
          inputKey={'Ride Information'}
          value={`${ride.client_name ?? '(No Client Name)'} on ${
            ride.pickup_date ?? '(No Pickup Date)'
          }`}
        />
        <KeyValue inputKey={'Driver'} value={driverName ?? '(No Driver)'} />
        <KeyValue
          inputKey={'Trip Direction'}
          value={capFirstLetter(ride.trip_direction) ?? '(No Trip Direction)'}
        />
        <KeyValue
          inputKey={'Trip Status'}
          value={capFirstLetter(ride.trip_status)}
        />
        <KeyValue
          inputKey={'Purpose'}
          value={capFirstLetter(ride.purpose) ?? '(No Driver)'}
        />
        <KeyValue
          inputKey={'Ride Type'}
          value={capFirstLetter(ride.ride_type) ?? '(No Ride Type)'}
        />
        <KeyValue
          inputKey={'Group Code'}
          value={capFirstLetter(ride.GroupID) ?? '(No Group Code)'}
        />
      </Grid>
      <Grid sx={sectionSX} item xs={12} sm={5.5}>
        <Header header={'Passenger Information'} />
        <Box>
          <KeyValue
            inputKey={'Number of Passengers'}
            value={(1 + Number(ride.number_additional_passengers)).toString()}
          />
          <KeyValue inputKey={'Primary Passenger'} value={ride.client_name} />
          <KeyValue
            inputKey={'Additional Passengers'}
            value={additionalPassengers.filter((a) => a).join(', ')}
          />
        </Box>
      </Grid>
      <Grid sx={sectionSX} item xs={12}>
        <Header header={'Pickup Information'} />
        <Grid container justifyContent="space-between">
          <Grid item sm={5.5}>
            <Subheader subheader={'Date and Time'} />
            <KeyValue
              inputKey={'Date'}
              value={
                ride.pickup_date
                  ? getPrettyDate(ride.pickup_date)
                  : '(No Pickup Date)'
              }
            />
            <KeyValue
              inputKey={'Pickup Time'}
              value={
                ride.pickup_time
                  ? convertMilitaryToAMPMTime(ride.pickup_time)
                  : '(No Pickup Time)'
              }
            />
            <KeyValue
              inputKey={'Appointment Time'}
              value={
                ride.appointment_time
                  ? convertMilitaryToAMPMTime(ride.appointment_time)
                  : '(No Appointment Time)'
              }
            />
            <KeyValue
              inputKey={'Return Pickup Time'}
              value={
                ride.return_pickup_time
                  ? convertMilitaryToAMPMTime(ride.return_pickup_time)
                  : '(No Return Pickup Time)'
              }
            />
            <KeyValue
              inputKey={'Additional Stop'}
              value={ride.destination_2_duration ?? ''}
            />
          </Grid>
          <Grid item sm={5.5}>
            <Subheader subheader={'Address'} />
            <KeyValue
              inputKey={'Name'}
              value={
                ride.trip_direction_id === '21'
                  ? ride.destination_name
                  : ride.client_name
              }
            />
            <KeyValue
              inputKey={'Address'}
              value={
                ride.trip_direction_id === '21'
                  ? ride.destination_address
                  : ride.pickup_address
              }
            />
            <KeyValue
              inputKey={'Development'}
              value={
                ride.trip_direction_id === '21' ? '' : ride.pickup_development
              }
            />
            <KeyValue
              inputKey={'City, State, Zipcode'}
              value={
                ride.trip_direction_id === '21'
                  ? `${ride.destination_city}, ${ride.destination_state}, ${ride.destination_zip}`
                  : `${ride.pickup_city}, ${ride.pickup_state}, ${ride.pickup_zip}`
              }
            />
            <KeyValue
              inputKey={'Phone'}
              value={
                ride.trip_direction_id === '21'
                  ? ride.destination_phone
                  : ride.pickup_phone
              }
            />
            <KeyValue
              inputKey={'Alternate Phone'}
              value={ride.trip_direction_id === '21' ? '' : client.cell_phone}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={sectionSX} item xs={12}>
        <Header header={'Destination Information'} />
        <Grid container justifyContent="space-between">
          <Grid item sm={5.5}>
            <Subheader subheader={'Primary Destination'} />
            <KeyValue
              inputKey={'Name'}
              value={
                ride.trip_direction_id === '21'
                  ? ride.client_name
                  : ride.destination_name
              }
            />
            <KeyValue
              inputKey={'Address'}
              value={
                ride.trip_direction_id === '21'
                  ? ride.pickup_address
                  : ride.destination_address
              }
            />
            <KeyValue
              inputKey={'Development'}
              value={
                ride.trip_direction_id === '21' ? ride.pickup_development : ''
              }
            />
            <KeyValue
              inputKey={'City, State, Zipcode'}
              value={
                ride.trip_direction_id === '21'
                  ? `${ride.pickup_city}, ${ride.pickup_state}, ${ride.pickup_zip}`
                  : `${ride.destination_city}, ${ride.destination_state}, ${ride.destination_zip}`
              }
            />
            <KeyValue
              inputKey={'Phone'}
              value={
                ride.trip_direction_id === '21'
                  ? ride.pickup_phone
                  : ride.destination_phone
              }
            />
            <KeyValue
              inputKey={'Alternate Phone'}
              value={ride.trip_direction_id === '21' ? client.cell_phone : ''}
            />
          </Grid>
          <Grid item sm={5.5}>
            <Subheader subheader={'Additional Destination'} />
            <KeyValue inputKey={'Name'} value={ride.destination_2_name ?? ''} />
            <KeyValue
              inputKey={'Address'}
              value={ride.destination_2_address ?? ''}
            />
            <KeyValue
              inputKey={'City, State, Zipcode'}
              value={`${ride.destination_2_city ?? ''}, ${
                ride.destination_2_state ?? ''
              }, ${ride.destination_2_zip ?? ''}`}
            />
            <KeyValue
              inputKey={'Additional Info'}
              value={ride.destination_2_info ?? ''}
            />
            <KeyValue
              inputKey={'Duration'}
              value={`${ride.destination_2_duration ?? '?'} min`}
            />
          </Grid>
        </Grid>
      </Grid>
      {authState === AuthState.Driver && (
        <Grid sx={{ ...sectionSX, displayPrint: 'none' }} item xs={12}>
          <Header header={'Ride Directions'} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingX: '10px',
            }}
          >
            {(ride.trip_direction_id === '1' ||
              ride.trip_direction_id === '11') && (
              <>
                <Button
                  sx={{ marginTop: '10px' }}
                  variant="contained"
                  href={driverToClient.replace('#', '%23')}
                  onClick={() => displayMapAlert(true)}
                  target="_blank"
                >
                  Driver to Client
                </Button>
                <Button
                  sx={{ marginTop: '10px' }}
                  variant="contained"
                  href={clientToDestinationLink.replace('#', '%23')}
                  onClick={() => displayMapAlert(false)}
                  target="_blank"
                >
                  Client to {ride.destination_name}
                </Button>
              </>
            )}
            {ride.trip_direction_id === '11' && (
              <Button
                sx={{ marginTop: '10px' }}
                variant="contained"
                href={destToDriver.replace('#', '%23')}
                onClick={() => displayMapAlert(true)}
                target="_blank"
              >
                {ride.destination_name} to Driver
              </Button>
            )}
            {ride.trip_direction_id === '21' && (
              <>
                <Button
                  sx={{ marginTop: '10px' }}
                  variant="contained"
                  href={driverToDest.replace('#', '%23')}
                  onClick={() => displayMapAlert(true)}
                  target="_blank"
                >
                  Driver to {ride.destination_name}
                </Button>
              </>
            )}
            {(ride.trip_direction_id === '1' ||
              ride.trip_direction_id === '21') && (
              <>
                <Button
                  sx={{ marginTop: '10px' }}
                  variant="contained"
                  href={destinationToClientLink.replace('#', '%23')}
                  onClick={() => displayMapAlert(false)}
                  target="_blank"
                >
                  {ride.destination_name} to Client
                </Button>
                <Button
                  sx={{ marginTop: '10px' }}
                  variant="contained"
                  href={clientToDriver.replace('#', '%23')}
                  onClick={() => displayMapAlert(true)}
                  target="_blank"
                >
                  Client to Driver
                </Button>
              </>
            )}
          </Box>
        </Grid>
      )}
      <Grid sx={sectionSX} item xs={12} sm={5.5}>
        <Header header={'Health Information'} />
        <KeyValue
          inputKey={'Health Issues'}
          value={client.health_issues ?? ''}
        />
      </Grid>
      <Grid sx={sectionSX} item xs={12} sm={5.5}>
        <Header header={'Trip Cost Information'} />
        <KeyValue
          inputKey={'Distance'}
          value={`${(Math.floor(Number(ride.distance) * 10) / 10).toFixed(
            1
          )} miles`}
        />
        <KeyValue inputKey={'Cost'} value={`$${ride.cost}`} />
      </Grid>
      <Grid sx={sectionSX} item xs={12} sm={5.5}>
        <Header header={'Emergency Contact Information - Primary Contact'} />
        <KeyValue
          inputKey={'Name'}
          value={client.emergency_contact_name ?? ''}
        />
        <KeyValue
          inputKey={'Relationship'}
          value={client.emergency_contact_relationship ?? ''}
        />
        <KeyValue
          inputKey={'Phone'}
          value={client.emergency_contact_phone ?? ''}
        />
      </Grid>
      <Grid sx={sectionSX} item xs={12} sm={5.5}>
        <Header header={'Emergency Contact Information - Additional Contact'} />
        <KeyValue
          inputKey={'Name'}
          value={client.emergency_contact_2_name ?? ''}
        />
        <KeyValue
          inputKey={'Relationship'}
          value={client.emergency_contact_2_relationship ?? ''}
        />
        <KeyValue
          inputKey={'Phone'}
          value={client.emergency_contact_2_phone ?? ''}
        />
      </Grid>
      {additionalPassengersInfo.map((passenger, index, array) => (
        <Grid
          key={index}
          sx={sectionSX}
          item
          xs={12}
          sm={array.length === 1 ? 12 : array.length === 2 ? 5.5 : 3}
        >
          <Header header={`Additional Passenger ${index + 1}`} />
          <KeyValue
            inputKey={'Name'}
            value={`${passenger.first_name || ''} ${passenger.last_name || ''}`}
          />
          <KeyValue inputKey={'Email'} value={passenger.email || ''} />
          <KeyValue
            inputKey={'Emergency Contact Name'}
            value={passenger.emergency_contact_name || ''}
          />
          <KeyValue
            inputKey={'Emergency Contact Phone'}
            value={passenger.emergency_contact_phone || ''}
          />
        </Grid>
      ))}
      <Grid sx={sectionSX} item xs={12} sm={5.5}>
        <Header header={'Trip Notes'} />
        <KeyValue inputKey={'Trip Notes'} value={ride.client_notes} />
      </Grid>
      <Grid sx={sectionSX} item xs={12} sm={5.5}>
        <Header header={'Client Notes'} />
        <KeyValue inputKey={'Client Notes'} value={client.client_notes} />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={failure}
        autoHideDuration={6000}
        onClose={() => setFailure(false)}
        sx={{ marginTop: 10 }}
      >
        <Alert
          severity="error"
          sx={{ width: '100%', padding: '20px 30px', fontWeight: 'bold' }}
          onClose={() => setFailure(false)}
        >
          Driver not assigned.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

const sectionSX = {
  border: 1,
  marginBottom: '20px',
  paddingBottom: '5px',
};
