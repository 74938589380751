import { useContext } from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../constants';
import { AuthState } from '../../models/auth_state';
import { UserContext } from '../../models/user_context';
import {
  WhiteTextButton,
  WhitePersonIcon,
  WhiteLogoutIcon,
} from '../WhiteComponents';
import NavigationBar from './NavigationBar';

function TopAccountBar() {
  const navigate = useNavigate();
  const { authState, logOut, userFullName, isMobile } = useContext(UserContext);

  const navToProfile = () => {
    if (authState !== AuthState.Liaison) {
      navigate('/profile');
    }
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: Colors.mainColor,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: 'flex' }}
          >
            <img
              src="/nr_logo_modern_white.svg"
              alt="Neighbor Ride"
              height={isMobile ? '40px' : '50px'}
              style={{ cursor: 'pointer' }}
              onClick={() => window.open('https://www.neighborride.org/')}
            />
            {authState === AuthState.Liaison && !isMobile && (
              <div
                style={{
                  marginLeft: 20,
                  paddingRight: 10,
                  fontStyle: 'italic',
                  color: '#6ede12',
                  fontSize: 35,
                  fontWeight: 'bold',
                  height: 50,
                }}
              >
                ASSIST
              </div>
            )}
          </Typography>
          <Box sx={{ flex: 1 }}></Box>
          {authState === AuthState.NotAuthenticated && (
            <Box sx={{ display: 'flex' }}>
              {isMobile ? (
                <AttachMoneyIcon
                  style={{ padding: '5px' }}
                  onClick={() => navigate('/make-payment')}
                />
              ) : (
                <WhiteTextButton
                  variant="outlined"
                  endIcon={<AttachMoneyIcon />}
                  onClick={() => navigate('/make-payment')}
                >
                  Make payment
                </WhiteTextButton>
              )}
              <Box sx={{ width: 10 }} />
              {isMobile ? (
                <WhitePersonIcon
                  style={{ padding: '5px' }}
                  onClick={() => navigate('/login')}
                />
              ) : (
                <WhiteTextButton
                  variant="outlined"
                  endIcon={<WhitePersonIcon />}
                  onClick={() => navigate('/login')}
                >
                  Login
                </WhiteTextButton>
              )}
            </Box>
          )}
          {authState !== AuthState.NotAuthenticated && !isMobile && (
            <span style={{ marginRight: '1%' }}>{userFullName}</span>
          )}
          {authState !== AuthState.NotAuthenticated &&
            authState !== AuthState.Liaison &&
            (isMobile ? (
              <WhitePersonIcon
                onClick={navToProfile}
                style={{ marginRight: '20px', padding: '5px' }}
              />
            ) : (
              <WhiteTextButton
                variant="outlined"
                sx={{ marginRight: '1%' }}
                endIcon={<WhitePersonIcon />}
                onClick={navToProfile}
              >
                Profile
              </WhiteTextButton>
            ))}
          {authState !== AuthState.NotAuthenticated &&
            (isMobile ? (
              <WhiteLogoutIcon onClick={logOut} style={{ padding: '5px' }} />
            ) : (
              <WhiteTextButton
                variant="outlined"
                endIcon={<WhiteLogoutIcon />}
                onClick={logOut}
              >
                Logout
              </WhiteTextButton>
            ))}
        </Toolbar>
        <NavigationBar />
      </Container>
    </AppBar>
  );
}

export default TopAccountBar;
