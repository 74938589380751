/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { SxProps, TextField } from '@mui/material';

interface IDateInput {
  name: string;
  label: string;
  date: string;
  setDate: (newDate: string) => void;
  sx?: SxProps;
}

export default function DateInput({
  name,
  label,
  date,
  setDate,
  sx,
}: IDateInput) {
  const previousDate = useRef('');

  useEffect(() => {
    if (
      (previousDate.current.length === 1 && date.length === 2) ||
      (previousDate.current.length === 4 && date.length === 5)
    ) {
      setDate(`${date}/`);
    }
    if (
      (previousDate.current.length === 6 && date.length === 5) ||
      (previousDate.current.length === 3 && date.length === 2)
    ) {
      setDate(date.slice(0, date.length - 1));
    }
  }, [date]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    previousDate.current = date;
    setDate(event.target.value);
  };

  const allowOnlyNumbers = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      !event.key.match(/[0-9]/) &&
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault();
    }
  };

  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      onChange={handleDateChange}
      onKeyDown={allowOnlyNumbers}
      value={date}
      inputProps={{ maxLength: 10 }}
      sx={sx}
    />
  );
}
