import { useContext } from 'react';

import { AuthState } from '../../models/auth_state';
import { UserContext } from '../../models/user_context';
import { ClientRideHistoryPage } from './components/ClientRideHistoryPage';
import { DriverRideHistoryPage } from './components/DriverRideHistoryPage';

export function RideHistoryPage() {
  const { authState } = useContext(UserContext);

  if (authState === AuthState.Client) {
    return <ClientRideHistoryPage />;
  }

  if (authState === AuthState.Driver) {
    return <DriverRideHistoryPage />;
  }

  return null;
}
