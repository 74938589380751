import { useContext } from 'react';

import { AuthState } from '../../models/auth_state';
import { UserContext } from '../../models/user_context';
import ClientProfile from './ClientProfilePage';
import DriverProfile from './DriverProfilePage';

export default function ProfilePage() {
  const { authState } = useContext(UserContext);

  if (authState === AuthState.Client) {
    return <ClientProfile />;
  } else if (authState === AuthState.Driver) {
    return <DriverProfile />;
  }

  return null;
}
