import React, { useContext, useEffect, useState } from 'react';

import {
  Grid,
  Box,
  Paper,
  Stack,
  Button,
  Divider,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useNavigate, useParams } from 'react-router-dom';

import Dropdown from '../../components/Dropdown';
import MonthRides from '../../components/MonthRides/MonthRides';
import { PageWrapper, PageTitle } from '../../components/PageComponents';
import { getLocations, requestARide } from '../../dbconnector/rides';
import { FirebaseLocation, LocationsGroup } from '../../models/location';
import { RideRequest } from '../../models/rides';
import { UserContext } from '../../models/user_context';
import { BASE_API_URL } from '../../models/utils';
import { convertAMPMtoMilitary, getNeighborRideDate } from '../utils';
import { tripDirections } from './helpers';
import Location from './Location';
import NewLocation from './NewLocation';
import { styles } from './RequestRidePages.styles';

const tripPurposesMap: Map<string, string> = new Map();
tripPurposesMap.set('1', 'Dental');
tripPurposesMap.set('2', 'Exercise');
tripPurposesMap.set('3', 'Medical');
tripPurposesMap.set('4', 'Personal Business');
tripPurposesMap.set('5', 'Personal Care');
tripPurposesMap.set('6', 'Rehabilitation');
tripPurposesMap.set('7', 'Religious Servs.');
tripPurposesMap.set('8', 'Shopping');
tripPurposesMap.set('9', 'Social');
tripPurposesMap.set('10', 'Volunteer');
tripPurposesMap.set('11', 'Work');
tripPurposesMap.set('12', 'Medical/Shopping');
tripPurposesMap.set('13', 'Education');
tripPurposesMap.set('14', 'Personal');
tripPurposesMap.set('15', 'Accompanied Grocery Shopping');
tripPurposesMap.set('16', 'Vision');

const tripPurposesList: { id: string; purpose: string }[] = [];
tripPurposesMap.forEach((purpose_1, id_1) =>
  tripPurposesList.push({ id: id_1, purpose: purpose_1 })
);

export default function RequestRidePage() {
  const navigate = useNavigate();
  const urlParams = useParams();

  const { userId, userToken, verifySession } = useContext(UserContext);

  const clientId = urlParams.clientId || userId;

  const [tripDirectionId, setTripDirectionId] = useState('1');
  const [requestErrorMessage, setRequestErrorMessage] = useState('');
  const [rideMileage, setRideMileage] = useState(0);
  const [distanceLoading, setDistanceLoading] = useState(false);

  // Destination state variables
  const [tripPurposeId, setTripPurposeId] = useState<string>('');
  const [allLocationsMap, setAllLocationsMap] = useState<
    Map<string, FirebaseLocation>
  >(new Map());
  const [previousClientLocationsMap, setPreviousClientLocationsMap] = useState<
    Map<string, FirebaseLocation>
  >(new Map());
  const [locationId, setLocationId] = useState<string>('');
  const [previousLocationId, setPreviousLocationId] = useState<string>('');
  const [newLocation, setNewLocation] = useState<FirebaseLocation>();
  const [tab, setTab] = useState<number>(0);

  // Pickup times + date
  const [pickupDate, setPickupDate] = useState<Date | null>(new Date());
  const [pickupTime, setPickupTime] = useState<string>('');
  const [appointmentTime, setAppointmentTime] = useState<string>('');
  const [returnTime, setReturnTime] = useState<string>('');

  const [addPassengersQty, setAddPassengersQty] = useState(0);
  const [passenger1, setPassenger1] = useState('');
  const [passenger2, setPassenger2] = useState('');
  const [passenger3, setPassenger3] = useState('');

  const [notes, setNotes] = useState<string>('');

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const possibleTimes = [];
  let hr = 12;
  for (let i = 0; i < 24; i++) {
    if (i >= 12) {
      possibleTimes.push(`${hr}:00 PM`);
      possibleTimes.push(`${hr}:15 PM`);
      possibleTimes.push(`${hr}:30 PM`);
      possibleTimes.push(`${hr}:45 PM`);
    } else {
      possibleTimes.push(`${hr}:00 AM`);
      possibleTimes.push(`${hr}:15 AM`);
      possibleTimes.push(`${hr}:30 AM`);
      possibleTimes.push(`${hr}:45 AM`);
    }
    hr = (hr + 1) % 12;
    hr = hr == 0 ? 12 : hr;
  }

  const previousClientLocations: FirebaseLocation[] = [];
  previousClientLocationsMap.forEach((val: FirebaseLocation) => {
    previousClientLocations.push(val);
  });

  const allLocations: FirebaseLocation[] = [];
  allLocationsMap.forEach((val: FirebaseLocation) => {
    allLocations.push(val);
  });

  const [locationsAvailable, setLocationsAvailable] = useState(true);

  useEffect(() => {
    fetch(`${BASE_API_URL}/clients-list/${clientId}`, {
      headers: { Authorization: userToken },
    }).then(async (res) => {
      if (res.status === 200) {
        const aux = await res.json();
        console.log(aux);
      } else {
        verifySession(res.status);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  useEffect(() => {
    if (locationId) setDistanceLoading(true);
    fetch(`${BASE_API_URL}/ride-distance/${clientId}/${locationId}`, {
      headers: { Authorization: userToken },
    }).then(async (res) => {
      if (res.status === 200) {
        const { mileage } = await res.json();
        setRideMileage(mileage);
        setDistanceLoading(false);
      } else {
        verifySession(res.status);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, userToken]);

  useEffect(() => {
    const downloadLocations = async () => {
      const uid = clientId;
      const token = userToken;
      try {
        const locationsGroup: LocationsGroup = await getLocations(uid, token);
        const allLocationsData: FirebaseLocation[] =
          locationsGroup.allLocations ?? [];
        const previouslyUsedByClient: FirebaseLocation[] =
          locationsGroup.previouslyUsedByClient;
        const newAllLocationsMap = new Map<string, FirebaseLocation>();
        allLocationsData.forEach((loc: FirebaseLocation) => {
          newAllLocationsMap.set(loc.id, loc);
        });
        const newPreviousClientLocationsMap = new Map<
          string,
          FirebaseLocation
        >();
        previouslyUsedByClient.forEach((loc: FirebaseLocation) => {
          newPreviousClientLocationsMap.set(loc.id, loc);
        });
        setAllLocationsMap(newAllLocationsMap);
        setPreviousClientLocationsMap(newPreviousClientLocationsMap);
      } catch (error) {
        setLocationsAvailable(false);
      }
    };
    downloadLocations();
  }, [clientId, userToken, newLocation]);

  const onSubmitClick = async () => {
    const token = userToken;
    let destinationAddress = '';
    let destinationCity = '';
    let destinationName = '';
    let destinationPhone = '';
    let destinationState = '';
    let destinationZip = '';
    let destinationId = '';

    console.log({ locationId });

    if (
      (tab == 0 && locationId !== '') ||
      (tab == 1 && previousLocationId !== '')
    ) {
      let location;
      if (tab == 0) {
        location = allLocationsMap.get(locationId);
      } else if (tab == 1) {
        location = previousClientLocationsMap.get(previousLocationId);
      }

      destinationAddress = location?.address ?? '';
      destinationCity = location?.city ?? '';
      destinationName = location?.title ?? '';
      destinationPhone = location?.phone ?? '';
      destinationState = location?.state ?? '';
      destinationZip = location?.zipcode ?? '';
      destinationId = location?.id ?? '';
    }

    if (tab === 2) {
      if (
        !newLocation ||
        newLocation.address === '' ||
        newLocation.city === '' ||
        newLocation.state === ''
      ) {
        return;
      }

      destinationAddress = newLocation.address;
      destinationCity = newLocation.city;
      destinationName = newLocation.title;
      destinationPhone = newLocation.phone;
      destinationState = newLocation.state;
      destinationZip = newLocation.zipcode;
      destinationId = '';
    }

    if (
      (returnTime == '' && tripDirectionId === '1') ||
      pickupTime == '' ||
      !pickupDate
    ) {
      return;
    }

    if (tripPurposeId === '') {
      return;
    }

    const rideRequest: RideRequest = {
      additional_passenger_1: addPassengersQty > 0 ? passenger1 : '',
      additional_passenger_2: addPassengersQty > 1 ? passenger2 : '',
      additional_passenger_3: addPassengersQty > 2 ? passenger3 : '',
      appointment_time:
        appointmentTime && appointmentTime != ''
          ? convertAMPMtoMilitary(appointmentTime)
          : null,
      client_id: clientId,
      client_notes: notes,
      destination_address: destinationAddress,
      destination_city: destinationCity,
      destination_location_id: destinationId,
      destination_name: destinationName,
      destination_phone: destinationPhone,
      destination_state: destinationState,
      destination_zip: destinationZip,
      number_additional_passengers: addPassengersQty.toString(),
      pickup_date: getNeighborRideDate(pickupDate),
      pickup_time: convertAMPMtoMilitary(pickupTime),
      purpose_id: tripPurposeId,
      return_pickup_time: returnTime
        ? convertAMPMtoMilitary(returnTime)
        : returnTime,
      ride_type_id: '0',
      trip_direction_id: tripDirectionId,
    };

    console.log({ rideRequest });

    const response = await requestARide(clientId, token, rideRequest);
    if (response.status === 200) {
      navigate('/dashboard');
    } else {
      const json = await response.json();
      if (json) {
        setRequestErrorMessage(json.message);
      } else {
        setRequestErrorMessage(
          'Could not request ride, please contact a Ride Coordinator.'
        );
      }
      setSnackbarOpen(true);
    }
  };

  // if (!allLocations || !previousClientLocations) {
  //   return (
  //     <PageWrapper>
  //       <PageTitle title="Create a Ride" />
  //     </PageWrapper>
  //   );
  // }

  return (
    <PageWrapper>
      <PageTitle title="Create a Ride" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <MonthRides userId={clientId} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Box sx={styles.container} component={Paper}>
            <h2>Date {'&'} Destination</h2>
            <h3>Pick-up Date</h3>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              value={pickupDate}
              onChange={setPickupDate}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            <Divider sx={{ margin: '5% 0 5% 0' }} />
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              justifyContent="flex-end"
            >
              <Button
                onClick={() => {
                  setTab(0);
                }}
                variant="contained"
              >
                Location
              </Button>
              <Button
                onClick={() => {
                  setTab(1);
                }}
                variant="contained"
              >
                Previously Used By Client
              </Button>
              <Button
                onClick={() => {
                  setTab(2);
                }}
                variant="contained"
              >
                New Location
              </Button>
            </Stack>
            {tab == 0 && (
              <Location
                locationsAvailable={locationsAvailable}
                locations={allLocations}
                setLocationId={setLocationId}
                label="Location"
                rideMileage={rideMileage}
                distanceLoading={distanceLoading}
              />
            )}
            {tab == 1 && (
              <Location
                locationsAvailable={locationsAvailable}
                locations={previousClientLocations}
                setLocationId={setPreviousLocationId}
                label="Previous Location"
                rideMileage={rideMileage}
                distanceLoading={distanceLoading}
              />
            )}
            {tab == 2 && (
              <NewLocation setNewLocation={setNewLocation} setTab={setTab} />
            )}
            <Divider sx={{ margin: '5% 0 5% 0' }} />
            <h3>Trip Purpose</h3>
            <TextField
              value={tripPurposeId}
              onChange={(e) => setTripPurposeId(e.target.value)}
              fullWidth={true}
              select
            >
              {tripPurposesList.map((val, index) => {
                return (
                  <MenuItem key={index} value={val.id}>
                    {val.purpose}
                  </MenuItem>
                );
              })}
            </TextField>
            <h3>Ride Type</h3>
            <TextField value="0" fullWidth select>
              <MenuItem key="0" value="0">
                Standard
              </MenuItem>
            </TextField>
          </Box>
          <Box sx={styles.container} component={Paper}>
            <h2>Ride Times</h2>
            <h3>Trip Direction</h3>
            <TextField
              value={tripDirectionId}
              onChange={(e) => setTripDirectionId(e.target.value)}
              select
              fullWidth
            >
              {tripDirections.map((tripDirection) => (
                <MenuItem key={tripDirection.id} value={tripDirection.id}>
                  {tripDirection.title}
                </MenuItem>
              ))}
            </TextField>
            <h3>Pick-up Time (hh:mm)</h3>
            <Dropdown
              options={possibleTimes}
              displayOption={pickupTime}
              setDisplayOption={setPickupTime}
              fullWidth
            />
            <h3>Appointment Time (hh:mm) *Optional</h3>
            <Dropdown
              options={possibleTimes}
              displayOption={appointmentTime}
              setDisplayOption={setAppointmentTime}
              fullWidth
            />
            {tripDirectionId === '1' && (
              <>
                <h3>Return Pick-up Time (hh:mm)</h3>
                <Dropdown
                  options={possibleTimes}
                  displayOption={returnTime}
                  setDisplayOption={setReturnTime}
                  fullWidth
                />
              </>
            )}
          </Box>
          <Box sx={styles.container} component={Paper}>
            <h2>Additional Passengers</h2>
            <div>
              Number of additional passengers does not include the client
              (primary passenger). If just the primary passenger, then enter 0.
            </div>
            <div>Office will need to confirm additional passengers.</div>
            <TextField
              value={addPassengersQty}
              onChange={(e) => setAddPassengersQty(Number(e.target.value))}
              sx={{ marginY: '20px' }}
              InputProps={{ type: 'number', inputProps: { min: 0, max: 3 } }}
              fullWidth
            />
            {addPassengersQty >= 1 && (
              <TextField
                value={passenger1}
                onChange={(e) => setPassenger1(e.target.value)}
                sx={{ marginBottom: '20px' }}
                label="Passenger 1"
                fullWidth
              />
            )}
            {addPassengersQty >= 2 && (
              <TextField
                value={passenger2}
                onChange={(e) => setPassenger2(e.target.value)}
                sx={{ marginBottom: '20px' }}
                label="Passenger 2"
                fullWidth
              />
            )}
            {addPassengersQty >= 3 && (
              <TextField
                value={passenger3}
                onChange={(e) => setPassenger3(e.target.value)}
                sx={{ marginBottom: '20px' }}
                label="Passenger 3"
                fullWidth
              />
            )}
          </Box>

          <Box sx={styles.container} component={Paper}>
            <h2>Extra Notes</h2>
            <h3>Trip Specific Notes (From the Client)</h3>
            <TextField
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              multiline={true}
              minRows={10}
              maxRows={20}
              fullWidth
            />
          </Box>
          <Button variant="contained" onClick={onSubmitClick}>
            Submit Request
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
          onClose={() => setSnackbarOpen(false)}
        >
          {requestErrorMessage}
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
}
