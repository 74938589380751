import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { NavLink, Link } from 'react-router-dom';

import { Colors } from '../../constants';
import { acceptRide, confirmAcceptedRide } from '../../dbconnector/rides';
import { FirebaseRide, TableRide } from '../../models/rides';
import { getRandomAlphanumbericString } from '../../models/utils';
import { capFirstLetter } from '../utils';

const commonProperties: GridColDef = {
  align: 'center',
  headerAlign: 'center',
  disableColumnMenu: true,
  field: '',
};

export function driverDashboardColumns(
  userId: string,
  userToken: string,
  isAccepted: boolean,
  setSuccess: (bool: boolean) => void = () => {},
  reloadRides: () => void = () => {},
  disabled = '',
  setDisabled: (rowId: string) => void = () => {}
): GridColDef[] {
  return [
    {
      ...commonProperties,
      field: 'date',
      headerName: 'Date',
      width: 200,
      renderCell: (params) => moment(params.value).format('DD-MMM-YYYY (ddd)'),
    },
    {
      ...commonProperties,
      field: 'client',
      headerName: 'Client',
      flex: 1 / 3,
      minWidth: 180,
    },
    {
      ...commonProperties,
      field: 'clientZip',
      headerName: 'Client Zip',
      renderCell: (params) => params.row.zipCodes[0],
    },
    {
      ...commonProperties,
      field: 'tripDirection',
      headerName: 'Trip Direction',
      flex: 1 / 3,
      minWidth: 180,
    },
    {
      ...commonProperties,
      field: 'purpose',
      headerName: 'Purpose',
      width: 150,
    },
    {
      ...commonProperties,
      field: 'destination',
      headerName: 'Destination',
      flex: 1 / 3,
      minWidth: 180,
    },
    {
      ...commonProperties,
      field: 'destZip',
      headerName: 'Dest Zip',
      renderCell: (params) => params.row.zipCodes[1],
    },
    {
      ...commonProperties,
      field: 'pickup',
      headerName: 'Pickup',
      renderCell: (params) => moment(params.value, 'HH:mm:ss').format('h:mm A'),
    },
    {
      ...commonProperties,
      field: 'return',
      headerName: 'Return',
      renderCell: (params) =>
        params.value ? moment(params.value, 'HH:mm:ss').format('h:mm A') : '',
    },
    {
      ...commonProperties,
      field: 'status',
      headerName: 'Status',
    },
    {
      ...commonProperties,
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      width: isAccepted ? 100 : 200,
      renderCell: (params) => {
        const onAcceptClick = async (e: { stopPropagation: () => void }) => {
          setDisabled(params.id as string);
          e.stopPropagation(); // don't select this row after clicking
          const success = await acceptRide(userId, params.row.id, userToken);
          if (success) {
            setSuccess(true);
          }
          reloadRides();
        };

        return (
          <div>
            <NavLink to={`/trips/${params.row.id}`} key={params.row.id}>
              <Button>View</Button>
            </NavLink>
            {!isAccepted && (
              <Button onClick={onAcceptClick} disabled={disabled === params.id}>
                Accept
              </Button>
            )}
          </div>
        );
      },
    },
  ];
}

export function clientDashboardColumns(
  userId: string,
  userToken: string,
  setSuccess: (bool: boolean) => void = () => {},
  setFailure: (bool: boolean) => void = () => {},
  reloadRides: () => void = () => {}
): GridColDef[] {
  return [
    {
      ...commonProperties,
      field: 'date',
      headerName: 'Date',
      width: 180,
      renderCell: (params) => moment(params.value).format('DD-MMM-YYYY (ddd)'),
    },
    {
      ...commonProperties,
      field: 'rideType',
      headerName: 'Ride Type',
    },
    {
      ...commonProperties,
      field: 'client',
      headerName: 'Client',
      flex: 1 / 3,
      minWidth: 180,
    },
    {
      ...commonProperties,
      field: 'tripDirection',
      headerName: 'Trip Direction',
      flex: 1 / 3,
      minWidth: 180,
    },
    {
      ...commonProperties,
      field: 'destination',
      headerName: 'Destination',
      flex: 1 / 3,
      minWidth: 180,
    },
    {
      ...commonProperties,
      field: 'pickup',
      headerName: 'Pickup',
      renderCell: (params) => moment(params.value, 'HH:mm:ss').format('h:mm A'),
    },
    {
      ...commonProperties,
      field: 'appointment',
      headerName: 'Appt',
      renderCell: (params) =>
        params.value ? moment(params.value, 'HH:mm:ss').format('h:mm A') : '',
    },
    {
      ...commonProperties,
      field: 'return',
      headerName: 'Return',
      renderCell: (params) =>
        params.value ? moment(params.value, 'HH:mm:ss').format('h:mm A') : '',
    },
    {
      ...commonProperties,
      field: 'status',
      headerName: 'Status',
    },
    {
      ...commonProperties,
      flex: 1 / 3,
      minWidth: 180,
      field: 'driver',
      headerName: 'Driver',
    },
    {
      ...commonProperties,
      field: 'action',
      headerName: 'Action',
      width: 180,
      renderCell: (params) => {
        const onConfirmClick = async (e: { stopPropagation: () => void }) => {
          e.stopPropagation(); // don't select this row after clicking
          const success = await confirmAcceptedRide(
            userId,
            userToken,
            params.row.id
          );
          if (success) {
            reloadRides();
            setSuccess(true);
          } else {
            setFailure(true);
          }

          return;
        };

        return (
          <div>
            <NavLink to={`/trips/${params.row.id}`} key={params.row.id}>
              <Button>View</Button>
            </NavLink>
            {params.row?.status?.toString().toLowerCase() === 'accepted' ? (
              <Button onClick={onConfirmClick}> Confirm </Button>
            ) : null}
          </div>
        );
      },
    },
  ];
}

export const driverRideHistoryColumns: GridColDef[] = [
  {
    ...commonProperties,
    field: 'date',
    headerName: 'Date',
    width: 200,
    renderCell: (params) =>
      params.value
        ? moment(params.value).format('DD-MMM-YYYY (ddd)')
        : params.value,
  },
  {
    ...commonProperties,
    field: 'destination',
    headerName: 'Destination',
    flex: 1 / 3,
    minWidth: 180,
  },
  {
    ...commonProperties,
    field: 'client',
    headerName: 'Client Name',
    flex: 1 / 3,
    minWidth: 180,
  },
  {
    ...commonProperties,
    field: 'mileage',
    headerName: 'Volunteer Mileage',
    minWidth: 180,
  },
  {
    ...commonProperties,
    field: 'clientAddress',
    headerName: 'Client Address',
    flex: 1 / 3,
    minWidth: 180,
  },
  {
    ...commonProperties,
    field: 'tripStatus',
    headerName: 'Status',
    minWidth: 180,
  },
  {
    ...commonProperties,
    field: 'action',
    headerName: 'Action',
    width: 180,
    renderCell: (params) => {
      if (params.id === 'total') return;
      return (
        <div>
          <NavLink to={`/trips/${params.row.id}`} key={params.row.id}>
            <Button>View</Button>
          </NavLink>
        </div>
      );
    },
  },
];

export const clientRideHistoryColumns: GridColDef[] = [
  {
    ...commonProperties,
    field: 'date',
    headerName: 'Date',
    width: 200,
    renderCell: (params) => moment(params.value).format('DD-MMM-YYYY (ddd)'),
  },
  {
    ...commonProperties,
    field: 'driver',
    headerName: 'Driver',
    minWidth: 180,
    flex: 1 / 2,
  },
  {
    ...commonProperties,
    field: 'destination',
    headerName: 'Destination',
    minWidth: 180,
    flex: 1 / 2,
  },
  {
    ...commonProperties,
    field: 'cost',
    headerName: 'Cost',
  },
  {
    ...commonProperties,
    field: 'action',
    headerName: 'Action',
    sortable: false,
    renderCell: (params) => {
      return (
        <NavLink to={`/trips/${params.row.id}`} key={params.row.id}>
          <Button>View</Button>
        </NavLink>
      );
    },
  },
];

export const convertFirebaseRidesToTableRides = (
  firebaseRides: FirebaseRide[]
): TableRide[] => {
  const tableRides: TableRide[] = [];
  if (firebaseRides) {
    for (let i = 0; i < firebaseRides.length; i++) {
      const tableRide = convertFirebaseRideToTableRide(firebaseRides[i]);
      tableRides.push(tableRide);
    }
  }
  return tableRides;
};

export const convertFirebaseRideToTableRide = (
  firebaseRide: FirebaseRide
): TableRide => {
  return {
    id: firebaseRide.id || getRandomAlphanumbericString(),
    date: firebaseRide.pickup_date,
    rideType: capFirstLetter(firebaseRide.ride_type),
    client: firebaseRide.client_name,
    tripDirection: capFirstLetter(firebaseRide.trip_direction),
    groupCode: firebaseRide.GroupID,
    destination: firebaseRide.destination_name,
    pickup: firebaseRide.pickup_time,
    appointment: firebaseRide.appointment_time,
    return: firebaseRide.return_pickup_time,
    status: capFirstLetter(firebaseRide.trip_status),
    driver: `${firebaseRide.driver_first_name} ${firebaseRide.driver_last_name}`,
    zipCodes: [
      firebaseRide.pickup_zip,
      firebaseRide.destination_zip,
      firebaseRide.destination_2_zip,
    ],
    hsv: firebaseRide.hsv,
    purpose: firebaseRide.purpose,
  };
};

export const rideTypeFilterMap = {
  lastResort: 'Last Resort',
  suggested: 'Suggested',
  allCall: 'All Call',
  pending: 'All Pending',
  standard: 'Standard',
  foodBank: 'Food Bank Delivery',
  loanClosetDelivery: 'Loan Closet Delivery',
  ecFood: 'EC Food Delivery',
  loanClosetReturn: 'Loan Closet Equipment Return',
};

export const getDatestringFromDate = (date: Date): string => {
  /**
   * Input: Date
   * Output: 'YYYY-MM-DD'
   */
  const newDate = new Date(date);
  return moment(newDate).format('YYYY-MM-DD');
};

export const getRideTypeDisplayString = (rideType: string): string => {
  if (
    rideType === 'lastResort' ||
    rideType === 'suggested' ||
    rideType === 'allCall' ||
    rideType === 'pending' ||
    rideType === 'standard' ||
    rideType === 'foodBank' ||
    rideType === 'loanClosetDelivery' ||
    rideType === 'ecFood' ||
    rideType === 'loanClosetReturn'
  ) {
    return rideTypeFilterMap[rideType];
  } else {
    return '';
  }
};

// Liaison

export const ridesColumns: GridColDef[] = [
  {
    ...commonProperties,
    field: 'date',
    headerName: 'Date',
    width: 150,
    renderCell: (params) => moment(params.value).format('DD-MMM-YYYY (ddd)'),
  },
  {
    ...commonProperties,
    field: 'client',
    headerName: 'Client',
    minWidth: 180,
    flex: 1 / 3,
  },
  {
    ...commonProperties,
    field: 'type',
    headerName: 'Type',
  },
  {
    ...commonProperties,
    field: 'dir',
    headerName: 'Direction',
    minWidth: 180,
    flex: 1 / 3,
  },
  {
    ...commonProperties,
    field: 'dest',
    headerName: 'Destination',
    minWidth: 180,
    flex: 1 / 3,
  },
  {
    ...commonProperties,
    field: 'pickup',
    headerName: 'Pickup',
    renderCell: (params) => moment(params.value, 'HH:mm:ss').format('h:mm A'),
  },
  {
    ...commonProperties,
    field: 'return',
    headerName: 'Return',
    renderCell: (params) =>
      params.value ? moment(params.value, 'HH:mm:ss').format('h:mm A') : '',
  },
  {
    ...commonProperties,
    field: 'status',
    headerName: 'Status',
  },
  {
    ...commonProperties,
    field: 'actions',
    headerName: 'Actions',
    width: 80,
    renderCell: (params) => {
      return (
        <Link
          style={{ textDecoration: 'none', color: Colors.mainColor }}
          to={`/trips/${params.id}`}
        >
          View
        </Link>
      );
    },
  },
];

export const membersColumns: GridColDef[] = [
  {
    ...commonProperties,
    field: 'name',
    headerName: 'Name',
    minWidth: 150,
    flex: 1,
  },
  {
    ...commonProperties,
    field: 'actions',
    headerName: 'Actions',
    minWidth: 120,
    renderCell: (params) => {
      return (
        <Link
          style={{ textDecoration: 'none', color: Colors.mainColor }}
          to={`/ride-request/${params.id}`}
        >
          Request Ride
        </Link>
      );
    },
  },
];
