import { useEffect, useState } from 'react';

import { Grid, TextField } from '@mui/material';

import Dropdown from '../../../components/Dropdown';
import { PersonalInfoModel } from '../../../models/client_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  personalInfo: PersonalInfoModel;
  setPersonalInfo: React.Dispatch<React.SetStateAction<PersonalInfoModel>>;
}

export default function Notes(props: InfoProps) {
  const getFirstLetterCapitalString = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const { personalInfo, setPersonalInfo } = props;
  const [language, setLanguage] = useState(
    personalInfo?.primaryLanguage
      ? getFirstLetterCapitalString(personalInfo?.primaryLanguage)
      : ''
  );

  const possibleLanguages = [
    'English',
    'Spanish',
    'Chinese',
    'French',
    'German',
    'Tagalong',
    'Vietnamese',
    'Italian',
    'Korean',
    'Russian',
    'Polish',
  ];

  const paymentsMethods = [
    '',
    'Passenger',
    'Good Neighbor Fund',
    'Passenger - ALICE',
  ];

  const updatePersonalKey = (key: keyof PersonalInfoModel, val: string) => {
    setPersonalInfo((obj: PersonalInfoModel) => {
      const objCopy: PersonalInfoModel = { ...obj };
      objCopy[key] = val;
      return objCopy;
    });
  };

  useEffect(() => {
    if (language) {
      setPersonalInfo((obj: PersonalInfoModel) => {
        const objCopy = { ...obj };
        objCopy.primaryLanguage = getFirstLetterCapitalString(language);
        return objCopy;
      });
    }
  }, [language, setPersonalInfo]);

  useEffect(() => {
    setLanguage(
      personalInfo?.primaryLanguage
        ? getFirstLetterCapitalString(personalInfo?.primaryLanguage)
        : ''
    );
  }, [personalInfo]);

  return (
    <BoxContainer header="Personal Info">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12} md={6}>
          <h4>Primary Language</h4>
          <Dropdown
            options={possibleLanguages}
            displayOption={language}
            setDisplayOption={setLanguage}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <h4>Payment Method</h4>
          <div>{paymentsMethods[Number(personalInfo.paymentMethod)]}</div>
        </Grid>
        <Grid item xs={12}>
          <h4>Health issues</h4>
          <TextField
            value={personalInfo.healthIssues}
            onChange={(e) => updatePersonalKey('healthIssues', e.target.value)}
            multiline
            minRows={10}
            maxRows={10}
            fullWidth
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
