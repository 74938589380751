import { Grid, TextField } from '@mui/material';

import { EmergencyModel } from '../../../models/driver_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  emergencyInfo: EmergencyModel;
  setEmergencyInfo: React.Dispatch<React.SetStateAction<EmergencyModel>>;
}

export default function Contact(props: InfoProps) {
  const { emergencyInfo, setEmergencyInfo } = props;

  const updateEmergencyKey = (key: keyof EmergencyModel, val: string) => {
    setEmergencyInfo((obj: EmergencyModel) => {
      const objCopy: EmergencyModel = { ...obj };
      objCopy[key] = val;
      return objCopy;
    });
  };

  return (
    <BoxContainer header="Emergency">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12} md={6}>
          <h4>Name</h4>
          <TextField
            value={emergencyInfo.emergencyName}
            onChange={(e) =>
              updateEmergencyKey('emergencyName', e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <h4>Phone *</h4>
          <TextField
            value={emergencyInfo.emergencyPhone}
            onChange={(e) =>
              updateEmergencyKey('emergencyPhone', e.target.value)
            }
            fullWidth
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
