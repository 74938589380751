/* eslint-disable @typescript-eslint/no-explicit-any */
import { BasicClient, IDriverAddress } from '../models/client_profile';
import { FirebaseLocation, LocationsGroup } from '../models/location';
import { FirebaseRide, RideRequest } from '../models/rides';
import { BASE_API_URL } from '../models/utils';

export const getClientRides = async (
  uid: string,
  token: string
): Promise<FirebaseRide[]> => {
  const res = await fetch(`${BASE_API_URL}/clientRides`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const rides: FirebaseRide[] = data.rides;
  return rides;
};

export const getPendingCount = async () => {
  const response = await fetch(`${BASE_API_URL}/pending-count`);
  const json = await response.json();
  return json.count;
};

export const getAssignedRides = async (
  uid: string,
  token: string
): Promise<FirebaseRide[]> => {
  const res = await fetch(`${BASE_API_URL}/assignedRides`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const rides: FirebaseRide[] = data.rides;
  return rides;
};

export const cachePendingRides = async () => {
  const cache = await caches.open('pendingRides');
  const keys = await cache.keys();
  if (keys.every((key) => !key.url.includes('/rides-aux'))) {
    cache.add(`${BASE_API_URL}/rides-aux`);
  }
};

export const getPendingRides = async (
  uid: string,
  token: string
): Promise<FirebaseRide[]> => {
  const res = await fetch(`${BASE_API_URL}/rides/${uid}`, {
    headers: { Authorization: token },
  });
  const data = await res.json();
  const rides: FirebaseRide[] = data.rides;

  const cache = await caches.open('pendingRides');
  const keys = await cache.keys();
  if (keys.every((key) => !key.url.includes('/rides-aux'))) {
    await cache.add(`${BASE_API_URL}/rides-aux`);
  }
  const response = await cache.match(`${BASE_API_URL}/rides-aux`);
  const json = await response?.json();

  return rides.map((ride) => ({
    ...ride,
    ride_type: json.rideTypes.find((type: any) => type.id === ride.ride_type_id)
      ?.title,
    purpose: json.ridePurposes.find((pur: any) => pur.id === ride.purpose_id)
      ?.title,
    hsv: json.clientsHSV.includes(ride.client_id) ? 'Yes' : 'No',
  }));
};

export const getPastClientRides = async (
  uid: string,
  token: string
): Promise<FirebaseRide[]> => {
  const res = await fetch(`${BASE_API_URL}/clientPastRides`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  console.log({ data });
  const rides: FirebaseRide[] = data.rides;
  return rides;
};

export const getPastDriverRides = async (
  uid: string,
  token: string
): Promise<FirebaseRide[]> => {
  const res = await fetch(`${BASE_API_URL}/driverPastRides`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const rides: FirebaseRide[] = data.rides;
  return rides;
};

export const getRideAndClientAndDriver = async (
  uid: string,
  token: string,
  rideId: string
): Promise<RideAndClientAndDriver> => {
  const res = await fetch(`${BASE_API_URL}/rideAndClient`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      rideId: rideId,
    }),
  });
  const data = await res.json();
  return data;
};

export interface AdditionalPassenger {
  first_name: string;
  last_name: string;
  email: string;
  emergency_contact_name: string;
  emergency_contact_phone: string;
}

export interface RideAndClientAndDriver {
  ride: FirebaseRide;
  client: BasicClient;
  driverInfo: IDriverAddress;
  additionalPassengers: AdditionalPassenger[];
}

export const getSuggestedRides = async (
  uid: string,
  token: string
): Promise<SuggestedRideGroup> => {
  const res = await fetch(`${BASE_API_URL}/suggestedRides`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });
  const data = await res.json();
  const suggestedRides: SuggestedRideGroup = {
    rideCoordinatorRides: data.rideCoordinatorRides,
    scheduleFittingRides: data.scheduleFittingRides,
    zipcodeRides: data.zipcodeRides,
    clientsLastSixRides: data.clientsLastSixRides,
  };

  // const suggestedRides: SuggestedRideGroup = {
  //   rideCoordinatorRides: [],
  //   scheduleFittingRides: [],
  //   zipcodeRides: [],
  //   clientsLastSixRides: [],
  // };
  return suggestedRides;
};

export interface SuggestedRideGroup {
  rideCoordinatorRides: FirebaseRide[];
  scheduleFittingRides: FirebaseRide[];
  zipcodeRides: FirebaseRide[];
  clientsLastSixRides: FirebaseRide[];
}

export const acceptRide = async (
  uid: string,
  rideId: string,
  token: string
): Promise<boolean> => {
  const res = await fetch(`${BASE_API_URL}/acceptRide/${uid}/${rideId}`, {
    headers: { Authorization: token },
  });
  return res.status === 200;
};

export const confirmAcceptedRide = async (
  uid: string,
  token: string,
  rideId: string
): Promise<boolean> => {
  const res = await fetch(
    `${BASE_API_URL}/confirmAcceptedride/${uid}/${rideId}`,
    { headers: { Authorization: token } }
  );
  return res.status === 200;
};

export const requestARide = async (
  uid: string,
  token: string,
  rideRequest: RideRequest
): Promise<Response> => {
  const res = await fetch(`${BASE_API_URL}/requestRide`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      ride: rideRequest,
    }),
  });
  return res;
};

export const getLocations = async (
  uid: string,
  token: string
): Promise<LocationsGroup> => {
  const res = await fetch(`${BASE_API_URL}/locations`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
    }),
  });

  const data = await res.json();
  console.log({ data });
  const locationsGroup: LocationsGroup = {
    allLocations: data.allLocations,
    previouslyUsedByClient: data.previouslyUsedByClient,
  };

  return locationsGroup;
};

export const addNewLocation = async (
  uid: string,
  token: string,
  location: FirebaseLocation
): Promise<boolean> => {
  const response = await fetch(`${BASE_API_URL}/locations`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: uid,
      token: token,
      location: location,
    }),
  });

  return response.status === 200;
};
