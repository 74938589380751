import { useContext } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { BottomNavigation } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../constants';
import { UserContext } from '../../models/user_context';
import { WhiteBottomNavigationAction } from '../WhiteComponents';

function DriverNavBar() {
  const navigate = useNavigate();
  const navigationValueToPage = (value: number): string => {
    switch (value) {
      case 0:
        return '/ride-history';
      case 1:
        return '/update-schedule';
      case 2:
        return '/dashboard';
      default:
        return '/dashboard';
    }
  };

  const { isMobile } = useContext(UserContext);

  return (
    <BottomNavigation
      showLabels={!isMobile}
      sx={{ backgroundColor: Colors.mainColor }}
      onChange={(event, newValue) => {
        navigate(navigationValueToPage(newValue));
      }}
    >
      <WhiteBottomNavigationAction
        label="HISTORY"
        icon={<DirectionsCarIcon fontSize="large" />}
      />
      <WhiteBottomNavigationAction
        label="SCHEDULE"
        icon={<AccessTimeIcon fontSize="large" />}
      />
      <WhiteBottomNavigationAction
        label="DASHBOARD"
        icon={<DashboardIcon fontSize="large" />}
      />
    </BottomNavigation>
  );
}

export default DriverNavBar;
