import { BASE_API_URL } from '../../models/utils';

export const getGroupCode = async (
  userId: string,
  userToken: string
): Promise<string> => {
  const res = await fetch(`${BASE_API_URL}/user-groups/${userId}`, {
    headers: { Authorization: userToken },
  });
  if (res.status === 200) {
    const json = await res.json();
    return json.groupCode;
  } else if (res.status === 403) {
    throw new Error('Forbidden');
  }
  return '';
};
