import { useEffect, useState } from 'react';

import { Grid, TextField } from '@mui/material';

import Dropdown from '../../../components/Dropdown';
import { EmergencyModel } from '../../../models/client_profile';
import BoxContainer from '../components/BoxContainer';

interface InfoProps {
  emergencyInfo: EmergencyModel;
  setEmergencyInfo: React.Dispatch<React.SetStateAction<EmergencyModel>>;
}

export default function Emergency(props: InfoProps) {
  const getFirstLetterCapitalString = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const { emergencyInfo, setEmergencyInfo } = props;
  const [relationship, setRelationship] = useState<string>(
    emergencyInfo?.relationship
      ? getFirstLetterCapitalString(emergencyInfo?.relationship)
      : ''
  );

  const possibleRelationships = [
    'Brother',
    'Brother-in-law',
    'Cousin',
    'Daughter',
    'Daughter-in-law',
    'Father',
    'Father-in-law',
    'Friend',
    'Granddaughter',
    'Grandson',
    'Mother',
    'Mother-in-law',
    'Neighbor',
    'Nephew',
    'Niece',
    'Partner',
    'Roommate',
    'Sister',
    'Sister-in-law',
    'Son',
    'Son-in-law',
    'Spouse',
  ];

  const updateEmergencyKey = (key: keyof EmergencyModel, val: string) => {
    setEmergencyInfo((obj: EmergencyModel) => {
      const objCopy: EmergencyModel = { ...obj };
      objCopy[key] = val;
      return objCopy;
    });
  };

  useEffect(() => {
    if (relationship) {
      setEmergencyInfo((obj: EmergencyModel) => {
        const objCopy: EmergencyModel = { ...obj };
        objCopy.relationship = getFirstLetterCapitalString(relationship);
        return objCopy;
      });
    }
  }, [relationship, setEmergencyInfo]);

  useEffect(() => {
    setRelationship(
      emergencyInfo?.relationship
        ? getFirstLetterCapitalString(emergencyInfo?.relationship)
        : ''
    );
  }, [emergencyInfo]);

  return (
    <BoxContainer header="Emergency Contact">
      <Grid container spacing={2} sx={{ padding: '1.5em', textAlign: 'left' }}>
        <Grid item xs={12} md={3}>
          <h4>Name *</h4>
          <TextField
            value={emergencyInfo.name}
            onChange={(e) => updateEmergencyKey('name', e.target.value)}
            fullWidth
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <h4>Phone *</h4>
          <TextField
            value={emergencyInfo.phone}
            onChange={(e) => updateEmergencyKey('phone', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <h4>Alternate Phone</h4>
          <TextField
            value={emergencyInfo.alternatePhone}
            onChange={(e) =>
              updateEmergencyKey('alternatePhone', e.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <h4>Relationship *</h4>
          <Dropdown
            options={possibleRelationships}
            displayOption={relationship}
            setDisplayOption={setRelationship}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <h4>Address</h4>
          <TextField
            value={emergencyInfo.address}
            onChange={(e) => updateEmergencyKey('address', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <h4>City</h4>
          <TextField
            value={emergencyInfo.city}
            onChange={(e) => updateEmergencyKey('city', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <h4>State</h4>
          <TextField
            value={emergencyInfo.state}
            onChange={(e) => updateEmergencyKey('state', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <h4>Zip Code</h4>
          <TextField
            value={emergencyInfo.zipCode}
            onChange={(e) => updateEmergencyKey('zipCode', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
