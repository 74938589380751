import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import { Colors } from '../../constants';

export const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    align: 'center',
    headerAlign: 'center',
    disableColumnMenu: true,
    headerClassName: 'history-table-header',
    sortable: false,
    width: 85,
  },
  {
    field: 'details',
    headerName: 'Transaction Details',
    width: 400,
    headerAlign: 'center',
    disableColumnMenu: true,
    headerClassName: 'history-table-header',
    sortable: false,
    renderCell: (params) => {
      if (
        params.value.includes('Ride Fare for Ride') &&
        !params.value.includes('null')
      ) {
        const rideId = params.value.split(' ').pop() || '';
        const aux1 = params.value.split(rideId)[0];
        return (
          <>
            {aux1.replace('Ride Fare for Ride', 'Fare for')}
            <Link
              style={{
                textDecoration: 'none',
                color: Colors.mainColor,
                marginLeft: '4px',
              }}
              to={`/trips/${rideId}`}
            >
              {rideId}
            </Link>
          </>
        );
      }
    },
  },
  {
    field: 'guest',
    headerName: 'Guest',
    align: 'center',
    headerAlign: 'center',
    disableColumnMenu: true,
    headerClassName: 'history-table-header',
    sortable: false,
    width: 60,
  },
  {
    field: 'credit',
    headerName: 'Credit',
    align: 'center',
    headerAlign: 'center',
    disableColumnMenu: true,
    headerClassName: 'history-table-header',
    sortable: false,
    width: 75,
  },
  {
    field: 'debit',
    headerName: 'Debit',
    align: 'center',
    headerAlign: 'center',
    disableColumnMenu: true,
    headerClassName: 'history-table-header',
    sortable: false,
    width: 75,
  },
];

export const emptyDates = { from: '', to: '' };

export const dateAux = (date: string) =>
  date ? date.replace(' UTC', '') : date;

export const CustomToolbar = () => {
  return (
    <GridToolbarContainer style={{ display: 'flex', justifyContent: 'center' }}>
      <GridToolbarExport
        printOptions={{ hideToolbar: true, hideFooter: true }}
        style={{ width: '100%' }}
      />
    </GridToolbarContainer>
  );
};

export interface ITransaction {
  id: number;
  date: string;
  details: string;
  guest: string;
  credit: string;
  debit: string;
}

interface ITransactionFirebase {
  created_at: string;
  type: string;
  purpose: string;
  guest_pay: string;
  credit: string;
  debit: string;
}

export interface IJson {
  transactions: ITransactionFirebase[];
}
